import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';

import { ISite } from '../model/site';

export class DeletedSiteDoc extends Document<ISite> {
  public static navigateNew = () => {
    browserHistory.push(`/deletedSites/new`)
  }
}
