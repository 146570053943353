import addDays from 'date-fns/add_days';
import addMonths from 'date-fns/add_months';
import startOfMonth from 'date-fns/start_of_month';
import { last, max, sum, TimeSeries } from 'pondjs';

import { Timestamp } from '../firestore';
import { IMetricBatch } from './metricBatch';

export interface IMetricRollup {
  pondId: string
  endTimestamp: Timestamp
  timeSeriesJson: string
}

export const timeSeriesAggregation = {
  BatchDosedGrams: { BatchDosedGrams: sum() },
  BatteryVoltage: { BatteryVoltage: last() },
  DosedGrams: { DosedGrams: sum() },
  DryAbsorptionMm: { DryAbsorptionMm: last() },
  RainMm: { RainMm: sum() },
  WantedDoseGrams: { WantedDoseGrams: last() },
  commissioned: { commissioned: max() },
  restarted: { restarted: max() },
  ManualDosedLiteres: { ManualDosedLiteres: sum() },
  SignalQualityPercent: { SignalQualityPercent: last() },
  SignalStrengthPercent: { SignalStrengthPercent: last() },
}

export const BATTERY_CHART_MAX = 100

const DAYS_BEFORE_ROLLUP = 7

export const groupStartDate = (date: Date): Date => startOfMonth(date)
export const groupEndDate = (date: Date): Date =>
  groupStartDate(addMonths(date, 1))
export const maxRollupEndDate = (now: Date, bufferFactor = 1) =>
  groupStartDate(addDays(now, -(bufferFactor * DAYS_BEFORE_ROLLUP)))

const FILL_BATTERY_CHART_VALUE = BATTERY_CHART_MAX * 2
const asBatteryBool = (x?: number): number => (x ? FILL_BATTERY_CHART_VALUE : 0)

const zeroMissing = (x?: number): number => x || 0

export const metricsToHourlyTimeSeries = (
  batches: IMetricBatch[],
): TimeSeries =>
  new TimeSeries({
    columns: [
      'time',
      'RainMm',
      'DryAbsorptionMm',
      'DosedGrams',
      'BatchDosedGrams',
      'WantedDoseGrams',
      'BatteryVoltage',
      'restarted',
      'commissioned',
      'ManualDosedLiteres',
      'SignalQualityPercent',
      'SignalStrengthPercent'
    ],
    points: batches.map(({ timestamp, metrics }) => [
      timestamp.toMillis(),
      zeroMissing(metrics.RainMm),
      zeroMissing(metrics.DryAbsorptionMm),
      // zeroMissing(metrics.DosedLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      // zeroMissing(metrics.BatchDosedLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      // zeroMissing(metrics.WantedDoseLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      zeroMissing(metrics.DosedLitres),
      zeroMissing(metrics.BatchDosedLitres),
      zeroMissing(metrics.WantedDoseLitres),
      zeroMissing(metrics.BatteryVoltage),
      asBatteryBool(metrics.LiveModeBootCount),
      asBatteryBool(metrics.CommissioningModeBootCount),
      zeroMissing(metrics.ManualDosedLiteres),
      zeroMissing(metrics.SignalQualityPercent),
      zeroMissing(metrics.SignalStrengthPercent)
    ]),
  }).fixedWindowRollup({
    aggregation: timeSeriesAggregation,
    windowSize: '1h',
  })
export const metricsToWeeklyTimeSeries = (
  batches: IMetricBatch[],
): TimeSeries =>
  new TimeSeries({
    columns: [
      'time',
      'RainMm',
      'DryAbsorptionMm',
      'DosedGrams',
      'BatchDosedGrams',
      'WantedDoseGrams',
      'BatteryVoltage',
      'restarted',
      'commissioned',
      'ManualDosedLiteres',
      'SignalQualityPercent',
      'SignalStrengthPercent'
    ],
    points: batches.map(({ timestamp, metrics }) => [
      timestamp.toMillis(),
      zeroMissing(metrics.RainMm),
      zeroMissing(metrics.DryAbsorptionMm),
      // zeroMissing(metrics.DosedLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      // zeroMissing(metrics.BatchDosedLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      // zeroMissing(metrics.WantedDoseLitres) * LIQUID_PAC_GRAMS_PER_LITRE,
      zeroMissing(metrics.DosedLitres),
      zeroMissing(metrics.BatchDosedLitres),
      zeroMissing(metrics.WantedDoseLitres),
      zeroMissing(metrics.BatteryVoltage),
      asBatteryBool(metrics.LiveModeBootCount),
      asBatteryBool(metrics.CommissioningModeBootCount),
      zeroMissing(metrics.ManualDosedLiteres),
      zeroMissing(metrics.SignalQualityPercent),
      zeroMissing(metrics.SignalStrengthPercent)
    ]),
  }).fixedWindowRollup({
    aggregation: timeSeriesAggregation,
    windowSize: '7d',
  })