import Link from '@material-ui/core/Link'
import { useObserver } from 'mobx-react-lite'
import React, { FC } from 'react'
import { PondDoc } from '../../db/pondDoc'

export const DeployedToLink: FC<{ deployedToPond: PondDoc | null }> = ({
  deployedToPond,
}) => {
  return useObserver(() => {
    if (!deployedToPond) {
      return null
    }
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation()
      deployedToPond.navigateEdit()
    }
    return (
      <Link onClick={onClick} color="primary">
        {deployedToPond.sitePondName}
      </Link>
    )
  })
}
