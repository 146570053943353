import { locationStore } from '@any-ui/core/navigation'
import { Collection, Mode } from 'firestorter'
import { computed, reaction } from 'mobx'
import {CompanyStaffEditStore} from './companyStaffEditStore'
import { authStore } from '../auth/authStore'
import { CompanyStaffDoc } from '../db/companyStaffDoc'
import { SiteDoc } from '../db/siteDoc'
import { apiCall } from '../firebase'
import { FieldPath } from '../firestore'
import {hasRoles} from '../model/user'
import {
  ApplyCompanyStaffToUserData,
  IApplyCompanyStaffToUserResult,
} from '../model/companyStaffModal'

const applyCompanyStaffToUser = apiCall<IApplyCompanyStaffToUserResult>(
  'applyCompanyStaffToUser',
  ApplyCompanyStaffToUserData,
)
// import { constVoid } from 'fp-ts/lib/function'
class CompanyStaffStore {

  notificationOn = false;

  public companyStaffs = new Collection<CompanyStaffDoc> ('companyStaffs', {
    createDocument: (...args) => new CompanyStaffDoc(...args),
    mode: Mode.On,
    query: ref => authStore.isAdmin ? ref : authStore.isCustomer ? ref.where("ownerUid", '==', authStore.uid) : null,
  })
  private readonly disposer: () => void
  constructor() {
    this.disposer = reaction(
      () => locationStore.path.startsWith('/companyStaffs'),
      inDevices => {
        this.companyStaffs.mode = inDevices ? Mode.On : Mode.Auto
      },
      { fireImmediately: false },
    )
  }

  @computed
  get isLoading(): boolean {
    if (this.companyStaffs != null) {
      return this.companyStaffs.isLoading
    }
    return true
  }

  @computed
  get user(): CompanyStaffDoc | null {
    return authStore.uid && authStore.isCompanyStaff
      ? this.byId(authStore.uid)
      : null
  }

  public byId(uid: string): any | null {
    const companyStaffUser = new Collection<CompanyStaffDoc>('companyStaffs', {
      createDocument: (...args) => new CompanyStaffDoc(...args),
      mode: Mode.On,
      query: ref => ref.where(FieldPath.documentId(), '==', uid),
    })
    return companyStaffUser.docs || null
  }

  /**
   * Will search for keyword in contactName and email
   * @param keyword
   */
  // public async searchCompanyStaff(keyword: string) {
  //   const companyStaffUser = new Collection<CompanyStaffDoc>('companyStaffs', {
  //     createDocument: (...args) => new CompanyStaffDoc(...args),
  //     mode: Mode.Off,
  //   })
  //   companyStaffUser.fetch().then(p=>{
  //     const regex = new RegExp(`${keyword}`, 'i');
  //     const filter = p.docs.filter(p=>p.data.contactName.match(regex)|| p.data.email.match(regex))
  //     console.log('filter',filter);
  //     return filter
  //   })
  // }

  /**
   *
   * @param field contactName, email, addedTimestamp
   * @param sort asc, desc
   */

  public enableStaff(uid: string | undefined) {
    if (uid) {
      const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,/*not sure if this works the way we want it do #TODO*/
      })
      companyStaff.fetch().then(async (item) => {
        item.data.enabled = true
        await companyStaff.update(item.data)
      })
    } else {
      // Handle the case when the uid is undefined, for example, show an error message or throw an error.
    }
  }

  public disableStaff(uid: string | undefined) {
    if (uid) {
      const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,
      })
      companyStaff.fetch().then(async (item) => {
        item.data.enabled = false
        await companyStaff.update(item.data)
      })
    } else {
      // Handle the case when the uid is undefined, for example, show an error message or throw an error.
    }
  }

  public addSiteToCompanyStaff(uid: string | undefined, siteId: string) {
    if (uid) {
      const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,
      });
      companyStaff.fetch().then(async (item) => {
        if (!item.data.siteIds) {
          item.data.siteIds = []; // Initialize siteIds array if it doesn't exist
        }
        if (!item.data.siteIds.includes(siteId)) {
          item.data.siteIds.push(siteId); // Add siteId to the list if it's not already present
          await companyStaff.update(item.data);
          await this.assignCustomersStaffToSites(siteId, uid);
        }
      });
    } else {
      // Handle the case when the uid is undefined, for example, show an error message or throw an error.
    }
  }
  
  public removeSiteFromCompanyStaff(uid: string | undefined, siteId: string) {
    if (uid) {
      const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,
      });
      companyStaff.fetch().then(async (item) => {
        if (item.data.siteIds) {
          const index = item.data.siteIds.indexOf(siteId);
          if (index !== -1) {
            item.data.siteIds.splice(index, 1); // Remove siteId from the list if it exists
            await companyStaff.update(item.data);
          await this.removeCustomersStaffFromSites(siteId, uid);
          }
        }
      });
    } else {
      // Handle the case when the uid is undefined, for example, show an error message or throw an error.
    }
  }

  public assignCustomersStaffToSites = async (siteId: string, companyStaffId: string) => {
    const site = await new SiteDoc(`sites/${siteId}`, { mode: Mode.Off }).fetch()
    const companyStaffIds = (site.data.companyStaffIds && site.data.companyStaffIds.length) ? site.data.companyStaffIds : [];
    if (companyStaffIds.indexOf(companyStaffId) === -1) {
      companyStaffIds.push(companyStaffId)
      await site.set({ companyStaffIds }, { merge: true })
      return true
    } else {
      return false
    }
  }

  public removeCustomersStaffFromSites = async (siteId: string, companyStaffId: string) => {
    const site = await new SiteDoc(`sites/${siteId}`, { mode: Mode.Off }).fetch();
    const companyStaffIds = (site.data.companyStaffIds && site.data.companyStaffIds.length) ? site.data.companyStaffIds : [];
    
    const index = companyStaffIds.indexOf(companyStaffId);
    if (index !== -1) {
      companyStaffIds.splice(index, 1); // Remove the companyStaffId from the array
      await site.set({ companyStaffIds }, { merge: true });
      return true;
    } else {
      return false; // The companyStaffId was not found in the array
    }
  }

  public sortCompanyStaff(field: string, sort: any) {
    if (field === 'all') {
      this.companyStaffs = new Collection<CompanyStaffDoc>('companyStaffs', {
        createDocument: (...args) => new CompanyStaffDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('enabled', '==', true),
      })
    } else {
      this.companyStaffs = new Collection<CompanyStaffDoc>('companyStaffs', {
        createDocument: (...args) => new CompanyStaffDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('enabled', '==', true).orderBy(field, sort),
      })
    }
  }
  public async deleteCompanyStaff(uid: any) {
    const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
      mode: Mode.Off,
    })
    await companyStaff.fetch().then(async item => {
      item.data.enabled = false
      await companyStaff.update(item.data);
      const writableCompanyStaff: any = {
        email: companyStaff.data.email,
        enabled: false,
        newPassword: companyStaff.data.newPassword,
        ownerUid: companyStaff.data.ownerUid,
        phoneNumber: companyStaff.data.phoneNumber
      }
      await applyCompanyStaffToUser({
        companyStaff: writableCompanyStaff,
        uid: companyStaff.id,
      })
    })

  }

  public getCustomersSites = async (ownerId: string) => {
    const allSites = await new Collection<SiteDoc>('sites', {
      createDocument: (...args) => new SiteDoc(...args),
      mode: Mode.On,
      query: ref =>
        ref.where('ownerUid', '==', ownerId).where('isArchive', '==', false),
    })
    return allSites
  }
  // public companyStaffName(uid: string): string | null {
  //   const doc = this.byId(uid)
  //   return doc && doc.contactName
  // }

  // public ownerName(owned: IDocHasOwner): string | null {
  //   return this.customerName(owned.data.ownerUid)
  // }

  public async toggleNotification(uid: string | undefined, isContact: boolean) {
    
    this.notificationOn = !isContact;

    if (uid) {
      const companyStaff = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,/*not sure if this works the way we want it do #TODO*/
      });
      
      companyStaff.fetch().then(async (item) => {
        item.data.isContactPoint = this.notificationOn;
        await companyStaff.update(item.data);
      });
    } else {
      // Handle the case when the uid is undefined, for example, show an error message or throw an error.
    }
  }
  
}

export const companyStaffStore = new CompanyStaffStore()
