import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import { DeviceRetrievalListItemBase } from './DeviceRetrievalListItemBase';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: theme.palette.action.selected,
    fontSize: '1.1rem',
    // tslint:disable-next-line:object-literal-sort-keys
    '.MuiPaper-rounded &': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
  },
}))

export const DeviceRetrievalListTitle = () => {
  const classes =  useStyles({})
  return (
    <DeviceRetrievalListItemBase
      serial="Serial"
      customerName="Customer"
      deployedToPond="Deployed to"
      action="Actions"
      className={clsx('DeviceRetrievalListTitle', classes.root)}
      button={false}
    />
  )
}
