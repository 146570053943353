import { Collection, Mode } from 'firestorter'
import { computed } from 'mobx'
import { authStore } from '../auth/authStore'
import { CustomerDoc } from '../db/customerDoc'
import { FieldPath } from '../firestore'
import { IDocHasOwner } from '../model/customer'

class CustomerStore {
  public customers = new Collection<CustomerDoc>('customers', {
    createDocument: (...args) => new CustomerDoc(...args),
    mode: Mode.On,
    query: ref =>
      authStore.isAdmin
        ? ref
        : authStore.isCustomer
        ? ref.where(FieldPath.documentId(), '==', authStore.uid)
        : null,
  })

  @computed
  get isLoading(): boolean {
    return this.customers.isLoading
  }

  @computed
  get user(): CustomerDoc | null {
    return authStore.uid && authStore.isCustomer
      ? this.byId(authStore.uid)
      : null
  }

  public byId(uid: string): CustomerDoc | null {
    return this.customers.docs.find(d => d.id === uid) || null
  }

  public customerName(uid: string): string | null {
    const doc = this.byId(uid)
    return doc && doc.displayName
  }

  public ownerName(owned: IDocHasOwner): string | null {
    return this.customerName(owned.data.ownerUid)
  }
}

export const customerStore = new CustomerStore()
