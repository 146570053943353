import { Form, FormSubmitHandler, NumberField, requiredNumber } from '@any-ui/form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';

import { PondDoc } from '../../db/pondDoc';
import { pondStore } from '../pondStore';

export interface IFormFields {
  pendingBatchDoseKg: number
}

export const DosePondDialog: FC<{
  pond: PondDoc
  onClose: () => void
  open: boolean
}> = ({ pond, onClose, open }) => {
  const handleClose = () => {
    onClose()
  }
  const handleSubmit: FormSubmitHandler<IFormFields> = async values => {
    await pondStore.updatePond(pond, {
      pendingBatchDoseG: requiredNumber(values.pendingBatchDoseKg),
    })
    handleClose()
  }
  const pendingBatchDoseKg = pond.data.pendingBatchDoseG
    ? pond.data.pendingBatchDoseG
    : 0
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Set Batch Dose in ltr</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Form<IFormFields>
          initialValues={{ pendingBatchDoseKg }}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          submitLabel="Set"
        >
          <NumberField autoFocus name="pendingBatchDoseKg" endAdornment="ltr" />
        </Form>
      </DialogContent>
    </Dialog>
  )
}
