import { useStoreClass } from '@any-ui/core/store'
import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { SiteViewPage } from './SiteViewPage'
import { SiteViewStore } from './store/siteViewStore'

export interface IRouteParams {
  siteId?: string
}

export const UserSiteViewPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const store = useStoreClass(SiteViewStore, true, match.params.siteId || null)
  return <SiteViewPage store={store} siteUrl={match.url} />
}
