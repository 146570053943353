import { EmptyResult, PageError, PageLoading } from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { useMediaQuery } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import mapValues from 'lodash/mapValues';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  BarChart,
  Baseline,
  Brush,
  ChartContainer,
  ChartRow,
  Charts,
  LineChart,
  Resizable,
  styler,
  YAxis,
} from 'react-timeseries-charts';

import { BATTERY_CHART_MAX } from '../model/dashboard';
import { toFixed } from '../model/format';
import { DashHeader } from './components/DashHeader';
import { InfoFactory, Marker } from './components/Marker';
import { DashboardStore } from './dashboardStore';

export interface IRouteParams {
  pondId: string
}

const colorStyle = styler([
  { key: 'RainMm', color: colors.lightBlue[500] },
  { key: 'DryAbsorptionMm', color: colors.yellow[900], dashed: true },
  { key: 'DosedGrams', color: colors.amber[500] },
  { key: 'BatchDosedGrams', color: colors.deepOrange[500] },
  { key: 'WantedDoseGrams', color: colors.red[400], dashed: true },
  { key: 'BatteryVoltage', color: colors.lime[500] },
  { key: 'commissioned', color: colors.green[800] },
  { key: 'restarted', color: colors.red[900] },
  { key: 'ManualDosedLiteres', color: colors.purple[400] },
  { key: 'SignalQualityPercent', color: colors.blue[400] },
  { key: 'SignalStrengthPercent', color: colors.blue[900] },
])

const superLegendStyle = colorStyle.legendStyle.bind(colorStyle)

colorStyle.legendStyle = (category: string, type: string) => {
  const { label, ...original } = superLegendStyle(category, type)
  return {
    ...original,
    label: mapValues(label, (style: object) => ({
      ...style,
      color: colors.grey[300],
    })),
  }
}

const zeroBaselineStyle = { line: { strokeDasharray: '' } }

const formatValue = (
  value: unknown,
  decimalDigits: number,
  unit: string,
): string => {
  if (typeof value !== 'number' || !Number.isFinite(value) || value === 0) {
    return ''
  }
  return `${toFixed(value, decimalDigits)}${unit}`
}

const valueInfoFactory = (
  column: string,
  label: string,
  decimalDigits: number,
  unit: string,
  showInfoWhenNoValue: boolean = true,
): InfoFactory => valueGetter => {
  const value = formatValue(valueGetter(column), decimalDigits, unit)
  if (!showInfoWhenNoValue && !value) {
    return null
  }
  return {
    column,
    label,
    value,
  }
}

const isPositive = (x: unknown) =>
  typeof x === 'number' && Number.isNaN(x) && x > 0

const DashboardPage: FC<RouteComponentProps<IRouteParams>> = ({ match }) => {
  const { pondId } = match.params
  const store = useStoreClass(DashboardStore, pondId)
  const theme = useTheme()
  store.setMobile(useMediaQuery(theme.breakpoints.down('xs')))

  const startedInfoFactory: InfoFactory = valueGetter => {
    if (isPositive(valueGetter('commissioned'))) {
      return {
        column: 'commissioned',
        label: 'Started',
        value: 'Commissioned',
      }
    }
    if (store.showDev && isPositive(valueGetter('restarted'))) {
      return {
        column: 'restarted',
        label: 'Started',
        value: 'Restarted',
      }
    }
    return null
  }

  return useObserver(() => {
    if (store.isLoading) {
      return <PageLoading />
    }
    if (store.error) {
      return <PageError error={store.error} />
    }
    if (store.isEmpty) {
      return <EmptyResult>No data yet</EmptyResult>
    }
    return (
      <Paper>
        <DashHeader colorStyle={colorStyle} store={store} />
        <Resizable>
          <ChartContainer
            timeRange={store.timeRange}
            minDuration={store.minDuration}
            minTime={store.fullRange.begin()}
            maxTime={store.fullRange.end()}
            onTrackerChanged={store.handleTrackerChanged}
            width={1026}
          /*
          enablePanZoom
          onTimeRangeChanged={store.handleTimeRangeChange}
          */
          >
            <ChartRow height="200">
              <YAxis
                id="mm"
                label="Rain mm"
                min={0}
                // max={store.maxMmScale}
                max={Math.ceil(store.timeSeries.max('DryAbsorptionMm'))}
                format=",.1f"
              />
              <Charts>
                <Baseline axis="mm" value={0} style={zeroBaselineStyle} />
                <Baseline
                  axis="mm"
                  value={store.maxDryRainAbsorptionMm}
                  label="Max dry absorption"
                />
                <LineChart
                  axis="mm"
                  series={store.timeSeries}
                  columns={['DryAbsorptionMm']}
                  style={colorStyle}
                />
                <BarChart
                  axis="mm"
                  series={store.timeSeries}
                  columns={['RainMm']}
                  minBarHeight={0}
                  style={colorStyle}
                />
                <Marker
                  store={store}
                  axis="mm"
                  fields={[
                    valueInfoFactory(
                      'DryAbsorptionMm',
                      'Dry absorption',
                      1,
                      'mm',
                    ),
                    valueInfoFactory('RainMm', 'Rain', 1, 'mm'),
                  ]}
                />
              </Charts>
            </ChartRow>
            <ChartRow height="200">
              <YAxis
                id="litres"
                label="Dose litres"
                min={0}
                max={store.maxGramsScale}
                format=",.1f"
              />
              <Charts>
                <Baseline axis="litres" value={0} style={zeroBaselineStyle} />
                <Baseline
                  axis="litres"
                  value={store.minDoseGs}
                  label="Min dose"
                />
                <LineChart
                  axis="litres"
                  series={store.timeSeries}
                  columns={['WantedDoseGrams']}
                  style={colorStyle}
                />
                <BarChart
                  axis="litres"
                  series={store.timeSeries}
                  columns={['BatchDosedGrams', 'DosedGrams', 'ManualDosedLiteres']}
                  minBarHeight={0.001} // To get around this bug: https://github.com/esnet/react-timeseries-charts/issues/411
                  style={colorStyle}
                />
                <Marker
                  store={store}
                  axis="litres"
                  fields={[
                    valueInfoFactory('DosedGrams', 'Dosed', 2, 'ltr'),
                    valueInfoFactory(
                      'BatchDosedGrams',
                      'Batch dosed',
                      2,
                      'ltr',
                      false,
                    ),
                    valueInfoFactory('WantedDoseGrams', 'Outstanding', 2, 'ltr'),
                    valueInfoFactory('ManualDosedLiteres', 'ManualDosed', 2, 'ltr')
                  ]}
                />
              </Charts>
            </ChartRow>
            <ChartRow height="85">
              <YAxis
                id="percentage"
                label="Battery %"
                min={0}
                max={BATTERY_CHART_MAX}
                format=",.1f"
                tickCount={2}
              />
              <Charts>
                <Baseline
                  axis="percentage"
                  value={0}
                  style={zeroBaselineStyle}
                />
                <BarChart
                  axis="percentage"
                  series={store.timeSeries}
                  columns={['restarted']}
                  style={colorStyle}
                  minBarHeight={0}
                  visible={store.showDev}
                />
                <BarChart
                  axis="percentage"
                  series={store.timeSeries}
                  columns={['commissioned']}
                  minBarHeight={0}
                  style={colorStyle}
                />
                <LineChart
                  axis="percentage"
                  series={store.timeSeries}
                  columns={['BatteryVoltage']}
                  interpolation="curveBasis"
                  style={colorStyle}
                />
                <Marker
                  store={store}
                  axis="percentage"
                  fields={[
                    valueInfoFactory('BatteryVoltage', 'Battery', 0, '%'),
                    startedInfoFactory,
                  ]}
                />
              </Charts>
            </ChartRow>
            <ChartRow>
            <YAxis
                id="percentage"
                label="Signal %"
                min = {0}
                max = {100}
                format=",.1f"
                tickCount={2}
              />
              <Charts>
                <Baseline
                  axis="percentage"
                  value={0}
                  style={zeroBaselineStyle}
                />
                <LineChart 
                  axis="percentage"
                  series={store.timeSeries}
                  columns={['SignalQualityPercent']}
                  interpolation="curveBasis"
                  style={colorStyle}
                />
                <LineChart 
                  axis="percentage"
                  series={store.timeSeries}
                  columns={['SignalStrengthPercent']}
                  interpolation="curveBasis"
                  style={colorStyle}
                />
                <Marker
                  store={store}
                  axis="percentage"
                  fields={[
                    valueInfoFactory('SignalQualityPercent', 'Signal quality', 0, '%'),
                    valueInfoFactory('SignalStrengthPercent', 'Signal strength', 0, '%'),
                    startedInfoFactory,
                  ]}
                />
              </Charts>
            </ChartRow>
          </ChartContainer>
        </Resizable>
        <Resizable>
          <ChartContainer timeRange={store.fullRange} width={1026}>
            <ChartRow height="85">
              <Brush
                timeRange={store.brushRange}
                allowSelectionClear
                onTimeRangeChanged={store.handleTimeRangeChange}
                style={{ stroke: 'none' }}
              />
              <YAxis id="mm" min={0} max={store.maxMmScale} visible={false} />
              <Charts>
                <BarChart
                  axis="mm"
                  series={store.timeSeries}
                  columns={['RainMm']}
                  minBarHeight={0}
                  style={colorStyle}
                />
              </Charts>
            </ChartRow>
          </ChartContainer>
        </Resizable>
      </Paper>
    )
  })
}

export default DashboardPage
