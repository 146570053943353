import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';

// tslint:disable:object-literal-sort-keys
// @ts-ignore
const useStyles = makeStyles<Theme>(theme => ({
  customerName: {},
  noOfDevice: {
    textAlign: 'center',
  },
  deliveryInstructions: {},
  root: {
    display: 'grid',
    textAlign: 'left',
    gridTemplateColumns: '2fr 2fr 2fr 1.5fr'
  },
  notDeployed: {
    color: theme.palette.text.disabled,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}))

interface IDeviceListItemBaseProps extends Partial<ListItemProps> {
  serial: string | null
  customerName?: string
  deployedToPond: ReactNode | null
  action?: any
}

export const DeviceRetrievalListItemBase: FC<IDeviceListItemBaseProps> = ({
  serial,
  customerName,
  deployedToPond,
  action,
  className,
  ...rest
}) => {
  const classes =  useStyles({})
  return useObserver(() => (
    <ListItem
      // @ts-ignore
      button
      classes={{
        root: clsx(classes.root),
      }}
      className={clsx('DeviceRequestListItemBase', className)}
      {...rest}
    >
      <Box className={clsx(classes.serial)}>{serial}</Box>
      <Box className={clsx(classes.customerName)}>{customerName}</Box>
      <Box className={clsx(classes.deployedToPond)}>{deployedToPond}</Box>
      <Box className={clsx(classes.deliveryInstructions)}>{action}</Box>
      
    </ListItem>
  ))
}
