export const template = `<!DOCTYPE html>
<html>
<body>
    <div>
        <p>Hello <%=fullName%>,</p>
	<p>You are registered as a company staff for Autoflocc Customer.</p>
<p>Please login using bellow credentials.</p>
<p>email: <strong><%=email%></strong>
<p>password: <strong><%=password%></strong>
<p>After logging in we recommend navigating to your profile section and changing your password.</p>
<p>Thanks,</p>
<p>Autoflocc Team</p>
    </div>
</body>
</html>`