import { DocsView } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { customerStore } from '../../customer/customerStore';
import { DeviceDoc } from '../../db/deviceDoc';
import { sitesStore } from '../../site/store/sitesStore';
import { DeployedToLink } from './DeployedToLink';
import { DeviceListItemBase } from './DeviceListItemBase';

export const DeviceListItem: DocsView<DeviceDoc> = ({ document, ...rest }) =>
  useObserver(() =>(
    <DeviceListItemBase
      serial={document.displayName}
      customerName={
        (document.data.ownerUid &&
          customerStore.customerName(document.data.ownerUid)) ||
        undefined
      }
      deployedToPond={
        document.deployedToPond ? (
          <DeployedToLink deployedToPond={document.deployedToPond} />
        ) : sitesStore.siteName(document.data.siteId as string)
      }
      status={document.data.status}
      count={document.data.doseCount? document.data.doseCount : '0'}
      onClick={document.navigateEdit}
      {...rest}
    />
  ))
