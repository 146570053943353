import { useConfirm } from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { authStore } from '../../auth/authStore';
import { DeviceRequestDoc } from '../../db/deviceRequestDoc';
import { DeviceRequestStatus } from '../../model/deviceReqStatus';
import { DeviceRequestEditStore } from '../deviceRequestEditStore';

const useStyles = makeStyles<Theme>(theme => ({
  label: {
    fontSize: 11,

  },
  chipcolor:{
    color:'#FFFF'
  }
}))
interface IDeviceAssginedProps {
  document: DeviceRequestDoc
}

export const DeviceAssgined: FC<IDeviceAssginedProps> = ({
  document,
  ...props
}) => {
  const classes = useStyles({});
  const store = useStoreClass(DeviceRequestEditStore, document.id);
  const onAssginedClick = useConfirm({
    message: 'Confirm that you have assigned a device to this customer?',

    onCancel: () => { },
    onConfirm: async () => {
      const fields = {
        status: DeviceRequestStatus.ASSGINED
      };
      await store.save(fields)
    },
    title: `Device(s) Requests`,
  });

  return useObserver(() => {
    if (authStore.isAdmin) {
      return (
        <Button
          variant="contained"
          color="primary"
          classes={{ label: classes.label }}
          onClick={onAssginedClick}
          disabled={document.data.status === DeviceRequestStatus.PENDING ? false : true}
        >
          {document.data.status === DeviceRequestStatus.PENDING ? <span>Device Assigned</span> : <span>{document.data.status}</span>}
        </Button>
      )
    } else if (authStore.isCustomer) {
      return (
        <Button
          variant="contained"
          color={document.data.status === DeviceRequestStatus.DELETED ? 'secondary':'primary'}
          classes={{ label: classes.label }}
          disabled={true}
        >
          <span className={classes.chipcolor}>{document.data.status}</span>
        </Button>
      )
    }
    return null
  })
}
