import { Mode } from 'firestorter';
import { action, computed, observable } from 'mobx';

import { authStore } from '../../auth/authStore';
import { ownerStore } from '../../auth/ownerStore';
import { SiteDoc } from '../../db/siteDoc';
import * as log from '../../log';
import { ISite } from '../../model/site';
import { FormFields } from '../components/EditSiteForm';
import { sitesStore } from './sitesStore';

// import { unexpectedFormSubmitError } from '../../error'
export class SiteEditStore {
  @computed
  get initialData(): Partial<ISite> {
    return (this.initialDoc && this.initialDoc.data) || {}
  }

  @computed
  get initialValues(): Partial<FormFields> {
    const { customerNotes, adminNotes, ...initialRest } = this.initialData
    return {
      notes: authStore.isAdmin ? adminNotes : customerNotes,
      ownerUid: ownerStore.defaultOwnerUid || undefined,
      ...initialRest,
    }
  }

  @computed
  get initialOwnerUid(): string | null {
    return this.initialValues.ownerUid || null
  }

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @computed
  get isNew(): boolean {
    return !this.initialDoc
  }

  @observable
  private initialDoc: SiteDoc | null = null

  constructor(siteId?: string) {
    if (siteId) {
      this.setSiteId(siteId)
    }
  }

  // public save = async ({
  //   notes,
  //   ...formFields
  // }: FormFields): Promise<string> => {
  //   const fields = {
  //     [authStore.isAdmin ? 'adminNotes' : 'customerNotes']: notes,
  //     ...formFields,
  //   }
  //   try {
  //     if (this.initialDoc) {
  //       await this.initialDoc.set(fields, { merge: true })
  //       await this.initialDoc.fetch()
  //       return this.initialDoc.id!
  //     } else {
  //       const newDoc = await sitesStore.sites.add(fields)
  //       const siteId = newDoc.id!
  //       this.setSiteId(siteId)
  //       return siteId
  //     }
  //   } catch (e) {
  //     throw unexpectedFormSubmitError(e)
  //   }
  // }


   public save = async ({ notes, ...formFields }: FormFields) => {
     const fields = {
       [authStore.isAdmin ? 'adminNotes' : 'customerNotes']: notes,
       ...formFields,
     }

     if (this.initialDoc) {
       await this.initialDoc.set(fields, { merge: true })
       await this.initialDoc.fetch()
       return this.initialDoc.id!
     } else {
       const newDoc = await sitesStore.sites.add(fields)
       const siteId = newDoc.id!
       this.setSiteId(siteId)
       return siteId
     }
   }

  @action
  private setSiteId(siteId: string) {
    this.initialDoc = new SiteDoc(`sites/${siteId}`, { mode: Mode.Off })
    this.initialDoc.fetch().catch(log.error)
  }
}
