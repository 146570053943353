import { browserHistory } from '@any-ui/core/navigation'
import { Document } from 'firestorter'
import { computed } from 'mobx'
import { ICustomer } from '../model/customer'

export class CustomerDoc extends Document<ICustomer> {
  public static navigateNew = () => {
    browserHistory.push(`/customers/new`)
  }

  @computed
  get displayName(): string {
    return this.data.orgName
  }

  public navigateEdit = () => {
    browserHistory.push(`/customers/${this.id}/edit`)
  }
}
