import { authStore } from '../auth/authStore';
import { firebase } from '../firebase';
import { FieldPath } from '../firestore';
import { viewerStore } from '../site/store/viewerStore';
import { CompanyStaffDoc } from '../db/companyStaffDoc'
import { Collection, Mode } from 'firestorter'

export const narrowByOwnership = (query: firebase.firestore.Query) => {
  if (!authStore.isAuthenticated) {
    return null
  }
  if (authStore.isAdmin) {
    return query
  }
  else if (authStore.isCustomer) {
    return query.where('ownerUid', '==', authStore.uid)
  }
  else if (authStore.isCompanyStaff) { /*companyStaff*/
    return null;
  }
  else {
    return null;
  }

}

export const narrowByOwnershipOrViewerSite = (
  query: firebase.firestore.Query,
  siteIdField: FieldPath | string = 'siteId',
) => {
  if (authStore.isAuthenticated) {
    if (!authStore.isCompanyStaff) {
      return narrowByOwnership(query)
    } else {
      /** @TODO change block for company staff */
      return narrowByOwnership(query)
    }
  }
  if (authStore.isViewer && viewerStore.siteId) {
    return query.where(siteIdField, '==', viewerStore.siteId)
  }
  return null
}
