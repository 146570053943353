import { CardSecondaryAction } from '@any-ui/core';
import { Theme } from '@material-ui/core';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

export const useStyles = makeStyles<Theme>(theme => ({
  action: {
    marginRight: theme.spacing(1),
  },
  typography: {
    display: 'block',
    fontFamily: 'Arial Black',
    fontSize: 32,
    lineHeight: '32px',
  },
}))

export interface IPondCountProps extends TypographyProps {
  className?: string
  count: number
}

export const PondCount: FC<IPondCountProps> = ({ count }) => {
  const classes =  useStyles({})
  return (
    <CardSecondaryAction className={clsx('PondCount', classes.action)}>
      <Typography
        color="textSecondary"
        variant="h4"
        className={classes.typography}
      >
        {count}
      </Typography>
    </CardSecondaryAction>
  )
}
