import { SubTitle } from '@any-ui/core';
import {
  DateTimeField,
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  IFormActionProps,
  NumberField,
  requiredNumber,
  requiredString,
  SelectField,
  SwitchField,
  TextField,
  toNumberOrNull,
  useFormState,
  validators,
} from '@any-ui/form';
import { Box, Grid, Theme, Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { toFixed } from '../model/format';
import { IPond } from '../model/pond';
import { PondEditStore } from './pondEditStore';
import { authStore } from '../auth/authStore';

interface IClientProps extends IFormActionProps {
  store: PondEditStore
}
// const timeValidate = async (
//   time: string,
// ) => {
//   if (time && time !== '') {
//     const idLength = validators.numericality({ lessThan: 25 })(time)
//     if (idLength) {
//       return idLength
//     }
//   }
//   return
// }


export type FormFields = Pick<IPond, 'name' | 'dispenseDeviceId' | 'isArchive'> & {
  dispenserConfig: Pick<
    IPond['dispenserConfig'],
    'maxDryAfterDays' | 'maxDryRainAbsorptionMm' | 'flowingRainMmPerHour'
  > & {
    catchmentAreaM2: number
    doseMgPerLitre: number
    disableDose: boolean
    doseType: number
    manualDoseL?: number | null
    interval?: number | null
    startTime?: string | null
    endTime?: string | null
    isDayWise?: boolean
    minimumDoseml?: number | null
    dosingTimeS?: number | null
    //disableRainDose?: number | null
    //rainGauge?: number | null
    //rainType?: number | null
    //connectionIntervalMins?: number | null
    //connectionUpTimeSecs?: number | null

  }
  floccSetLitres: number | null
}

const useStyles = makeStyles<Theme>(theme => ({
  grid: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
  info: {
    marginTop: 7
  }
}))

const dosageValidate = async (
  dosage: string,
  formState: any
) => {
  if (dosage && formState) {
    if (+dosage < 300) {
      return 'Dosage amount should not be less then 300 ml'
    }
    const value = 1000 * formState.dispenserConfig.interval;
    if (value < +dosage) {
      return 'Dosage should not be more then interval * 1000'
    }
  }
  return
}

export const EditPondForm: FormComponent<FormFields, IClientProps> = ({
  store,
  isNew,
  onResult,
  ...formProps
}) => {
  const classes = useStyles({})

  const handleSubmit: FormSubmitHandler<FormFields> = async values => {
    const config = values.dispenserConfig || {}
    console.log('config: ', config);
    await onResult({
      dispenseDeviceId: emptyToNull(values.dispenseDeviceId),
      dispenserConfig: {
        catchmentAreaM2: requiredNumber(config.catchmentAreaM2),
        doseMgPerLitre: requiredNumber(config.doseMgPerLitre),
        flowingRainMmPerHour: requiredNumber(config.flowingRainMmPerHour),
        maxDryAfterDays: requiredNumber(config.maxDryAfterDays),
        maxDryRainAbsorptionMm: requiredNumber(config.maxDryRainAbsorptionMm),
        disableDose: Boolean(config.disableDose),
        doseType: requiredNumber(config.doseType),
        manualDoseL: toNumberOrNull(config.manualDoseL),
        interval: toNumberOrNull(config.interval),
        startTime: emptyToNull(config.startTime),
        endTime: emptyToNull(config.endTime),
        minimumDoseml: toNumberOrNull(config.minimumDoseml),
        dosingTimeS: toNumberOrNull(config.dosingTimeS),
        //rainType: toNumberOrNull(config.rainType),
        //disableRainDose: toNumberOrNull(config.disableRainDose),
        //rainGauge: toNumberOrNull(config.rainGauge),
        //connectionIntervalMins: toNumberOrNull(config.connectionIntervalMins),
        //connectionUpTimeSecs: toNumberOrNull(config.connectionUpTimeSecs),

        isDayWise: Boolean(config.isDayWise),
      },
      floccSetLitres: toNumberOrNull(values.floccSetLitres),
      name: requiredString(values.name),
      isArchive: (emptyToNull(values.dispenseDeviceId) === null && !isNew) ? true : false
    })
  }
  // console.log(formProps)
  if (formProps.initialValues && formProps.initialValues.dispenserConfig && !formProps.initialValues.dispenserConfig.doseType) {
    formProps.initialValues.dispenserConfig.doseType = '1';
  }

  //if (formProps.initialValues && formProps.initialValues.dispenserConfig && !formProps.initialValues.dispenserConfig.rainType) {
  //  formProps.initialValues.dispenserConfig.rainType = '1';
  //}

   const initialDeviceId = formProps.initialValues.dispenseDeviceId;
   const DoseTypeBaseSettings: FC = () => {
     const formState = useFormState<FormFields>({})
     const doseType = formState && formState.values.dispenserConfig ? formState.values.dispenserConfig.doseType : '1';
    
  //   //Switch to change values of rainGuage and disableRainDose
  //   const rainType = formState && formState.values.dispenserConfig ? formState.values.dispenserConfig.rainType : 1;
  //   if (rainType && (+rainType === 1)) {
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.disableRainDose = 0);
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.rainGauge = 0);
  //   } else if (rainType && (+rainType === 2)) {
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.disableRainDose = 1);
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.rainGauge = 0);
  //   } else if (rainType && (+rainType === 3)) {
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.disableRainDose = 0);
  //     formState && formState.values.dispenserConfig && (formState.values.dispenserConfig.rainGauge = 1);
  //   }

    if (doseType && (+doseType === 2 || +doseType === 3)) {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <NumberField
                name="dispenserConfig.manualDoseL"
                label="Floc dosage"
                validate={dosageValidate}
                endAdornment="ml"
              />
            </Grid>
            <Grid item xs={5}>
              <NumberField
                name="dispenserConfig.interval"
                label="Intervals"
                endAdornment="min"
              />
            </Grid>
            {/* <Grid item xs={4}>
          <NumberField
            name="dispenserConfig.noOfDays"
            label="Repeat"
            endAdornment="Days"
          />
        </Grid> */}
          </Grid>
          <DateTimeField
            type="datetime-local"
            name="dispenserConfig.startTime"
            label="Start time"
          />
          <DateTimeField
            type="datetime-local"
            name="dispenserConfig.endTime"
            label="End time"

          />
          <Grid container>
            <Grid item>
              <SwitchField name="dispenserConfig.isDayWise" label="Daywise Dose" />
            </Grid>
            <Grid item className={classes.info}>
              <Tooltip title="If you enable this mode, the system will only dose between your starting and ending times each day (for example 7am to 3pm). Be sure not to select the same time at the Start and End.">
                <InfoOutlined color={"primary"} />
              </Tooltip>
            </Grid>
          </Grid>

        </>
      )
    }
    return null
  }
  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...formProps}>
    <Form<FormFields> onSubmit={handleSubmit} {...formProps}>
      <SubTitle>{`For site ${store.siteName}`}</SubTitle>
      <TextField
        name="name"
        label="Name"
        validate={validators.required()}
        autoFocus
      />
      <SelectField
        name="dispenseDeviceId"
        label={
          store.isAvailableDevicesLoading ? 'Loading...' : 'Dispensing device'
        }
        displayEmpty
        fullWidth
      >
        <MenuItem>
          <em>
            {store.isAvailableDevicesLoading
              ? '...'
              : initialDeviceId
                ? 'Decommission device from pond'
                : store.availableDevices.length
                  ? 'Not deployed'
                  : 'None available'}
          </em>
        </MenuItem>
        {store.availableDevices.map(d => (
          <MenuItem key={d.id} value={d.id}>
            {d.displayName}
            {initialDeviceId === d.id && ' (current)'}
          </MenuItem>
        ))}
      </SelectField>
      <SelectField
        name="dispenserConfig.doseType"
        label={'Dose Type'}
        fullWidth>
        <MenuItem value="1">Auto</MenuItem>
        <MenuItem value="2">Hybrid</MenuItem>
        <MenuItem value="3">Manual</MenuItem>
      </SelectField>
      <DoseTypeBaseSettings />
      <SubTitle>Device settings</SubTitle>
      <NumberField
        name="floccSetLitres"
        label="Floc level"
        validate={validators.numericality({ allowBlank: true, greaterThan: 0 })}
        endAdornment="litres"
        helperText={
          store.floccRemainingLitres
            ? `Currently ${toFixed(
              store.floccRemainingLitres,
              0,
            )} litres remaining`
            : null
        }
      />
       <NumberField 
        name="dispenserConfig.catchmentAreaM2"
        label="Catchment area"
        validate={validators.numericality({ greaterThan: 0 })}
        endAdornment="m2"
      />
      {authStore.isAdmin && (
        <NumberField
        name="dispenserConfig.minimumDoseml"
        label="Minimum dose"
        validate={validators.numericality({ greaterThan: 0 })}
        endAdornment="ml"
      />
      )}
      {authStore.isAdmin && (
        <NumberField
        name="dispenserConfig.dosingTimeS"
        label="Dosing time"
        validate={validators.numericality({ greaterThan: 0 })}
        endAdornment="s"
      />
      )} {/*
      {authStore.isAdmin && (
        <SelectField
          name="dispenserConfig.rainType"
          label={'Rain Type'}
          fullWidth>
          <MenuItem value='1'>Default</MenuItem>
          <MenuItem value='2'>Disable rain dose</MenuItem>
          <MenuItem value='3'>Rain gauge</MenuItem>
      </SelectField>
      )} 
      {authStore.isAdmin && (
        <NumberField
        name="dispenserConfig.connectionIntervalMins"
        label="Connection interval"
        validate={validators.numericality({ greaterThan: 10, lessThan: 30})}
        endAdornment="min"
      />
      )}
      {authStore.isAdmin && (
        <NumberField
        name="dispenserConfig.connectionUpTimeSecs"
        label="Connection uptime"
        validate={validators.numericality({ greaterThan: 10, lessThan: 120})}
        endAdornment="s"
      />
      )} */}
      <Box className={classes.grid}>
        <NumberField
          name="dispenserConfig.doseMgPerLitre"
          label="Floc dosage"
          validate={validators.numericality({ greaterThan: 0 })}
          endAdornment="ml/m3"
        />
        <NumberField
          name="dispenserConfig.flowingRainMmPerHour"
          label="Dosing min rain intensity"
          validate={validators.numericality({ greaterThan: 0 })}
          endAdornment="mm/h"
          step="0.1"
        />
        <NumberField
          name="dispenserConfig.maxDryAfterDays"
          label="Time to fully dry ground"
          validate={validators.numericality({ greaterThan: 0 })}
          endAdornment="days"
        />
        <NumberField
          name="dispenserConfig.maxDryRainAbsorptionMm"
          label="Rain absorption fully dry"
          validate={validators.numericality({ greaterThan: 0 })}
          endAdornment="mm"
        />
        <SwitchField name="dispenserConfig.disableDose" label="Disable Dose" />
      </Box>
    </Form>
  ))
}
