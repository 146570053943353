import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { EditPondForm, FormFields } from './EditPondForm';
import { PondEditStore } from './pondEditStore';

export interface IRouteParams {
  pondId?: string
  siteId: string
}

export const EditPondPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const { pondId, siteId } = match.params
  const store = useStoreClass(PondEditStore, siteId, pondId)
  const save = async (fields: FormFields) => {
    await store.save(fields)
    browserHistory.goBack()
  }
  return useObserver(() => (
    <EditPage
      isLoading={store.isLoading}
      heading={store.isNew ? 'New Pond' : 'Edit Pond'}
    >
      <EditPondForm
        isNew={store.isNew}
        initialValues={store.initialValues}
        store={store}
        onResult={save}
      />
    </EditPage>
  ))
}
