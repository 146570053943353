import { EditPage } from '@any-ui/core'
import { browserHistory } from '@any-ui/core/navigation'
import { useStoreClass } from '@any-ui/core/store'
import { useObserver } from 'mobx-react-lite'
import React, { FC } from 'react'
import { EditProfileForm, FormFields } from './EditProfileForm'
import { ProfileEditStore } from './profileEditStore'

export const EditProfilePage: FC = () => {
  const store = useStoreClass(ProfileEditStore)
  const save = async (profile: FormFields) => {
    await store.save(profile)
    browserHistory.goBack()
  }
  return useObserver(() => (
    <EditPage heading="Your Profile" isLoading={store.isLoading}>
      <EditProfileForm
        initialValues={store.initialValues}
        onResult={save}
        store={store}
      />
    </EditPage>
  ))
}
