import { HttpsCallableCollAdapter } from '@any-ui/core'
import { observable } from 'mobx'
import { apiCall } from '../../firebase'
import {
  IUser,
  ListUsersWithoutRoleData,
  UpdateUserRoleData,
} from '../../model/user'
import { AdminUsersStore, IUserDoc } from './adminUsersStore'

const listUsersByRole = apiCall<IUser[]>(
  'listUsersByRole',
  ListUsersWithoutRoleData,
)
const removeUserRole = apiCall('removeUserRole', UpdateUserRoleData)

export class AdminUsersCollection extends HttpsCallableCollAdapter<IUserDoc> {
  constructor(adminStore: AdminUsersStore) {
    super(
      () => listUsersByRole({ role: 'admin' }),
      data => {
        const user: IUserDoc = observable({
          data,
          handleSelect: async () => {
            user.removing = true
            await removeUserRole({ uid: data.uid, role: 'admin' })
            adminStore.reload()
          },
          id: data.uid,
          removing: false,
        })
        return user
      },
    )
  }
}
