import { PageTitle } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { Button, Card, CardActions, CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import QrReader from 'react-qr-reader';

import { Bullet, CustomerAddress } from '../app/Icons';
import { customerStore } from '../customer/customerStore';
import { DeviceEditStore } from '../device/deviceEditStore';
import { deviceStore } from '../device/deviceStore';
import { DeviceStatus } from '../tools/DeviceStatus';
import { sitesStore } from './store/sitesStore';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: 10,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 1,
  },
  customername: {
    marginTop: 20,
  },
  location: {
    display: 'flex',
    marginBottom: 5,
  },
  textColor: {
    color: 'rgba(255,255,255,0.25)',
  },
  divider: {
    marginTop: 20,
  },
  cardaction: {
    display: 'flex',
    flexFlow: 'column',
  },
  otherBtnContainer: {
    marginTop: 20,
    paddingBottom:60
  },
  actionBtn: {
    height: 40,
    marginBottom: 20,
  },
  otherBtn: {
    minWidth: 160,
    marginTop: 10,
  },
})

export const ScanQrCodePage: FC = () => {
  

  const classes =  useStyles({})
  const [scanId, setScanId] = useState('')
  const [deviceId, setDeviceId] = useState();
  const store = useStoreClass(DeviceEditStore, deviceId)
  const [deviceInfo, setDeviceInfo] = useState()
  let deviceObj;
  
  const handleScan = (data: any) => {
    if (data) {
      setScanId(data)
      const deviceInfo = deviceStore.getDeviceInfoByQrCode(data)
      deviceInfo.then(p => {
        deviceObj = p.docs[0].data;
        setDeviceInfo(deviceObj);
        setDeviceId(p.docs[0].id);
      })
    }
  }
  const handleError = (err: any) => {
    if (err) {
      
    }
  }
  const onAction = () => {
    if (
      deviceInfo.status === DeviceStatus.UNALLOCATED ||
      deviceInfo.status === DeviceStatus.OFFHIRE
    ) {
      browserHistory.push(`/devices/${deviceId}/edit`)
    } else {
      browserHistory.push(`/sites/${deviceInfo.siteId}`)
    }
  }

  const onChangeStatus = (event:any) =>{
    if(event.currentTarget.id === DeviceStatus.UNALLOCATED) {
      store.markUnallocated()
    } else {
      store.endHireDevice();
    }
   //  deviceStore.updateDeviceStatus(deviceId,event.currentTarget.id);
    browserHistory.push(`/devices/`);
  }
  const renderStatus = (status: string) => {
    if (status === DeviceStatus.ALLOCATED) {
      return (
        <Typography component={'span'} variant={'body2'} className={classes.location}>
          <Bullet color="primary" />{' '}
          <Typography color="primary" component={'span'} variant={'body2'}>{status}</Typography>
        </Typography>
      )
    } else if (status === DeviceStatus.UNALLOCATED) {
      return (
        <Typography component={'span'} variant={'body2'} className={classes.location}>
          <Bullet color="secondary" />{' '}
          <Typography color="secondary" component={'span'} variant={'body2'}>{status}</Typography>
        </Typography>
      )
    } else {
      return (
        <Typography component={'span'} variant={'body2'} className={classes.location}>
          <Bullet /> <Typography color="textSecondary" component={'span'} variant={'body2'}>{status}</Typography>
        </Typography>
      )
    }
  }
  return useObserver(() => (
    <>
      {!scanId ? (
        <div>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        </div>
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent>
              <PageTitle>Device Info</PageTitle>
              <Typography className={classes.pos} color="textSecondary">
                Serial Number
              </Typography>
              <Typography variant="h5" component="h2" className={classes.pos}>
                {deviceInfo && deviceInfo.serial}
              </Typography>
              {/* <Typography className={classes.pos} color="textSecondary">
                QR Code: {deviceInfo && deviceInfo.qrCode}
              </Typography> */}

              <Typography
                className={classes.customername}
                color="textSecondary"
              >
                {deviceInfo && customerStore.customerName(deviceInfo.ownerUid)}
              </Typography>
              <Typography
                component={'span'}
                variant={'body2'}
                className={classes.location}
              >
                <CustomerAddress />
                <Typography color="textSecondary">
                  {deviceInfo &&
                    deviceInfo.siteId &&
                    sitesStore.siteName(deviceInfo.siteId)}
                </Typography>
              </Typography>
              <Typography>
                {deviceInfo && renderStatus(deviceInfo.status)}
              </Typography>
              <Divider className={classes.divider} />
            </CardContent>
            <CardActions className={classes.cardaction}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.actionBtn}
                onClick={onAction}
              >
                {deviceInfo &&
                (deviceInfo.status === DeviceStatus.UNALLOCATED ||
                  deviceInfo.status === DeviceStatus.OFFHIRE) ? (
                  <> Edit Device Details</>
                ) : (
                  <>Go to Pond</>
                )}
              </Button>
            </CardActions>
          </Card>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            className={classes.otherBtnContainer}
          >
            {deviceInfo && deviceInfo.status === DeviceStatus.ALLOCATED && (
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  className={classes.otherBtn}
                  color="primary"
                  size="large"
                  onClick={()=>browserHistory.push(`/devices/${deviceId}/edit`)}
                >
                  Edit Device Details
                </Button>
              </Grid>
            )}
            {deviceInfo &&
              (deviceInfo.status === DeviceStatus.ALLOCATED ||
                deviceInfo.status === DeviceStatus.UNALLOCATED) && (
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    className={classes.otherBtn}
                    color="secondary"
                    size="large"
                    id={DeviceStatus.OFFHIRE}
                    onClick={onChangeStatus}
                  >
                    End Hire
                  </Button>
                </Grid>
              )}
            {deviceInfo && deviceInfo.status === DeviceStatus.ALLOCATED && (
              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  className={classes.otherBtn}
                  color="secondary"
                  size="large"
                  id={DeviceStatus.UNALLOCATED}
                  onClick={onChangeStatus}
                >
                  Mark as Unallocated
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  ))
}
