import { amber, deepOrange, green } from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import {
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ShapeOptions } from '@material-ui/core/styles/shape';
import '../fonts.css'

// tslint:disable:object-literal-sort-keys

declare module '@material-ui/core/styles/createPalette' {
  // tslint:disable-next-line:interface-name
  interface Palette {
    alarms: {
      critical: PaletteColor
      low: PaletteColor
      none: PaletteColor
    }
  }
  // allow configuration using `createMuiTheme`
  // tslint:disable-next-line:interface-name
  interface PaletteOptions {
    alarms?: {
      critical?: PaletteColorOptions
      low?: PaletteColorOptions
      none?: PaletteColorOptions
    }
  }
}

export const defaultDrawerWidth = 260

interface IColors {
  primary: SimplePaletteColorOptions
}
const colors: IColors = {
  primary: {
    // light: '#9cff57',
    // main: lightGreen.A400,
    // dark: '#1faa00',
    // contrastText: '#000',
    light: '#FFE500',
    main: '#FFE500',
    dark: '#FFE500',
    // contrastText: '#FFF',
    contrastText: '#000',
  },
}

export const palette: PaletteOptions = {
  type: 'dark',
  alarms: {
    critical: {
      light: deepOrange[400],
      main: deepOrange.A400,
      // main: '#f50057', // PINK
      // contrastText: amber[200],
      // contrastText: '#000',
      contrastText: '#FFF',
    },
    low: {
      light: amber[500],
      main: amber[500],
      // contrastText: deepOrange[800],
      // contrastText: '#FFF',
      contrastText: '#000',
    },
    none: { main: green[500] },
    // none: { main: green[700] },
  },
  primary: {
    main: colors.primary.main!,
    contrastText: colors.primary.contrastText!,
    // main: '#ff0000',
  },
  secondary: {
    main: '#91FF35',
  },
  warning: {
    main: deepOrange[400],
  },
  background: {
    paper: '#2F3235',
    default: '#000000',
  },
  text: {
    primary: '#FFE500',
    secondary: '#FFE500',
  }
}

export const shape: ShapeOptions = {
  borderRadius: 8,
}
const fieldBackgroundColor = 'rgba(0, 0, 0, 0.08)'
const fieldBackgroundColorFocus = 'rgba(0, 0, 0, 0.09)'
const fieldBorderColor = 'rgba(0, 0, 0, 0.25)'
const fieldBorderColorHover = 'rgba(0, 0, 0, 0.25)'

export const overrides: Overrides = {
  // @ts-ignore
  AuiSelectableComponent: {
    root: {
      border: `0 solid transparent`,
      transition: 'border-color ease-out 250ms',
    },
    selectable: {
      borderWidth: 2,
    },
    selected: {
      background:
        'linear-gradient(135deg,rgba(20,29,41,.5),rgba(84,120,144,.5))',
      borderColor: colors.primary.main!,
    },
  },
  AuiResponsiveTitle: {
    root: {
      fontFamily: 'Gill Sans Nova Book',
      fontWeight: 900,
      '&.SubTitle': {
        color: '#A58D6A',
      },
      '&.PageTitle': {
        color: colors.primary.main!,
      },
      '&.AppBarTitle': {
        color: colors.primary.main!,
        '@media (max-width:600px)': {
          display: 'flex',
          width : '100%'
        },
      },
    },
  },
  AuiDocsCollectionTitle: {
    root: {
      color: '#A58D6A',
    },
  },
  MuiInputLabel: {
    shrink: {
      opacity: 0.75,
      '&.Mui-focused': {
        opacity: 1,
      },
    },
  },
  MuiInputAdornment: {
    root: {
      '& .MuiTypography-body1': {
        opacity: 0.15,
        fontSize: '1.1em',
        fontWeight: 800,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      //     color: colors.primary.main!,
      borderColor: fieldBorderColor,
      // borderColor: fieldBackgroundColor,
      backgroundColor: fieldBackgroundColor,
      '&:hover': {
        borderColor: fieldBorderColorHover,
        backgroundColor: fieldBackgroundColorFocus,
      },
      '& $notchedOutline': {
        // borderColor: fieldBackgroundColor,
        borderColor: fieldBorderColor,
      },
      '&:hover $notchedOutline': {
        borderColor: fieldBorderColorHover,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: fieldBorderColorHover,
        },
      },
      '&$focused $notchedOutline': {
        backgroundColor: fieldBackgroundColorFocus,
        borderWidth: 2,
      },
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: 0,
      //     color: colors.primary.main!,
      backgroundColor: 'transparent',
    },
  },
  MuiInput: {
    root: {
      color: colors.primary.main!,
      backgroundColor: fieldBackgroundColor,
      marginBottom: 10,
    },
  },
  MuiButton: {
    root: {
      borderRadius: 6,
      minHeight: 30,
      padding: '0 1em',
      '&.MuiButton--large': {
        minHeight: 39,
      },
    },
    label: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 500,
    },
    outlined: {
      padding: '0 1em',
    },
    outlinedPrimary: {
      borderColor: colors.primary.main!,
      color: colors.primary.main!,
      '&:hover': {
        borderColor: colors.primary.main!,
        color: colors.primary.main!,
        backgroundColor: colors.primary.dark!,
      },
    },
    contained: {
      minHeight: 30,
      boxShadow: 'none',
      '&$focusVisible': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      backgroundColor: colors.primary.main!,
      color: colors.primary.contrastText,
      '&:hover': {
        backgroundColor: colors.primary.light!,
        '@media (hover: none)': {
          backgroundColor: colors.primary.light!,
        },
      },
    },
    sizeLarge: {
      padding: '0 1em',
    },
    sizeSmall: {
      label: {
        fontSize:10
      }
    }
  },
  MuiDrawer: {
    docked: {
      width: defaultDrawerWidth,
    },
    paper: {
      width: defaultDrawerWidth,
    },
  },
}

export const themeOptions: ThemeOptions = {
  overrides,
  palette,
  shape,
  typography: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
}
