import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import { authStore } from '../../auth/authStore';
import { DeviceRequestListItemBase } from './DeviceRequestListItemBase';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: theme.palette.action.selected,
    fontSize: '1.1rem',
    // tslint:disable-next-line:object-literal-sort-keys
    '.MuiPaper-rounded &': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
  },
}))

export const DeviceRequestListTitle = () => {
  const classes =  useStyles({})
  let action;
  if(authStore.isAdmin) {
   action = 'Actions'
  } else if(authStore.isCustomer) {
    action = 'Status'
  }
  return (
    <DeviceRequestListItemBase
      customerName="Customer"
      noOfDevice="Quantity"
      deliveryAddress="Delivery Address"
      deliveryInstructions="Delivery Instructions"
      action ={action}
      className={clsx('DeviceRequestListTitle', classes.root)}
      button={false}
    />
  )
}
