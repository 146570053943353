import { AppBarLogo, AppContent, AppLayout, AppTitle } from '@any-ui/core'
import { useObserver } from 'mobx-react-lite'
import React, { FC } from 'react'
import { AutoflocTitle } from './AutoflocTitle'
import { Logo } from './Logo'
import { TopRoutes } from './TopRoutes'

export const ViewerRoot: FC = () =>
  useObserver(() => (
    <AppLayout>
      <AppBarLogo>
        <Logo marginLeft={2.8} marginRight={3} />
      </AppBarLogo>
      <AppTitle>
        <AutoflocTitle />
      </AppTitle>
      <AppContent>
        <TopRoutes />
      </AppContent>
    </AppLayout>
  ))
