import { DocsView } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { customerStore } from '../../customer/customerStore';
import { DeviceDoc } from '../../db/deviceDoc';
import { DeviceRetriev } from './DeviceRetriev';
import { DeviceRetrievalListItemBase } from './DeviceRetrievalListItemBase';

export const DeviceRetrievalListItem: DocsView<DeviceDoc> = ({
  document,
  ...rest
}) =>
  useObserver(() => (
    <DeviceRetrievalListItemBase
      serial={document.displayName}
      customerName={
        (document.data.ownerUid &&
          customerStore.customerName(document.data.ownerUid)) ||
        undefined
      }
      deployedToPond={document.deployedToPond && document.deployedToPond.sitePondName}
      action={<DeviceRetriev deviceId={document.id} />}
      {...rest}
    />
  ))
