import { Coll, IColl } from '@any-ui/core/docs';
import { Collection, Mode } from 'firestorter';
import isString from 'lodash/isString';
import { computed } from 'mobx';

import { AlarmDoc } from '../alarms/alarmDoc';
import { PondDoc } from '../db/pondDoc';
import { SiteDoc } from '../db/siteDoc';
import { IPond } from '../model/pond';

export const activeAlarmsForPonds = (ponds: IColl<PondDoc>): IColl<AlarmDoc> =>
  Coll.flatMap(ponds, pond => pond.activeAlarms.docs)

class PondStore {
  @computed
  get usedDevicesIds(): string[] {
    return this.ponds.docs
      .map(pondDoc => pondDoc.data.dispenseDeviceId)
      .filter(isString)
  }

  public ponds = new Collection<PondDoc>('ponds', {
    createDocument: (...args) => new PondDoc(...args),
    mode: Mode.On,
    // query: ref => narrowByOwnershipOrViewerSite(ref),
    query: ref => ref
  })

  public async updatePond(pond: PondDoc, values: Partial<IPond>) {
    // tslint:disable-next-line:no-console
    // console.log(`${pond.displayName} values`, values)
    return await pond.set(values, { merge: true })
  }

  public withDispenserDeviceId(deviceId: string): PondDoc | null {
    return (
      this.ponds.docs.find(s => s.data.dispenseDeviceId === deviceId) || null
    )
  }

  public pondsAtSite(siteDoc: SiteDoc): IColl<PondDoc> {
    return Coll.filter(this.ponds, pond => pond.data.siteId === siteDoc.id)
  }

  public commissionedPonds(siteDoc: SiteDoc): IColl<PondDoc> {
    return Coll.filter(this.pondsAtSite(siteDoc), pond => pond.isCommissioned)
  }

  public hasActivePonds(siteDoc: SiteDoc): boolean {
    return this.pondsAtSite(siteDoc).docs.some(pond => !pond.isDeCommissioned)
  }
  public updateSiteStatus = async (siteId: any, dispenseDeviceId: any) => {
    if (siteId) {
      let isActive: boolean
      const ponds = await new Collection('ponds', {
        mode: Mode.Off,
        query: ref => ref.where('siteId', '==', siteId),
      }).fetch()
      const activePonds = ponds.docs.filter((p: any) => p.data.dispenseDeviceId)
      if (activePonds.length || dispenseDeviceId) {
        isActive = true
      } else {
        isActive = false
      }
      const site = await new SiteDoc(`sites/${siteId}`, { mode: Mode.Off })
      site.set({ isActive }, { merge: true })
    }
  }
}

export const pondStore = new PondStore()
