import { Collection, Mode } from 'firestorter';

import { DeletedPondDoc } from '../db/deletedPondDoc';
import { PondDoc } from '../db/pondDoc';
import * as log from '../log';


// import { unexpectedFormSubmitError } from '../error'
export class PondDeleteStore {
    private readonly initialDoc: PondDoc | any = null

    constructor(pondId?: string) {
        if (pondId) {
            this.initialDoc = new PondDoc(`ponds/${pondId}`, { mode: Mode.Off })
            this.initialDoc.fetch().catch(log.error)
        }
    }
    public deletedPonds = new Collection<DeletedPondDoc>('deletedPonds', {
        createDocument: (...args) => new DeletedPondDoc(...args),
        mode: Mode.Off
    })
    public delete = async () => {
       // const deletePond = await new PondDoc(`ponds/${this.initialDoc.id}`, { mode: Mode.Off }).fetch()
        if (!this.initialDoc.data.dispenseDeviceId) {
            await this.deletedPonds.add(this.initialDoc.data)
            await this.initialDoc.delete()
        }
        
    }

}
