import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

export const ActionCaption: FC = props => (
  <Typography
    variant="subtitle1"
    component="h6"
    color="textSecondary"
    {...props}
  />
)
