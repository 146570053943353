import { globalFormError, mapValidationErrors } from '@any-ui/form'
import { IdTokenResult, User } from '@firebase/auth-types'
import * as Sentry from '@sentry/browser'
import { Document } from 'firestorter'
import {hasRoles} from '../model/user'
import { action, computed, observable } from 'mobx'
import { firebase } from '../firebase'
import { AsUpdate, FieldValue } from '../firestore'
import * as log from '../log'
// import { CompanyStaffDoc } from '../db/companyStaffDoc';

import {
  IUserMeta,
  IUserProfile,
  IUserProfileUpdate,
  Role,
  rolesFromClaims,
} from '../model/user'
import { viewerStore } from '../site/store/viewerStore'

interface IUserData {
  user: User
  token: IdTokenResult
}

class AuthStore {
  @observable
  public isLoading = true

  @observable
  public error: Error | null = null

  @computed
  get isAuthenticated() {
    return this.roles.length > 0
  }

  @computed
  get isSignedUp() {
    return !!this.user
  }

  @computed
  get isAdmin() {
    return this.roles.includes('admin')
  }

  @computed
  get isAdminOrCustomer() {
    
    return this.roles.includes('admin') || this.roles.includes('customer');
  }


  private async getShortDelay() {
    await new Promise((resolve) => setTimeout(resolve, 500));
  }

  @computed
  get isCustomer() {
    return this.roles.includes('customer')
  }

  @computed
  get isCompanyStaff() {
    return this.roles.includes('companyStaff')
  }

  @computed
  get isViewer() {
    return viewerStore.isViewer
  }

  @computed
  get isDev() {
    return this.roles.includes('dev')
  }

  @computed
  get uid(): string | null {
    return this.user ? this.user.uid : null
  }

  // @computed
  // get companyStaff(): string | null {
  //   if (this.isCompanyStaff) {
  //     const companyStaffUser = new CompanyStaffDoc(`companyStaffs/${this.uid}`, { mode: Mode.Off }).fetch()
        
  //     return companyStaffUse
  //   }
  //   else {
  //     return null
  //   }
  // }
  
  // Can't be computed as emailVerified is not observable
  get isEmailVerified(): boolean {
    return (this.user && this.user.emailVerified) || false
  }

  @computed
  private get user(): User | null {
    return this.data && this.data.user
  }

  @computed.struct
  private get roles(): Role[] {
    console.log("getting roles!!!!!!!!!!!!!!!!!!")
    return this.data && this.data.token
      ? rolesFromClaims(this.data.token.claims)
      : []
  }

  @observable
  private data: IUserData | null = null

  private checkingVerificationEmailUid: string | null = null

  constructor() {
    firebase.auth().onIdTokenChanged(async user => {
      log.dev('authStateChanged', user)
      if (!user) {
        this.setLoadedUserData(null)
        return
      }
      let token: IdTokenResult | null = null
      try {
        token = await user.getIdTokenResult()
      } catch (e) {
        log.error('Failed to get ID token', e)
        this.setLoadedUserData(null, e)
        return
      }
      this.setLoadedUserData({ user, token })
      try {
        await this.checkVerificationEmail(user)
      } catch (e) {
        log.error('Failed to checkVerificationEmail', e)
        return
      }
    })
  }

  // Can't be computed as User is a class instance so properties of user (email, displayName) are not observable
  public buildUserProfile(): IUserProfile | null {
    if (!this.user) {
      return null
    }
    if (!this.user.email) {
      throw new Error('Unexpectedly no email on user')
    }
    return {
      contactName: this.user.displayName,
      email: this.user.email,
    }
  }

  public async updateUser(userUpdate: IUserProfileUpdate) {
    if (!this.user) {
      throw new Error('Can only update when authenticated')
    }
    const user = this.user // Hold on to reference so it doesn't change after await's
    try {
      // Change email first as it may produce auth/requires-recent-login
      if (user.email !== userUpdate.email) {
        await user.updateEmail(userUpdate.email)
        await this.clearVerificationEmailSentFlag(user)
        await this.checkVerificationEmail(user)
      }
      if (userUpdate.newPassword) {
        await user.updatePassword(userUpdate.newPassword)
      }
      if (user.displayName !== userUpdate.contactName) {
        await user.updateProfile({
          displayName: userUpdate.contactName,
          photoURL: null,
        })
      }
    } catch (e) {
      if (e.code === 'auth/requires-recent-login') {
        throw globalFormError(
          'Sensitive details can only be updated after recently signing in, please sign out and in again',
        )
      }
      throw mapValidationErrors<IUserProfileUpdate>(e, {
        'auth/email-already-in-use': 'email',
        'auth/invalid-email': 'email',
        'auth/weak-password': 'newPassword',
      })
    }
  }

  public signOut = () => {
    firebase
      .auth()
      .signOut()
      .catch(e => log.error('signOut failed', e))
  }

  public refreshTokenInBackground = () => {
    if (!this.user) {
      return
    }
    // If it succeeds then onIdTokenChanged will fire
    this.user.getIdTokenResult(true).catch(e => {
      log.error('Failed to refresh ID token, signing out', e)
    })
  }

  public sendEmailVerification = async () => {
    if (!this.user) {
      return
    }
    await this.sendEmailVerificationTo(this.user)
  }

  @action
  private setLoadedUserData(
    data: IUserData | null,
    error: Error | null = null,
  ) {
    Sentry.setUser(
      data && {
        email: data.user.email || undefined,
        id: data.user.uid,
      },
    )
    this.data = data
    this.error = error
    this.isLoading = false
  }

  private userMetaDoc(user: User) {
    return new Document<IUserMeta>(`usersMeta/${user.uid}`)
  }

  private async sendEmailVerificationTo(user: User) {
    await user.sendEmailVerification()
    const update: AsUpdate<IUserMeta> = {
      verificationEmailSentTimestamp: FieldValue.serverTimestamp(),
    }
    await this.userMetaDoc(user).set(update, { merge: true })
  }

  private async checkVerificationEmail(user: User) {
    if (user.uid === this.checkingVerificationEmailUid) {
      log.dev('Ignoring checkVerificationEmail already in progress')
      return
    }
    try {
      this.checkingVerificationEmailUid = user.uid
      const userMetaDoc = await this.userMetaDoc(user).fetch()
      if (userMetaDoc.data.verificationEmailSentTimestamp) {
        return
      }
      await this.sendEmailVerificationTo(user)
    } finally {
      this.checkingVerificationEmailUid = null
    }
  }

  private async clearVerificationEmailSentFlag(user: User) {
    const update: AsUpdate<IUserMeta> = {
      verificationEmailSentTimestamp: null,
    }
    await this.userMetaDoc(user).set(update, {
      merge: true,
    })
  }
}

export const authStore = new AuthStore()
