import { AlarmKey, IPondAlarms } from './alarm';
import { batteryVoltsToPercentage } from './battery';
import { IHasOwner } from './customer';
import { DEFAULT_DOSE_GRAMS_PER_LITRE, IDispenserConfig, LITRES_PER_HECTARE_MM } from './dispenserConfig';
import { toFixed, toInt } from './format';
import { IFirestoreTimestamp } from './timestamp';
import * as log from '../log';

export interface IDose {
  seconds: number
  litres: number
  metricsTimestamp: IFirestoreTimestamp
}

export interface IFloccSet {
  remainingG: number
  timestamp: IFirestoreTimestamp
}

export interface IPond extends IHasOwner {
  name: string
  dispenserConfig: IDispenserConfig
  siteId: string
  siteName: string
  dispenseDeviceId: string | null
  firstCommissionedTimestamp?: IFirestoreTimestamp
  decommissionedTimestamp?: IFirestoreTimestamp
  metricsLastSetTimestamp?: IFirestoreTimestamp
  pendingBatchDoseG?: number | null
  pendingReset?: boolean | false
  floccDosedG?: number | null
  floccSet?: IFloccSet
  batteryVoltage?: number | null
  rainGaugeTimestamp?: IFirestoreTimestamp
  rainGaugeFault?: boolean | null
  siphonFault?: boolean | null
  valveFault?: boolean | null
  valveLastResetTimestamp?: IFirestoreTimestamp
  lastDose?: IDose
  alarms?: IPondAlarms
  isArchive?: boolean | false
  pondId?: string
  NotifyUserPending?: boolean | false
  SignalQualityPercent? : number | 0
  SignalStrengthPercent?: number | 0
}

export const pondFullName = (pond: IPond): string =>
  `${pond.siteName} device ${pond.name}`

// const getDoseGramsPerLitre = (config: IDispenserConfig): number =>
//   config && config.doseRate
//     ? config.doseRate * LIQUID_PAC_GRAMS_PER_LITRE
//     : DEFAULT_DOSE_GRAMS_PER_LITRE

const getDoseGramsPerLitre = (config: IDispenserConfig): number =>
  config && config.doseRate
    ? config.doseRate
    : DEFAULT_DOSE_GRAMS_PER_LITRE

export const getDoseGramsPerMmRain = (pond: IPond): number => {
  const config = pond.dispenserConfig
  const inflowLitresPerRainMm =
    config.catchmentAreaHectares * LITRES_PER_HECTARE_MM
  return inflowLitresPerRainMm * getDoseGramsPerLitre(config)
}

const getFloccRemainingG = (pond: IPond): number | null => {
  if (pond.floccDosedG == null || pond.floccSet == null) {
    return null
  }
  let remainingFloc = pond.floccSet.remainingG - pond.floccDosedG
  remainingFloc = remainingFloc < 0 ? 0 : remainingFloc
  return remainingFloc
}

export const getFloccRemainingMm = (pond: IPond): number | null => {
  const floccRemainingG = getFloccRemainingG(pond)
  if (floccRemainingG == null) {
    return floccRemainingG
  }
  return floccRemainingG / getDoseGramsPerMmRain(pond)
}

export const getFloccRemainingLitres = (pond: IPond): number | null => {
  const floccRemainingG = getFloccRemainingG(pond)
  if (floccRemainingG == null) {
    return floccRemainingG
  }
  //return floccRemainingG / LIQUID_PAC_GRAMS_PER_LITRE
  return floccRemainingG

}

const getLastDoseFlowLitresPerMinute = (pond: IPond): number | null => {
  if (pond.lastDose == null) {
    return null
  }
  return pond.lastDose.litres / (pond.lastDose.seconds / 60)
}

export const getLastDoseFlowLitres = (pond: IPond): number => {
  log.error(`FW getLastDoseFlowLitres pond: ${pond.name} `);
  if (!pond.lastDose) {
    log.error(`FW getLastDoseFlowLitres: null`)
    return 0;
  }
  log.error(`FW getLastDoseFlowLitres: ${pond.lastDose.litres}`)
  return pond.lastDose.litres; 
}

export const alarmMetric = (pond: IPond, alarm: AlarmKey): string | null => {
  switch (alarm) {
    case 'BATTERY_LEVEL':
      const percentage = batteryVoltsToPercentage(pond.batteryVoltage)
      if (percentage == null) {
        return null
      }
      return `${toInt(percentage)} %`
    case 'FLOCC_LEVEL':
      const rainMm = getFloccRemainingMm(pond)
      if (rainMm == null) {
        return null
      }
      return `${toInt(rainMm)} rain mm`
      case 'OVERDOSED_LEVEL':
        const overdosedL = getLastDoseFlowLitres(pond)
        if (overdosedL == 0) {
          return null
        }
        return `${toInt(overdosedL)} L`
    // case 'DOSE_FLOW_FAULT':
    //   const rate = getLastDoseFlowLitresPerMinute(pond)
    //   if (rate == null) {
    //     return null
    //   }
    //   return `${toFixed(rate, 2)} l/min`
  }
  return null
}
