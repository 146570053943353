import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { SiteListPage } from './SiteListPage'
import { UserSiteViewPage } from './UserSiteViewPage'

export const SiteRoutes: FC = () => (
  <Switch>
    <Route exact path="/sites" component={SiteListPage} />
    <Route path="/sites/new" component={UserSiteViewPage} />
    <Route path="/sites/:siteId" component={UserSiteViewPage} />
    <Redirect to="sites" />
  </Switch>
)
