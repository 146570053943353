import { Collection, Mode } from 'firestorter';

import { PondDoc } from '../db/pondDoc';
import { FieldValue } from '../firestore';


class PondHistoryStore {
    public pondHistory = new Collection<PondDoc>('pondHistory', {
        createDocument: (...args) => new PondDoc(...args),
        mode: Mode.Off
    })

    public save = async (pondId: string | any) => {
        const pond: any = await new PondDoc(`ponds/${pondId}`, {
            mode: Mode.Off,
        }).fetch()
        pond.data.pondId = pondId
        pond.data.addedTimestamp = FieldValue.serverTimestamp()
        await this.pondHistory.add(pond.data)
    }

    public getPondHistory = async (pondId: string = '') => {
        const pondHistoryData = await new Collection('pondHistory', {
            mode: Mode.Off,
            query: ref => ref.where('pondId', '==', pondId),
          }).fetch()
        
        // console.log('this.pondHistory', pondHistoryData);
        return pondHistoryData
    }

}

export const pondHistoryStore = new PondHistoryStore()
