import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { authStore } from '../auth/authStore';
import { ICustomerUpdate } from '../model/customer';
import { CustomerListStore } from './components/customerListStore';
import { CustomerEditStore } from './customerEditStore';
import { EditCustomerForm } from './EditCustomerForm';

// import { CompanyStaffEditStore } from '../companyStaff/companyStaffEditStore'
export interface IRouteParams {
  uid?: string
  email?:string
  contactName?:string
}

export const EditCustomerPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const { uid,email,contactName } = match.params
 
  const store = useStoreClass(CustomerEditStore, uid)
  const cust_store = useStoreClass(CustomerEditStore, '0')
  const newCustomers = useStoreClass(CustomerListStore, cust_store);
  const save = async (customer: ICustomerUpdate) => {
    authStore.isLoading = true;
    await store.save(customer)
    await newCustomers.getNewUsers()
    authStore.isLoading = false;
    browserHistory.goBack()
  }
  // const storeRM = useStoreClass(CompanyStaffEditStore, uid)
  // const save = async () => {
  //   storeRM.save({
  //     email: 'alex_zender07@doubleyolk.com',
  //     enabled: false,
  //     newPassword: 'Doubleyolk123',
  //     ownerUid: 'mf0Ch1OE8NcXx9tQ0PUdaj1lpGf2',
  //     phoneNumber: '+64 21 676140',
  //     siteIds: ['1EdJ0n048kVN8NZx96q4', 'tTphKl3DXz4VXtw12oVT'],
  //     firstName: 'Alex',
  //     lastName: 'Zender4'
  //   })
  //   browserHistory.goBack()
  // }
  return useObserver(() => (
    <EditPage
      isLoading={store.isLoading}
      heading={`${store.isNew ? 'Add' : 'Modify'} customer`}
    >
      <EditCustomerForm
          isNew={store.isNew}
          existsInAuth={store.existsInAuth}
          initialValues={store.initialValues?store.initialValues : {email,contactName,enabled:true}}
          onResult={save}
        />
      {/* {store.initialValues ? (
        <EditCustomerForm
          isNew={store.isNew}
          existsInAuth={store.existsInAuth}
          initialValues={store.initialValues?store.initialValues : {email,enabled:true}}
          onResult={save}
        />
      ) : (
          <FindUserForm onResult={store.find} initialValues={{}}>
            <SelectNewCustomerList customerStore={store} />
          </FindUserForm>
        )} */}
    </EditPage>
  ))
}