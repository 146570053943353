import * as mobx from 'mobx'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app/App'
import * as env from './env'
import { captureErrors } from './error'
import './index.css'
import './fonts.css'

captureErrors()

if (env.development) {
  mobx.configure({ computedRequiresReaction: true })
}

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)
