import { useConfirm } from '@any-ui/core'
import { browserHistory } from '@any-ui/core/navigation'
import {
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  requiredString,
  SelectField,
  TextField,
  SwitchField,
} from '@any-ui/form'
import {
  Checkbox,
  IconButton,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { DeleteStaff } from '../app/Icons'
import { authStore } from '../auth/authStore'
import { customerStore } from '../customer/customerStore'
import { ICompanyStaff } from '../model/companyStaffModal'
import { sitesStore } from '../site/store/sitesStore'
import { companyStaffStore } from './companyStaffStore'

interface IProps {
  uid: any
}

export type FormFields = Pick<
  ICompanyStaff,
  'ownerUid' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'siteIds' | 'newPassword' | 'isContactPoint'
>
const useStyles = makeStyles<Theme, {}, any>(theme => ({
  iconButton: {
    marginTop: '-70px',
    MuiSvgIcon: {
      fontSize: '15px',
    },
  },
}))

export const EditCompanyStaffForm: FormComponent<FormFields, IProps> = ({
  onResult,
  uid,
  ...formProps
}) => {
  const classes = useStyles({})
  const handleSubmit: FormSubmitHandler<FormFields> = async values => {
    values.siteIds = siteIds
    // console.log(values)
    await onResult({
      ownerUid: emptyToNull(values.ownerUid),
      firstName: emptyToNull(values.firstName),
      lastName: emptyToNull(values.lastName),
      email: requiredString(values.email),
      phoneNumber: requiredString(values.phoneNumber),
      isContactPoint: Boolean(values.isContactPoint),
      siteIds: values.siteIds,
      newPassword: emptyToNull(values.newPassword)
    })
  }
  const [siteIds, setSiteIds] = React.useState<string[]>(
    formProps.initialValues ? formProps.initialValues.siteIds : [],
  )
  const handleChage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSiteIds(event.target.value as string[])
  }
  const onDeleteCompanyStaff = useConfirm({
    message: 'Are you sure you want to delete the company staff?',
    onCancel: () => { },
    onConfirm: async () => {
      await companyStaffStore.deleteCompanyStaff(uid);
      browserHistory.push('/companystaffs');
    },
    title: `Delete Companystaff`,
  });

  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...formProps}>
    <>
      <Form<FormFields>
        onSubmit={handleSubmit}
        {...formProps}
        submitLabel="Add"
      >
        <TextField name="firstName" label="First Name" />
        <TextField name="lastName" label="Last Name" />
        <TextField name="email" label="E-mail ID" />
        {/* <PasswordField name="newPassword" label="Password" /> */}
        <TextField name="phoneNumber" label="Phone Number" />
        {authStore.isAdmin && (
          <SelectField name="ownerUid" label="Select Customer" fullWidth>
            {customerStore.customers.docs.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  <em>{item.displayName}</em>
                </MenuItem>
              )
            })}
          </SelectField>
        )}
        <SelectField
          name="siteIds"
          label="Select Site"
          onChange={handleChage}
          value={siteIds}
          multiple
          renderValue={(selected: any) => {
            // console.log(selected)
            return sitesStore.sitesNamesByIds(selected)
          }}
          fullWidth
        >
          {sitesStore.sites.docs.map(name => (
            <MenuItem key={name.id} value={name.id}>
              <Checkbox
                color="primary"
                checked={siteIds.indexOf(name.id as string) > -1}
              />
              <ListItemText primary={name.data.name} />
            </MenuItem>
          ))}
        </SelectField>
        <SwitchField name="isContactPoint" label="Enable notifications" />
      </Form>
      {formProps.initialValues && <IconButton
        onClick={onDeleteCompanyStaff}
        className={classes.iconButton}
      >
        <DeleteStaff color="secondary" />
      </IconButton>}
      
    </>
  ))
}
