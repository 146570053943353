import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';
import { Int } from 'io-ts';
import { computed } from 'mobx';

import { authStore } from '../auth/authStore';
import { IDevice } from '../model/device';
import { pondStore } from '../pond/pondStore';
import { PondDoc } from './pondDoc';

export class DeviceDoc extends Document<IDevice> {
  public static navigateNew = () => {
    browserHistory.push(`/devices/new`)
  }

  @computed
  get deployedToPond(): PondDoc | null {
    return !this.isLoading && !pondStore.ponds.isLoading && this.id
      ? pondStore.withDispenserDeviceId(this.id)
      : null
  }

  @computed
  get displayName(): string {
    return this.data.serial
  }

  @computed
  get displayPondCount():string {
    const val = '10';
    return val
  }

  public navigateEdit = () => {
    if(this.data.status === 'Off Hire' && !authStore.isAdmin) {
      return ;
    }
    browserHistory.push(`/devices/${this.id}/edit`)
  }
}
