import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';
import { computed } from 'mobx';

import { authStore } from '../auth/authStore';
import { IDeviceRequest } from '../model/deviceRequest';
import { DeviceRequestStatus } from '../model/deviceReqStatus';

export class DeviceRequestDoc extends Document<IDeviceRequest> {
  public static navigateNew = () => {
    browserHistory.push(`/devices/request/new`)
  }

  @computed
  get noOfDevice(): string {
    return this.data.noOfDevice ? this.data.noOfDevice.toString() : ''
  }
  @computed
  get deliveryAddress(): string {
    return this.data.deliveryAddress
  }
  @computed
  get deliveryInstructions(): string {
    return this.data.deliveryInstructions
  }
  @computed
  get Id(): string | undefined {
    return this.id
  }

  public navigateEdit = () => {
    if (authStore.isCustomer) {
      if (this.data.status === DeviceRequestStatus.PENDING) {
        browserHistory.push(`/devices/request/${this.id}/edit`)
      }
    }
    return false;
  }
}
