import { DataCard, DocsView, ISelectableComponentProps } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { SiteDoc } from '../../db/siteDoc';
import { activeAlarmsForPonds, pondStore } from '../../pond/pondStore';
import { DeleteSite } from './DeleteSite';
import { PondCount } from './PondCount';
import { SiteAlarmIcon } from './SiteAlarmIcon';
import { SiteOwner } from './SiteOwner';

const useStyles = makeStyles<Theme>({
  root: {
    '&.decommissioned': {
      '& .AuiDataCard-Typography': {
        fontSize: '0.8rem',
      },
    },
  },
})

interface IProps extends ISelectableComponentProps {
  siteUrl: string
  className?: string
}

export const SiteCard: DocsView<SiteDoc, IProps> = ({
  document: siteDoc,
  siteUrl,
  className,
  children,
  ...rest
}) => {
  const classes = useStyles({})
  const handleViewClick = () => browserHistory.push(siteUrl)
  return useObserver(() => {
    const ponds = pondStore.commissionedPonds(siteDoc)
    const alarms = activeAlarmsForPonds(ponds)
    const decommissioned = !siteDoc.data.isActive//!siteDoc.isLoading && !ponds.hasDocs
    return (
      <DataCard
        onClick={handleViewClick}
        className={clsx(classes.root, { decommissioned }, className)}
        icon={<SiteAlarmIcon alarms={alarms} />}
        primaryText={siteDoc.displayName || <>&nbsp;</>}
        secondaryText={<SiteOwner siteDoc={siteDoc} />}

        secondaryAction={
          !decommissioned ? <PondCount count={ponds.docs.length} /> : !siteDoc.data.isActive && siteDoc.data.isArchive ? <DeleteSite siteId={siteDoc.id} /> : null
        }
        actions={children}
        {...rest}
      />
    )
  })
}
