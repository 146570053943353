import { browserHistory } from '@any-ui/core/navigation'
import { Document } from 'firestorter'

import { ICompanyStaff } from '../model/companyStaffModal'

// import { computed } from 'mobx'
export class CompanyStaffDoc extends Document<ICompanyStaff> {
  public static navigateNew = () => {
    browserHistory.push(`/companystaffs/new`)
  }

  // @computed
  // get displayName(): string {
  //   return this.data.orgName
  // }

  public navigateEdit = () => {
    browserHistory.push(`/companystaffs/${this.id}/edit`)
  }
}