import { Placeholder } from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { SiteViewPage } from './SiteViewPage';
import { SiteViewStore } from './store/siteViewStore';
import { viewerStore } from './store/viewerStore';

export interface IRouteParams {
  siteId: string
}

export const ViewerSiteViewPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const store = useStoreClass(SiteViewStore, false, viewerStore.siteId, true)
  return useObserver(() => (
    <Placeholder isLoading={viewerStore.isLoading}>
      <SiteViewPage store={store} siteUrl={match.url} />
    </Placeholder>
  ))
}
