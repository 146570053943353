import { IFirestoreTimestamp } from './timestamp';

export type Severity = 'NONE' | 'LOW' | 'CRITICAL'

export interface IAlarm {
  severity: Severity
  timestamp: IFirestoreTimestamp
}

// export type AlarmKey =
//   | 'DOSE_FLOW_FAULT'
//   | 'FLOCC_LEVEL'
//   | 'BATTERY_LEVEL'
//   | 'SIPHON_FAULT'
//   | 'VALVE_FAULT'
//   | 'DOSE_FLOW_FAULT'
//   | 'OFFLINE'
//   | 'RAIN_GAUGE_FAULT'

export type AlarmKey =
  | 'FLOCC_LEVEL'
  | 'BATTERY_LEVEL'
  | 'SIPHON_FAULT'
  // | 'DOSE_FLOW_FAULT'
  | 'OFFLINE'
  | 'RAIN_GAUGE_FAULT'
  | 'OVERDOSED_LEVEL'

export type IPondAlarms = Partial<Record<AlarmKey, IAlarm>>

export const AlarmKeyMapSeverityMapDescription: Record<
  AlarmKey,
  Record<Severity, string>
> = {
  BATTERY_LEVEL: {
    CRITICAL: 'Battery critical',
    LOW: 'Battery low',
    NONE: 'Battery level',
  },
  // DOSE_FLOW_FAULT: {
  //   CRITICAL: 'Dose too slow',
  //   LOW: 'Dose slow',
  //   NONE: 'Dose flow',
  // },
  FLOCC_LEVEL: {
    CRITICAL: 'Floc almost empty',
    LOW: 'Low floc remaining', // can also be due to not set
    NONE: 'Floc level',
  },
  OFFLINE: {
    CRITICAL: 'Offline',
    LOW: 'Waiting for device to connect',
    NONE: 'Device online',
  },
  RAIN_GAUGE_FAULT: {
    CRITICAL: 'Rain gauge failure',
    LOW: 'Rain gauge failure', // no LOW produced for RAIN_GAUGE_FAULT
    NONE: 'Rain gauge',
  },
  SIPHON_FAULT: {
    CRITICAL: 'Flow issue – reset device',
    LOW: 'Flow issue – reset device', // no LOW produced for SIPHON_FAULT
    NONE: 'Flow meter',
  },
  // VALVE_FAULT: {
  //   CRITICAL: 'Valve failure',
  //   LOW: 'Valve leak, reset',
  //   NONE: 'Valve',
  // },
  OVERDOSED_LEVEL: {
    CRITICAL: 'Overdosed',
    LOW: 'Overdosed', 
    NONE: 'Overdosed',
  },
}
