import addDays from 'date-fns/add_days';
import dateFormat from 'date-fns/format';
import { Collection } from 'firestorter';
import Document from 'firestorter/lib/Document';
import { action, computed, observable } from 'mobx';
import { fromPromise, FULFILLED, IPromiseBasedObservable, PENDING, REJECTED } from 'mobx-utils';
import { Event, IndexedEvent, TimeRange, TimeRangeEvent, TimeSeries } from 'pondjs';

import { authStore } from '../auth/authStore';
import { PondDoc } from '../db/pondDoc';
import { Timestamp } from '../firestore';
import { batteryVoltsToPercentage } from '../model/battery';
import {
  IMetricRollup,
  maxRollupEndDate,
  metricsToHourlyTimeSeries,
  metricsToWeeklyTimeSeries,
  timeSeriesAggregation,
} from '../model/dashboard';
import { IMetricBatch } from '../model/metricBatch';

const MILLIS_PER_DAY = 1000 * 60 * 60 * 24

export type RollUpWindow = 'daily' | 'hourly' | 'weekly'

export class DashboardStore {
  @observable
  public rollUpWindow: RollUpWindow = 'hourly'

  @computed
  get minDuration() {
    switch (this.rollUpWindow) {
      case 'daily':
        return (7 * MILLIS_PER_DAY);
      case 'hourly':
        return (1 * MILLIS_PER_DAY);
      case 'weekly':
        return (49 * MILLIS_PER_DAY)
    }
    return (this.rollUpWindow === 'daily' ? 7 : 1) * MILLIS_PER_DAY
  }

  @computed
  get initialShowDays() {
    if (this.rollUpWindow === 'daily' || this.rollUpWindow === 'weekly') {
      return this.isMobile ? 30 : 90
    } else {
      return this.isMobile ? 2 : 7
    }
  }

  @computed
  get initialShowRange() {
    const end = this.timeSeries.timerange().end()
    return new TimeRange([addDays(end, -this.initialShowDays), end])
  }

  @computed
  get fullRange() {
    const range = this.timeSeries.timerange()
    if (range.duration() < this.initialShowRange.duration()) {
      return this.initialShowRange
    }
    return range
  }

  @observable
  public requestedRange: TimeRange | null | undefined = undefined

  @observable
  public highlightEvent: IndexedEvent | null = null

  @computed
  get brushRange(): TimeRange | null {
    if (this.requestedRange !== undefined) {
      return this.requestedRange
    }
    if (this.fullRange.duration() > this.initialShowRange.duration()) {
      return this.initialShowRange
    }
    return null
  }

  @computed
  get timeRange(): TimeRange {
    return this.brushRange || this.fullRange
  }

  @computed
  get isLoading(): boolean {
    // So we kick off all loading in parallel
    // short circuit || would load one, then the other
    return [
      this.pondDoc.isLoading,
      this.currentMetrics.state === PENDING,
      this.rolledUpMetrics.state === PENDING,
    ].some(l => l)
  }

  @computed
  get isEmpty(): boolean {
    return this.timeSeries.size() === 0
  }

  @computed
  get error(): any {
    return [this.currentMetrics, this.rolledUpMetrics]
      .map(p => (p.state === REJECTED ? p.value : null))
      .find(e => e)
  }

  @computed
  get showDev(): boolean {
    return authStore.isDev
  }

  @computed
  get timeSeries() {
    const currentSeries = metricsToHourlyTimeSeries(
      this.currentMetrics.state === FULFILLED ? this.currentMetrics.value : [],
    )
    const currentSeriesWeekly = metricsToWeeklyTimeSeries(
      this.currentMetrics.state === FULFILLED ? this.currentMetrics.value : [],
    )
    const rolledUpSeries =
      this.rolledUpMetrics.state === FULFILLED
        ? this.rolledUpMetrics.value.map(
          rollup => new TimeSeries(JSON.parse(rollup.timeSeriesJson)),
        )
        : []
    const hourly = TimeSeries.timeSeriesListMerge({
      seriesList: [...rolledUpSeries, currentSeries],
    })
    const weekly = TimeSeries.timeSeriesListMerge({
      seriesList: [...rolledUpSeries, currentSeriesWeekly],
    })
    
    const asBatteryPercentage = (e: Event | TimeRangeEvent | IndexedEvent) =>
      new IndexedEvent(
        (e as IndexedEvent).index(),
        e.data().update('BatteryVoltage', batteryVoltsToPercentage),
      )
    if (this.rollUpWindow === 'hourly') {
      return hourly.map(asBatteryPercentage)
    } else if (this.rollUpWindow === 'weekly') {
      return weekly.map(asBatteryPercentage);
    }
    const asLocalDateEvent = (e: Event | TimeRangeEvent | IndexedEvent) =>
      new IndexedEvent(dateFormat(e.begin(), 'YYYY-MM-DD'), e.data())
    return hourly
      .map(asLocalDateEvent)
      .fixedWindowRollup({
        aggregation: timeSeriesAggregation,
        windowSize: '1d',
      })
      .map(asBatteryPercentage)
  }

  @computed
  get maxMmScale(): number {
    const requiredMm = Math.max(
      this.timeSeries.max('RainMm'),
      this.pondDoc.maxDryRainAbsorptionMm,
    )
    const requiredGrams = Math.max(
      this.timeSeries.max('DosedGrams'),
      this.timeSeries.max('WantedDoseGrams'),
    )
    const requiredMmAsGrams = requiredMm * this.pondDoc.doseGramsPerMmRain
    if (requiredMmAsGrams >= requiredGrams) {
      return requiredMm
    }
    // Otherwise we need to work backwards from requiredGrams
    return requiredGrams / this.pondDoc.doseGramsPerMmRain
  }

  @computed
  get maxGramsScale(): number {
    const scale = this.maxMmScale * this.pondDoc.doseGramsPerMmRain
    return scale < 3 ? scale * 5 : scale
  }

  @computed
  get minDoseGs(): number {
    // return this.pondDoc.minDoseLitres * LIQUID_PAC_GRAMS_PER_LITRE
    return this.pondDoc.minDoseLitres
  }

  @computed
  get maxDryRainAbsorptionMm(): number {
    return this.pondDoc.maxDryRainAbsorptionMm
  }

  @observable
  private isMobile: boolean = false

  private readonly pondDoc: PondDoc
  private readonly rolledUpMetrics: IPromiseBasedObservable<IMetricRollup[]>
  private readonly currentMetrics: IPromiseBasedObservable<IMetricBatch[]>

  constructor(pondId: string) {
    const loadRolledUpMetrics = async () => {
      const snap = await new Collection<Document<IMetricRollup>>(
        'metricsRollup',
        {
          query: ref => ref.where('pondId', '==', pondId),
        },
      ).fetch()
      return snap.docs.map(d => d.data)
    }

    const loadCurrentMetrics = async () => {
      // Use twice the buffer in case rollup hasn't happened for some reason
      const rolledUpEndDate = maxRollupEndDate(new Date(), 2)
      const snap = await new Collection<Document<IMetricBatch>>('metrics', {
        query: ref =>
          ref
            .where('pondId', '==', pondId)
            .where('timestamp', '>=', Timestamp.fromDate(rolledUpEndDate)),
      }).fetch()
      return snap.docs.map(d => d.data)
    }

    this.pondDoc = new PondDoc(() => `ponds/${pondId}`)
    this.rolledUpMetrics = fromPromise(loadRolledUpMetrics())
    this.currentMetrics = fromPromise(loadCurrentMetrics())
  }

  @action
  public setRollUpWindowDaily = () => {
    this.rollUpWindow = 'daily'
  }

  @action
  public setRollUpWindowHourly = () => {
    this.rollUpWindow = 'hourly'
  }
  @action
  public setRollUpWindowWeekly = () => {
    this.rollUpWindow = 'weekly'
  }

  @action
  public setMobile = (isMobile: boolean) => {
    this.isMobile = isMobile
  }

  @action
  public handleTrackerChanged = (tracker?: Date) => {
    this.highlightEvent = tracker
      ? (this.timeSeries.atTime(tracker) as IndexedEvent)
      : null
  }

  @action
  public handleTimeRangeChange = (timeRange: TimeRange | null) => {
    if (timeRange && timeRange.equals(this.fullRange)) {
      this.requestedRange = null
      return
    }
    this.requestedRange = timeRange
  }
}
