import { locationStore } from '@any-ui/core/navigation'
import { Document } from 'firestorter'
import { computed } from 'mobx'
import { matchPath } from 'react-router'
import { IViewer } from '../../model/viewer'

class ViewerStore {
  @computed
  public get isLoading(): boolean {
    return this.viewerDoc.isLoading
  }

  @computed
  public get isViewer(): boolean {
    return !!this.viewerId
  }

  @computed
  public get siteId(): string | null {
    return this.viewerDoc.hasData ? this.viewerDoc.data.siteId : null
  }

  @computed
  private get viewerId(): string | null {
    const match = matchPath<{
      id: string
    }>(locationStore.path, '/viewers/:id')
    return match && match.params.id
  }

  private viewerDoc = new Document<IViewer>(() =>
    this.viewerId ? `viewers/${this.viewerId}` : undefined,
  )
}

export const viewerStore = new ViewerStore()
