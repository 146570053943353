import { useConfirm } from '@any-ui/core'
import { browserHistory } from '@any-ui/core/navigation'
import { FC, useEffect } from 'react'
import { authStore } from '../auth/authStore'

const signOut = () => {
  authStore.signOut()
  browserHistory.push('/')
}
const stay = () => {
  browserHistory.goBack()
}

export const SignOutRoute: FC = () => {
  const handleSignOut = useConfirm({
    message: 'Are you sure you want to sign out of Autofloc?',
    onCancel: stay,
    onConfirm: signOut,
    title: `Sign out`,
  })

  useEffect(handleSignOut, [])
  return null
}
