import { log } from '@any-ui/core'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { isError } from '@sentry/utils'

export const error = (...args: unknown[]) => {
  const exception = args.find(isError)
  if (exception) {
    Sentry.captureException(exception)
  } else {
    const [firstArg] = args
    Sentry.captureMessage(
      typeof firstArg === 'string' ? firstArg : String(firstArg),
      Severity.Error,
    )
  }
  log.error(...args)
}

export const dev = log.dev
