import { Collection, Mode } from 'firestorter';

import { OffHiredDeviceDoc } from '../db/offHiredDeviceDoc';
import { AsUpdate, FieldValue } from '../firestore';
import { IDevice } from '../model/device';

export class OffHiredDeviceStore {

    public offHiredDevices = new Collection<OffHiredDeviceDoc>('offHiredDevices', {
        createDocument: (...args) => new OffHiredDeviceDoc(...args),
        mode: Mode.Off
    })

    public updateOffHireHistory = async (deviceData: IDevice) => {
        const data: AsUpdate<IDevice> = {
            retrivalTimestamp: FieldValue.serverTimestamp(),
            ...deviceData,
          }
        await this.offHiredDevices.add(data)
    }
    
}
export const offHiredDeviceStore = new OffHiredDeviceStore()