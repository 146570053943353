import { DocsView, ListItem, useConfirm } from '@any-ui/core'
import { ListItemIcon } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import { SupervisorAccount } from '@material-ui/icons'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { IUserDoc } from '../store/adminUsersStore'

export const SelectNewView: DocsView<IUserDoc> = ({ document }) => {
  const handleAdd = useConfirm({
    message:
      'This user will be able to perform administrator functions. Are you sure?',
    onConfirm: document.handleSelect,
    title: `Enable ${document.data.contactName || document.data.email}?`,
  })

  return useObserver(() => {
    const { data: user } = document
    return (
      <ListItem button onClick={handleAdd}>
        <ListItemIcon>
          <SupervisorAccount />
        </ListItemIcon>
        <ListItemText
          primary={user.contactName || user.email}
          secondary={user.contactName && user.email}
        />
      </ListItem>
    )
  })
}
