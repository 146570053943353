const dummy = process.env.REACT_APP_DUMMY
if (!dummy || dummy === String(undefined)) {
  throw new Error(`env not built`)
}

export const stage = process.env.REACT_APP_STAGE

export const version = process.env.REACT_APP_VERSION

export const development =
  process.env.NODE_ENV === 'development' || stage === 'dev'

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!)
