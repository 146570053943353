import { CardActionIconButton } from '@any-ui/core'
import React, { FC, useState } from 'react'
import { ResetPendingIcon/*DoseFlowIcon*/ } from '../../app/Icons'
import { PondDoc } from '../../db/pondDoc'
import { ClearPondDoseDialog } from './ClearPondDoseDialog'

export const ClearPondDoseIconButton: FC<{
  pond: PondDoc
}> = ({ pond }) => {
  const [showDialog, setShowDialog] = useState(false)
  const handleRequest = () => {
    setShowDialog(true)
  }
  return (
    <>
      <ClearPondDoseDialog
        pond={pond}
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
      />
      <CardActionIconButton
        aria-label="Clear dose"
        tooltip="Clear dose"
        onClick={handleRequest}
      >
        <ResetPendingIcon /> 
        {/* <DoseFlowIcon /> */}
      </CardActionIconButton>
    </>
  )
}