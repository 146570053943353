import { PropsRoute } from '@any-ui/core'
import React from 'react'
import { Redirect, Switch } from 'react-router'
import { Page } from './components/Page'
import { SelectNewPage } from './components/SelectNewPage'
import { IAdminUsersProps, useAdminUsersStore } from './store/adminUsersStore'

export const AdminRoutes = () => {
  const store = useAdminUsersStore()
  return (
    <Switch>
      <PropsRoute<IAdminUsersProps>
        path="/admin"
        exact
        component={Page}
        store={store}
      />
      <PropsRoute<IAdminUsersProps>
        path="/admin/new"
        exact
        component={SelectNewPage}
        store={store}
      />
      <Redirect to="/" />
    </Switch>
  )
}
