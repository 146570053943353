import { IconButton, InputBase, makeStyles, Paper, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC } from 'react';

export interface ISearchProps {
  onTextChanged: any,
  value?: string
}
const useStyles = makeStyles<Theme, {}, any>(theme => ({
  searchroot: {
    padding: '2px 4px',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft:5,
    height:40,
  },
  item: {
    marginRight: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))
export const Search: FC<ISearchProps> = ({ onTextChanged,value }) => {
  const classes =  useStyles({})
  return (
      <Paper className={classes.searchroot}>
        <InputBase
          className={classes.input}
          placeholder="Search..."
          onChange={onTextChanged}
        />
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
  )
}
