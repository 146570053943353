import { DocsLayout, DocsList, PageTitle } from '@any-ui/core';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { DeviceDoc } from '../db/deviceDoc';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { DeviceHistoryListItem } from './components/DeviceHistoryListItem';
import { DeviceHistoryListTitle } from './components/DeviceHistoryListTitle';
import { deviceHistoryStore } from './deviceHistoryStore';

// import { deviceRetrievalStore } from './../deviceRetrieval/deviceRetrievalStore';


const useStyles = makeStyles<Theme, {}, any>({
  defaultView: {},
  divider: {
    opacity: 0.25,
  },
  docsCollectionTitle: {},
  root: {},
  typographyRoot: {
    color: 'rgb(255, 255, 255, 0.7)',
    textAlign: 'center',
  },
  custgrid: {
    textAlign: 'right',
  },
})


export const DeviceHistoryPage: FC = () => {
  const classes = useStyles({})
  const [selectedDevice, setDeviceFilter] = React.useState('-1')
  
  const [searchText, setSearchText] = React.useState('')
  const onFilterDevice = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setDeviceFilter(value);
    deviceHistoryStore.getDeviceHistory(value);
  
  }

  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }

  const filterDevice = (doc: DeviceDoc) => {
    if (searchText) {
      const searchContent = searchText.toLowerCase();
      return (
        doc.data.status.toLowerCase().indexOf(searchContent) !==
        -1 ||
        doc.data.serial.toLowerCase().indexOf(searchContent) !== -1
      )
    }
    return true
  }
  return useObserver(() => (
    <DocsLayout collection={deviceHistoryStore.deviceHistory} pageWidth="800px">
      <PageTitle>Devices History</PageTitle>
      <Grid container spacing={3}>
        <Grid item sm={7}>
          <Search onTextChanged={onSearch}></Search>
        </Grid>
        <Grid item sm={3} className={classes.custgrid}>
          <DropDown
            value={selectedDevice}
            handleChange={onFilterDevice}
            placeholder={'All Device'}
            items={deviceHistoryStore.getDeivceName}
          ></DropDown>
        </Grid>
        <Grid item sm={12}>
          <h4>Total devices : {deviceHistoryStore.deviceHistory.docs.length}</h4>
          <DocsList<DeviceDoc>
            collection={deviceHistoryStore.deviceHistory}
            DefaultView={DeviceHistoryListItem}
            preItems={<DeviceHistoryListTitle />}
            filter={filterDevice}
            
            classes={classes}
          />
        </Grid>
      </Grid>
    </DocsLayout>
  ))
}
