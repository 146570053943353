import { onUnexpectedFormError } from '@any-ui/form'
import * as Sentry from '@sentry/browser'
import { onReactionError } from 'mobx'
import * as env from './env'

// export const unexpectedFormSubmitError = (err: any) => {
//   Sentry.captureException(err)
//   return globalFormError(err)
// }

export const captureErrors = () => {
  Sentry.init({
    dsn: '',
    environment: env.stage,
    release: env.version,
  })
  onUnexpectedFormError(e => {
    Sentry.captureException(e)
  })
  onReactionError(e => {
    Sentry.captureException(e)
  })
}
