import { IDoc } from '@any-ui/core'
import { browserHistory } from '@any-ui/core/navigation'
import { useStoreClass } from '@any-ui/core/store'
import { IUser } from '../../model/user'
import { AdminUsersCollection } from './adminUsersCollection'
import { NonAdminUsersCollection } from './nonAdminUsersCollection'

export interface IUserDoc extends IDoc {
  data: IUser
  handleSelect: () => void
  removing?: boolean
}

export interface IAdminUsersProps {
  store: AdminUsersStore
}

export const useAdminUsersStore = () => useStoreClass(AdminUsersStore)

export class AdminUsersStore {
  public readonly adminUsers: AdminUsersCollection
  public readonly nonAdminUsers: NonAdminUsersCollection

  constructor() {
    this.adminUsers = new AdminUsersCollection(this)
    this.nonAdminUsers = new NonAdminUsersCollection(this)
  }

  public navigateNew = () => {
    browserHistory.push(`/admin/new`)
  }

  public cancel = () => {
    browserHistory.goBack()
  }

  public reload() {
    this.adminUsers.reload()
    this.nonAdminUsers.reload()
  }
}
