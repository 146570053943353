import * as t from 'io-ts'

export interface ITermsConditions {
  isAccepted: boolean
  eSignature: string | null
  userUid:string | null
  addedTimestamp: any
}

export const Notification = t.type({
  toEmail: t.union([t.string, t.null]),
  subject: t.union([t.string, t.null]),
  template: t.union([t.string, t.null]),
})