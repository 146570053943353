import * as t from 'io-ts'

import { IUserProfile } from './user'

export interface IHasOwner {
  ownerUid: string
}

export interface IDocHasOwner {
  data: IHasOwner
}

export interface ICompanyStaffUserProfile extends IUserProfile {
  phoneNumber: string | null
}

export interface ICompanyStaff extends ICompanyStaffUserProfile {
  enabled: boolean
  siteIds: any
  addedTimestamp?: any
  firstName?: string | null
  lastName?: string | null
  ownerUid: string | null
  newPassword: string | null
  isContactPoint: boolean
}

const CompanyStaffUpdateDataRequired = t.type({
  email: t.string,
  enabled: t.boolean,
  newPassword: t.union([t.string, t.null]),
  ownerUid: t.string,
  phoneNumber: t.union([t.string, t.null]),
})

const CompanyStaffUpdateDataOptional = t.partial({
  contactName: t.union([t.string, t.null]),
  siteIds: t.Array,
  addedTimestamp: t.string,
  firstName: t.string,
  lastName: t.string,
})

const CompanyStaffUpdateData = t.intersection([
  CompanyStaffUpdateDataRequired,
  CompanyStaffUpdateDataOptional,
])

export const ApplyCompanyStaffToUserData = t.type({
  companyStaff: CompanyStaffUpdateData,
  uid: t.union([t.string, t.null]),
})

export const Notification = t.type({
  toEmail: t.union([t.string, t.null]),
  subject: t.union([t.string, t.null]),
  template: t.union([t.string, t.null]),
})

export type ICompanyStaffUpdate = t.TypeOf<typeof CompanyStaffUpdateData>

export interface IApplyCompanyStaffToUserResult {
  uid: string
}

export const toE164 = (phone: string | null): string | null => {
  const numbersOnly = (str: string): string => {
    let result = ''
    for (const c of str) {
      if (/\d/.test(c)) {
        result += c
      }
    }
    return result
  }

  if (!phone) {
    return null
  }
  if (phone.startsWith('+')) {
    return `+${numbersOnly(phone)}`
  } else {
    const num = numbersOnly(phone)
    if (num.startsWith('02')) {
      return `+642${num.substring(2)}`
    }
    return null
  }
}
