import { ListItemIcon, PropTypes } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { ListItemIconProps } from '@material-ui/core/ListItemIcon'
import { useTheme } from '@material-ui/core/styles'
import { NotificationsTwoTone } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { FC } from 'react'
import { Severity } from '../model/alarm'
import { alarmBackground } from './AlarmStyles'

const useStyles = makeStyles(
  createStyles({
    avatar: alarmBackground({
      fontWeight: 800,
      height: 32,
      width: 32,
    }),
    root: {
      '& .MuiSvgIcon-root': {
        minWidth: 32,
      },
    },
  }),
)

type TVariant = 'icon' | 'data'

export interface IListItemIconAlarmProps {
  data: string | number
  active: boolean
  severity: Severity
  variant?: TVariant
  color?: PropTypes.Color
}

export const ListItemIconAlarm: FC<
  Partial<ListItemIconProps> & IListItemIconAlarmProps
> = ({
  color,
  data,
  active,
  variant = 'icon',
  className,
  children,
  severity,
  ...rest
}) => {
  const theme = useTheme()
  const classes = useStyles({ theme, severity })
  return (
    <ListItemIcon {...rest} className={clsx(classes.root, className)}>
      {!!data ? (
        <Avatar classes={{ colorDefault: classes.avatar }}>
          {variant === 'data' ? data : <NotificationsTwoTone />}
        </Avatar>
      ) : (
        <>{children}</>
      )}
    </ListItemIcon>
  )
}
// @ts-ignore
ListItemIconAlarm.muiName = ListItemIcon.muiName
