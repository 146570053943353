// Many of these are also repeated in dispenser.ino
export const DEFAULT_DOSE_MG_PER_LITRE = 8
export const DEFAULT_DOSE_GRAMS_PER_LITRE = DEFAULT_DOSE_MG_PER_LITRE / 1000
export const DEFAULT_MAX_DRY_AFTER_DAYS = 7
export const DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM = 10
export const DEFAULT_MIN_DOSE_LITRES = 0.33
export const DEFAULT_FLOWING_RAIN_MM_PER_HOUR = 1
export const DEFAULT_MIN_DOSE_MILLILITRES = 330
export const DEFAULT_DOSE_TIME_SECONDS = 60
//export const DEFAULT_RAIN_TYPE = 1
//export const DEFAULT_CONNECTION_UPTIME_MINS = 15
//export const DEFAULT_CONNECTION_UPTIME_SECS = 20
// Values from https://drive.google.com/open?id=1UX2mNXl6MOYJPRKKslbf4RA15YgHv1Th
export const LITRES_PER_HECTARE_MM = 10000
// export const LIQUID_PAC_MG_PER_LITRE = 64164
// export const LIQUID_PAC_GRAMS_PER_LITRE = LIQUID_PAC_MG_PER_LITRE / 1000
export const M2_PER_HECTARE = 10000

// export const LITRES_PER_HECTARE_MM = 1
 export const LIQUID_PAC_MG_PER_LITRE = 1
 export const LIQUID_PAC_GRAMS_PER_LITRE = 1
// export const M2_PER_HECTARE = 1



// Optional (?) means it's not edited in the UI (yet)
export interface IDispenserConfig {
  litresPerFlowPulse?: number | null
  maxDryRainAbsorptionMm: number | null
  maxDryAfterDays: number | null
  rainMmPerBucketPulse?: number | null
  catchmentAreaHectares: number
  flowingRainMmPerHour: number
  pumpPrimeSeconds?: number | null
  minDoseLitres?: number | null
  doseRate: number
  disableDose?: boolean
  doseType: number 
  manualDoseL?: number | null
  interval?: number | null
  startTime?: any | null
  endTime?: any | null
  endDate?: number | null
  startDate?: number | null 
  isDayWise?:boolean
  minimumDoseml?: number | null
  dosingTimeS?: number | null
  //disableRainDose?: number | null
  //rainGauge?: number | null
  //rainType?: number | null
  //connectionIntervalMins?: number | null
  //connectionUpTimeSecs?: number | null

}
