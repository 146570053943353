import { Collection, Mode } from 'firestorter';

import { authStore } from '../auth/authStore';
import { DeviceDoc } from '../db/deviceDoc';
import { FieldValue } from '../firestore';


class DeviceHistoryStore {
  private tempDeviceHisotry: any;
  public deviceHistory = new Collection<DeviceDoc>('deviceHistory', {
    createDocument: (...args) => new DeviceDoc(...args),
    query: ref =>
      authStore.isAdmin
        ? ref.orderBy('deviceId', 'asc').orderBy('addedTimestamp', 'asc')
        : authStore.isCustomer
          ? ref.where('ownerUid', '==', authStore.uid).orderBy('deviceId', 'asc').orderBy('addedTimestamp', 'asc')
          : null,
  })

  public save = async (deviceId: string | any) => {
    const device: any = await new DeviceDoc(`devices/${deviceId}`, {
      mode: Mode.Off,
    }).fetch()
    device.data.deviceId = deviceId
    device.data.addedTimestamp = FieldValue.serverTimestamp()
    await this.deviceHistory.add(device.data)
  }

  public getDeviceHistory = async (deviceId: string = '') => {
    if (deviceId !== '-1') {
      this.deviceHistory = new Collection<DeviceDoc>('deviceHistory', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('deviceId', '==', deviceId).orderBy('deviceId', 'asc').orderBy('addedTimestamp', 'asc'),
      })
    } else
      this.deviceHistory = new Collection<DeviceDoc>('deviceHistory', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref => ref.orderBy('deviceId', 'asc').orderBy('addedTimestamp', 'asc'),
      })
  }

  get getDeivceName() {
    if (!this.tempDeviceHisotry) {
      this.tempDeviceHisotry = this.deviceHistory.docs;
    }
    const result = Array.from(new Set(this.tempDeviceHisotry.map((p: any) => p.data.deviceId))).map(id => {
      const deviceDoc = this.tempDeviceHisotry.filter((p: any) => p.data.deviceId === id);
      return {
        displayName: deviceDoc[0].data.serial,
        id
      }
    });
    return result;
  }
  public setTempDeviceHistory(history: any) {
    this.tempDeviceHisotry = history
  }

}

export const deviceHistoryStore = new DeviceHistoryStore()
