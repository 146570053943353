import * as t from 'io-ts'
import { Timestamp } from '../firestore'

const RoleData = t.keyof({
  admin: null,
  customer: null,
  dev: null,
  companyStaff: null
})

export type Role = t.TypeOf<typeof RoleData>

export interface ICustomClaims {
  roles?: Role[]
}

export interface IUserProfile {
  contactName?: any
  email: string
}

export interface IUserProfileUpdate extends IUserProfile {
  newPassword: string | null
}

export interface IUser extends IUserProfile {
  uid: string
  enabled: boolean
  roles: Role[]
}

export const UpdateUserRoleData = t.type({
  role: RoleData,
  uid: t.string,
})

export const ListCountData = t.partial({
  count: t.number,
})

export const GetUserByEmailData = t.intersection([
  t.type({
    email: t.string,
  }),
  ListCountData,
])

export const ListUsersWithoutRoleData = t.intersection([
  t.type({
    role: RoleData,
  }),
  ListCountData,
])

type CustomClaims = {
  [key: string]: any
}

let hasRoles = false;

export const rolesFromClaims = (customClaims?: CustomClaims): Role[] => {
  hasRoles = true;
  if (!customClaims) {
    return []
  }
  const claims = customClaims as ICustomClaims
  return claims.roles || []
}

export {hasRoles}

export interface IUserMeta {
  verificationEmailSentTimestamp?: Timestamp | null
}
