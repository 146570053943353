import {
  AppBarLogo,
  AppContent,
  AppLayout,
  AppTitle,
  DrawerContent,
  DrawerLogo,
  DrawerTitle,
  DrawerToggle,
  PrimaryNav,
} from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

import { authStore } from '../auth/authStore';
import { CustomerListStore } from '../customer/components/customerListStore';
import { CustomerEditStore } from '../customer/customerEditStore';
import {
  AdminUsersAction,
  AlarmsAction,
  CustomersAction,
  DeviceHistoryAction,
  DeviceRequestAction,
  DeviceRetrieveAction,
  DevicesAction,
  InvoiceAction,
  ProfileAction,
  SignOutAction,
  SitesAction,
} from './Actions';
import { AutoflocTitle } from './AutoflocTitle';
import { Logo } from './Logo';
import { TopRoutes } from './TopRoutes';

const useStyles = makeStyles<Theme, any>({
  pb: {
    paddingBottom: '70px',
  },
})
export const AdminRoot: FC = () => {
  const classes =  useStyles()
  const store = useStoreClass(CustomerEditStore, '0')
  const newCustomers = useStoreClass(CustomerListStore, store);
  return (
    <AppLayout>
    <DrawerToggle />
    <AppBarLogo>
      <Logo marginRight={2.5} />
    </AppBarLogo>
    <AppTitle>
      <AutoflocTitle />
    </AppTitle>

    <PrimaryNav>
      {SitesAction}
      {AlarmsAction}
      {DevicesAction}
      {CustomersAction(newCustomers)} 
    </PrimaryNav>

    <DrawerLogo>
      <Logo minWidth={56} />
    </DrawerLogo>
    <DrawerTitle>
      <AutoflocTitle />
    </DrawerTitle>
    <DrawerContent>
      {authStore.isAdmin && AdminUsersAction}
      <Divider />
      {ProfileAction}
      {DeviceRequestAction}
      {DeviceRetrieveAction}
      {DeviceHistoryAction}
      {InvoiceAction}
      {SignOutAction}
    </DrawerContent>
    <AppContent>
      <div className={classes.pb}>
      <TopRoutes />
      </div>
    </AppContent>
  </AppLayout>
  )
}
