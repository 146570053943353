import { fieldError } from '@any-ui/form';
import { Mode } from 'firestorter';
import pick from 'lodash/pick';
import { computed, observable } from 'mobx';

import { authStore } from '../auth/authStore';
import { CustomerDoc } from '../db/customerDoc';
import { template } from '../emailTemplates/customerRegisterTemplate';
import { apiCall, firebase } from '../firebase';
import * as log from '../log';
import {
  ApplyCustomerToUserData,
  IApplyCustomerToUserResult,
  ICustomer,
  ICustomerUpdate,
  Notification,
} from '../model/customer';
import { GetUserByEmailData, IUser } from '../model/user';
import { generateRandomString, getHtmlTemplate } from '../util/commonFunctions';
import { IFormFields as FindFormFields } from './FindUserForm';

// import { unexpectedFormSubmitError } from '../error'
const getUserByEmail = apiCall<IUser>('getUserByEmail', GetUserByEmailData)
const applyCustomerToUser = apiCall<IApplyCustomerToUserResult>(
  'applyCustomerToUser',
  ApplyCustomerToUserData
)

const sendEmail = apiCall<boolean>(
  'notifications',
  Notification
)
export class CustomerEditStore {
  public readonly isNew: boolean

  @computed
  get initialValues(): Partial<ICustomerUpdate> | null {
    if (this.initialDoc) {
      return this.initialDoc.data
    }
    if (this.foundUser) {
      return this.foundUser
    }
    if (this.searchedEmail) {
      return {
        email: this.searchedEmail,
        enabled: true,
      }
    }
    return null
  }

  @computed
  get existsInAuth(): boolean {
    return (this.initialDoc && this.initialDoc.hasData) || !!this.foundUser
  }

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @observable
  private searchedEmail: string | null = null

  @observable
  private foundUser: IUser | null = null
  private userResult: IUser | null = null

  private readonly initialDoc: CustomerDoc | null = null

  constructor(uid?: string) {
    this.isNew = !uid
    if (uid) {
      this.initialDoc = new CustomerDoc(`customers/${uid}`, { mode: Mode.Off })
      this.initialDoc.fetch().catch(log.error)
    }
  }

  public find = async ({ email }: FindFormFields) => {
    try {
      const result = await getUserByEmail({ email })
      this.foundUser = result.data
    } catch (e) {
      if ((e as firebase.functions.HttpsError).code !== 'not-found') {
        throw fieldError<FindFormFields>(
          'email',
          'Search failed, you can retry'
        )
      }
    } finally {
      this.searchedEmail = email
    }
  }
/**Checking if users exist in firebase account */
  public findUserData = async ({ email }: FindFormFields) => {
    try {
      let result = await getUserByEmail({ email })
      this.userResult = result.data
    } catch (e) {
      // console.log('e', e);
    }
  }

  public save = async (customer: ICustomerUpdate) => {
    await this.findUserData({ email: customer.email })
    const initialCustomerUuid = (this.initialDoc && this.initialDoc.id) || null
    if (!initialCustomerUuid && !this.userResult) {
      customer.newPassword = generateRandomString()
    }
    let uid: string
    if (authStore.uid && initialCustomerUuid === authStore.uid) {
      await authStore.updateUser(customer)
      uid = authStore.uid
    } else {
      const updateResult = await applyCustomerToUser({
        customer,
        uid: initialCustomerUuid,
      })
      uid = updateResult.data.uid
    }
    /** Send email to customer on registration */
    if (!initialCustomerUuid && !this.userResult) {
      const templateData = {
        fullName: customer.contactName,
        email: customer.email,
        password: customer.newPassword
      }
      const finalTemplate = getHtmlTemplate(template, templateData);
      await sendEmail({
        toEmail: customer.email,
        subject: 'Welcome to Autoflocc',
        template: finalTemplate
      })
    }
    const fields: ICustomer = pick(customer, [
      'contactName',
      'orgName',
      'email',
      'phoneNumber',
      'enabled',
    ])
    await new CustomerDoc(`customers/${uid}`).set(fields, { merge: true })

  }

  public select = (user: IUser) => {
    this.foundUser = user
    this.searchedEmail = user.email
  }
}
