import * as t from 'io-ts'
import { IUserProfile } from './user'

export interface IHasOwner {
  ownerUid: string
}

export interface IDocHasOwner {
  data: IHasOwner
}

export interface ICustomerUserProfile extends IUserProfile {
  phoneNumber: string | null
}

export interface ICustomer extends ICustomerUserProfile {
  orgName: string
  enabled: boolean
}

const CustomerUpdateData = t.type({
  contactName: t.union([t.string, t.null]),
  email: t.string,
  enabled: t.boolean,
  newPassword: t.union([t.string, t.null]),
  orgName: t.string,
  phoneNumber: t.union([t.string, t.null]),
})

export const ApplyCustomerToUserData = t.type({
  customer: CustomerUpdateData,
  uid: t.union([t.string, t.null]),
})

export const Notification = t.type({
  toEmail: t.union([t.string, t.null]),
  subject: t.union([t.string, t.null]),
  template: t.union([t.string, t.null]),
})

export type ICustomerUpdate = t.TypeOf<typeof CustomerUpdateData>

export interface IApplyCustomerToUserResult {
  uid: string
}

export const toE164 = (phone: string | null): string | null => {
  const numbersOnly = (str: string): string => {
    let result = ''
    for (const c of str) {
      if (/\d/.test(c)) {
        result += c
      }
    }
    return result
  }

  if (!phone) {
    return null
  }
  if (phone.startsWith('+')) {
    return `+${numbersOnly(phone)}`
  } else {
    const num = numbersOnly(phone)
    if (num.startsWith('02')) {
      return `+642${num.substring(2)}`
    }
    return null
  }
}
