import { browserHistory } from '@any-ui/core/navigation';
import { FormComponent, requiredString, validators } from '@any-ui/form';
import {
  Button,
  createStyles,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import QrReader from 'react-qr-reader';

import { DeleteStaff, ScanQrIcon } from '../app/Icons';
import { authStore } from '../auth/authStore';
import { customerMenuItems } from '../customer/customerMenu';
import { PondDoc } from '../db/pondDoc';
import { IDevice } from '../model/device';
import { sitesStore } from '../site/store/sitesStore';
import { DeviceStatus } from '../tools/DeviceStatus';
import { deviceStore } from './deviceStore';


interface IProps {
  deployedToPond: PondDoc | null
  onDelete: any
}

export type FormFields = Pick<
  IDevice, | 'particleId' | 'serial' | 'ownerUid' | 'siteId' | 'deviceRate' | 'status'>

const particleIdValidate = async (
  particleId: string,
  modified: any,
) => {
  const idLength = validators.length({ is: 24 })(particleId)
  if (idLength) {
    return idLength
  }
  if (modified) {
    const idInUse = await deviceStore.particleIdInUse(particleId)
    if (idInUse) {
      // return Promise.reject({ particleId: 'That Particle device ID is taken' })
      return 'That Practicle device ID is taken'
    }
  }
  return
}
const serialValidate = (value: string) => {
  return validators.required()(value)
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const EditDeviceForm: FormComponent<FormFields, IProps> = ({
  deployedToPond,
  onResult,
  onDelete,
  ...formProps
}) => {
  const [values, setValues]: any = useState({
    serial: formProps.initialValues.serial ? formProps.initialValues.serial : null,
    particleId: formProps.initialValues.particleId ? formProps.initialValues.particleId : '',
    ownerUid: formProps.initialValues.ownerUid ? formProps.initialValues.ownerUid : '',
    deviceRate: formProps.initialValues.deviceRate ? formProps.initialValues.deviceRate : '',
    siteId: formProps.initialValues.siteId ? formProps.initialValues.siteId : null,
  })
  const [isDisabled, setDisable] = useState(true);
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  const [particleError, setparticleError] = useState('')
  const [serialError, setSerialError] = useState('')
  const [isQrCode, setIsQrCode] = useState(false);
  const isParticleFirstRun = useRef(true);


  useEffect(() => {
    setDisable(true);
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, []);

  useEffect(() => {
    if (isParticleFirstRun.current) {
      isParticleFirstRun.current = false;
      return;
    }
    const fetchData = async () => {
      const result = await particleIdValidate(values.particleId, true);
      setparticleError(result);
    };
    fetchData();

  }, [values.particleId])
  useEffect(() => {
    if (values.serial !== null) {
      setSerialError(serialValidate(values.serial))
    }
  }, [values.serial])
  useEffect(() => {
    const isValid = checkValidation();
    setDisable(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, particleError])
  useEffect(() => {
    sitesStore.setSelectedCustomerId(values.ownerUid);
  }, [values.ownerUid])
  const handleSubmit = () => {
    onResult({
      ownerUid: requiredString(values.ownerUid),
      particleId: requiredString(values.particleId),
      serial: requiredString(values.serial),
      siteId: values.siteId,
      deviceRate: requiredString(values.deviceRate),
      status: 'Off Hire',
    })
  }
  const handleChange = (prop: keyof FormFields, value: any = '') => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValues({ ...values, [prop]: value !== '' ? value : event.target.value })
  }
  const handleSelectChange = (name: string, value: any) => {
    setValues({ ...values, [name]: value.props.value });
  }
  const checkValidation = () => {
    // console.log(values);
    return values && values.ownerUid && values.particleId && values.serial && values.deviceRate && !particleError
  }
  const handleScanQrCode = () => {
    setIsQrCode(true);
  }
  const handleScan = (data: any) => {
    if (data) {
      setValues({ ...values, serial: data });
      setIsQrCode(false);
    }
  }
  const handleError = (err: any) => {
    if (err) {

    }
  }
  const classes = useStyles({});
  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...formProps}>
    <>
      {isQrCode ? <div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </div> :
        <>
          <TextField disabled={!authStore.isAdmin} fullWidth label="Serial" value={values.serial} variant="outlined" margin="dense" error={serialError ? true : false} helperText={<span>{serialError}</span>} onChange={handleChange('serial')} />
          <TextField disabled={!authStore.isAdmin} fullWidth label="Particle device ID" value={values.particleId} variant="outlined" margin="dense" error={particleError ? true : false} helperText={<span>{particleError}</span>} onChange={handleChange('particleId')} />
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel ref={inputLabel} id="outlined-customer-label">
              Customer
        </InputLabel>
            <Select fullWidth variant="outlined"
              disabled={!!deployedToPond}
              input={
                <OutlinedInput
                  name="customer"
                  labelWidth={labelWidth}
                  id="outlined-customer"
                />
              }
              value={values.ownerUid}
              onChange={(name, value: ReactNode) =>
                handleSelectChange('ownerUid', value)
              }
            >
              {customerMenuItems({
                initialUid: formProps.initialValues!.ownerUid,
              })}
            </Select>
            <FormHelperText><span></span></FormHelperText>
          </FormControl>
          <TextField fullWidth label="Device rate/day" value={values.deviceRate}
            variant="outlined" margin="dense"
            helperText={<span></span>}
            onChange={handleChange('deviceRate')} disabled={!authStore.isAdmin} />
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel ref={inputLabel}>Select Site</InputLabel>
            <Select fullWidth variant="outlined"
              input={
                <OutlinedInput
                  name="sites"
                  labelWidth={labelWidth}
                  id="outlined-sites"
                />
              }
              value={values.siteId}
              onChange={(name, value: ReactNode) =>
                handleSelectChange('siteId', value)
              }
            >
              {sitesStore.customerSites.map(item => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    <em>{item.displayName}</em>
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText><span></span></FormHelperText>
          </FormControl>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button variant="contained" color="default"
                size="large"
                onClick={() => { browserHistory.goBack() }}>Cancel</Button>&nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="primary"
                size="large" disabled={isDisabled}
                onClick={handleSubmit}>Update</Button>
            </Grid>
          </Grid>
          <Grid container>
            <Fab variant="extended" color="primary" onClick={handleScanQrCode} disabled={!authStore.isAdmin}>
              <ScanQrIcon className={classes.extendedIcon} />
          Scan QR Code
        </Fab>
            {(formProps.initialValues && formProps.initialValues.status === DeviceStatus.OFFHIRE) &&
              <IconButton onClick={onDelete} >
                <DeleteStaff color="secondary" />
              </IconButton>
            }
          </Grid>
        </>}
    </>
  ))
}
