import { DocsEmpty, DocsLayout, DocsList, PageTitle } from '@any-ui/core'
import { useObserver } from 'mobx-react-lite'

import React, { FC } from 'react'
import { AlarmDoc } from './alarmDoc'
import { AlarmListItem } from './AlarmListItem'
import { alarmStore } from './alarmsStore'

const alarmActiveFilter = (alarm: AlarmDoc) => alarm.active
const alarmResolvedFilter = (alarm: AlarmDoc) => alarm.recentlyResolved

export const AlarmsListPage: FC = () =>
  useObserver(() => (
    <DocsLayout collection={alarmStore}>
      <PageTitle>Alarms</PageTitle>
      <DocsList<AlarmDoc>
        DefaultView={AlarmListItem}
        collection={alarmStore}
        filter={alarmActiveFilter}
      />
      <DocsList<AlarmDoc>
        DefaultView={AlarmListItem}
        collection={alarmStore}
        fixedOpen
        docsTitle="Recently resolved"
        showEmpty={false}
        collapseHistoryStateKey="fixedAlarmsCollapseHistoryStateKey"
        filter={alarmResolvedFilter}
      />
      <DocsEmpty>No alarms! Happy days</DocsEmpty>
    </DocsLayout>
  ))
