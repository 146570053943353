import { Collection, Mode } from 'firestorter';

import { DeletedDeviceDoc } from '../db/deletedDeviceDoc';
import { DeviceDoc } from '../db/deviceDoc';
import * as log from '../log';

export class DeviceDeleteStore {

    private readonly initialDoc: DeviceDoc | any = null

    constructor(deviceId?: string) {
        if (deviceId) {
            this.initialDoc = new DeviceDoc(`devices/${deviceId}`, { mode: Mode.Off })
            this.initialDoc.fetch().catch(log.error)
        }
    }
    public deletedDevices = new Collection<DeletedDeviceDoc>('deletedDevices', {
        createDocument: (...args) => new DeletedDeviceDoc(...args),
        mode: Mode.Off
    })

    public delete = async () => {
        // const deleteDevice = await new PondDoc(`devices/${this.initialDoc.id}`, { mode: Mode.Off }).fetch()
        if (this.initialDoc.data.status === 'Off Hire') {
            await this.deletedDevices.add(this.initialDoc.data)
            await this.initialDoc.delete()
            
        }

    }

}
