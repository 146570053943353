import { DocsView } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { customerStore } from '../../customer/customerStore';
import { DeviceRequestDoc } from '../../db/deviceRequestDoc';
import { DeviceAssgined } from './DeviceAssgined';
import { DeviceRequestListItemBase } from './DeviceRequestListItemBase';


export const DeviceRequestListItem: DocsView<DeviceRequestDoc> = ({ document, ...rest }) =>
  useObserver(() => (
    <DeviceRequestListItemBase
      noOfDevice={document.noOfDevice}
      customerName={
        (document.data.ownerUid &&
          customerStore.customerName(document.data.ownerUid)) ||
        undefined
      }
      deliveryInstructions={document.deliveryInstructions}
      deliveryAddress={document.deliveryAddress}
      onClick={document.navigateEdit}
      action={<DeviceAssgined document={document}  />}
      {...rest}
    />
  ))
