import { Collection, Mode } from 'firestorter';
import { computed } from 'mobx';

import { authStore } from '../auth/authStore';
import { TermsConditionsDoc } from '../db/termsConditionsDoc';
import { template } from '../emailTemplates/termsAndConditionsApproval';
import { apiCall } from '../firebase';
import { FieldValue } from '../firestore';
import { ITermsConditions, Notification } from '../model/termsConditions';
import { IUser, ListUsersWithoutRoleData } from '../model/user';
import { getHtmlTemplate } from '../util/commonFunctions';

const listUsersByRole = apiCall<IUser[]>(
  'listUsersByRole',
  ListUsersWithoutRoleData,
)
const sendEmail = apiCall<boolean>(
  'notifications',
  Notification
)
class TermsConditionsStore {

  public termsAndConditions = new Collection<TermsConditionsDoc>('termsAndConditions', {
    createDocument: (...args) => new TermsConditionsDoc(...args),
    mode: Mode.On,
    query: ref => ref.where('userUid', '==', authStore.uid),
  })

  public async createUser(values: Partial<ITermsConditions>) {
    // tslint:disable-next-line:no-console
    authStore.isLoading = true;
    const users: any = await listUsersByRole({ role: 'admin' })
    values.addedTimestamp = FieldValue.serverTimestamp()
    values.userUid = authStore.uid;
    const userEmails = users.data.map((user: any) => user.email)

    await this.termsAndConditions.add(values)

    /** Send email to customer on registration */
    if (userEmails.length) {
      const templateData = {
        eSignature: values.eSignature
      }
      const finalTemplate = getHtmlTemplate(template, templateData);
      await sendEmail({
        toEmail: userEmails,
        subject: 'A New User Submitted Approval',
        template: finalTemplate
      })
    }
    authStore.isLoading = false;
    return true
  }

  public getById = async (userUid: string | undefined) => {
    const termsAndConditions = await new Collection('termsAndConditions', {
      mode: Mode.Off,
      query: ref => ref.where('userUid', '==', userUid),
    }).fetch()
    return termsAndConditions.docs
  }
  @computed
  get isTermCondition() {
    return !this.termsAndConditions.hasDocs
  }
}

export const termsConditionsStore = new TermsConditionsStore()
