import { browserHistory } from '@any-ui/core/navigation'
import { Document } from 'firestorter'
import { computed } from 'mobx'
import { ISite } from '../model/site'

export class SiteDoc extends Document<ISite> {
  public static navigateNew = () => {
    browserHistory.push(`/sites/new`)
  }

  @computed
  get displayName(): string {
    return this.data.name
  }

  @computed
  get address(): string | null {
    return this.data.address || null
  }

  @computed
  get adminNotes(): string | null {
    return this.data.adminNotes || null
  }

  @computed
  get customerNotes(): string | null {
    return this.data.customerNotes || null
  }

  public navigateView = () => {
    browserHistory.push(`/sites/${this.id}`)
  }

  public navigateEdit = () => {
    browserHistory.push(`/sites/${this.id}/edit`)
  }

  public navigateNewPond = () => {
    browserHistory.push(`/sites/${this.id}/pond/new/edit`)
  }

  public createViewer = () => {
    //
  }
}
