import {
  AppBarLogo,
  AppContent,
  AppLayout,
  AppTitle,
  DrawerContent,
  DrawerLogo,
  DrawerTitle,
  DrawerToggle,
  PrimaryNav,
} from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import {
  AlarmsAction,
  CompanyStaffAction,
  DeviceRequestAction,
  DevicesAction,
  ProfileAction,
  SignOutAction,
  SitesAction,
} from './Actions';
import { AutoflocTitle } from './AutoflocTitle';
import { Logo } from './Logo';
import { TopRoutes } from './TopRoutes';

export const CustomerRoot: FC = () =>
  useObserver(() => (
    <AppLayout>
      <DrawerToggle />
      <AppBarLogo>
        <Logo marginRight={2.5} />
      </AppBarLogo>
      <AppTitle>
        <AutoflocTitle />
      </AppTitle>
      <PrimaryNav>
        {SitesAction}
        {AlarmsAction}
        {DevicesAction}
      </PrimaryNav>

      <DrawerLogo>
        <Logo minWidth={56} />
      </DrawerLogo>
      <DrawerTitle>
        <AutoflocTitle />
      </DrawerTitle>
      <DrawerContent>
        {DevicesAction}
        {DeviceRequestAction}
        {/* {NewDeviceRequestAction} */}
        {ProfileAction}
        {CompanyStaffAction}
        {SignOutAction}
      </DrawerContent>
      <AppContent>
        <TopRoutes />
      </AppContent>
    </AppLayout>
  ))
