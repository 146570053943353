import { DocsList } from '@any-ui/core'
import { DocsEmpty } from '@any-ui/core'
import { DocsLayout } from '@any-ui/core'
import { PageAction } from '@any-ui/core'
import { PageTitle } from '@any-ui/core'
import PersonAdd from '@material-ui/icons/PersonAddOutlined'
import negate from 'lodash/negate'
import { useObserver } from 'mobx-react-lite'

import React, { FC } from 'react'
import { IAdminUsersProps, IUserDoc } from '../store/adminUsersStore'
import { DefaultView } from './DefaultView'

const hasNameFilter = (user: IUserDoc) => !!user.data.contactName

export const Page: FC<IAdminUsersProps> = ({ store }) => {
  const collection = store.adminUsers
  return useObserver(() => (
    <DocsLayout collection={collection}>
      <PageTitle>Admin Users</PageTitle>
      <PageAction Icon={PersonAdd} label="Add" onClick={store.navigateNew} />
      <DocsList<IUserDoc>
        collection={collection}
        DefaultView={DefaultView}
        filter={hasNameFilter}
      />
      <DocsList<IUserDoc>
        collection={collection}
        DefaultView={DefaultView}
        filter={negate(hasNameFilter)}
        docsTitle="Incomplete"
        collapseHistoryStateKey="adminUsersListDisabled"
      />
      <DocsEmpty>no admin users</DocsEmpty>
    </DocsLayout>
  ))
}
