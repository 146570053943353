import { Coll, IColl } from '@any-ui/core/docs';
import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';
import { computed } from 'mobx';

import { AlarmDoc, sortAlarms } from '../alarms/alarmDoc';
import { greatestAlarmSeverity } from '../alarms/alarmsStore';
import { AlarmKey, IPondAlarms, Severity } from '../model/alarm';
import { DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM, DEFAULT_MIN_DOSE_LITRES } from '../model/dispenserConfig';
import { toFixed } from '../model/format';
import { getDoseGramsPerMmRain, getFloccRemainingMm, IPond, pondFullName } from '../model/pond';

export class PondDoc extends Document<IPond> {
  @computed
  get alarms(): IPondAlarms {
    return this.data.alarms || {}
  }

  @computed
  get alarmsCollection(): IColl<AlarmDoc> {
    return new Coll(
      Object.keys(this.alarms)
        .map(alarmKey => new AlarmDoc(alarmKey as AlarmKey, this))
        .sort(sortAlarms),
    )
  }

  @computed
  get greatestSeverity(): Severity {
    return greatestAlarmSeverity(this.activeAlarms.docs)
  }

  @computed
  get activeAlarms(): IColl<AlarmDoc> {
    return Coll.filter(this.alarmsCollection, alarm => alarm.active)
  }

  @computed
  get floccRemainingDescription(): string {
    const mm = getFloccRemainingMm(this.data)
    if (mm == null) {
      return 'floc level not set'
    }
    return `${toFixed(mm, 0)} mm rain to floc empty`
  }

  @computed
  get activeAlarmsCount(): number {
    return this.activeAlarms.docs.length
  }

  @computed
  get isDeCommissioned(): boolean {
    return !!this.data.decommissionedTimestamp
  }

  @computed
  get isNeverCommissioned(): boolean {
    return !this.data.firstCommissionedTimestamp
  }

  @computed
  get isCommissioned(): boolean {
    return (
      !!this.data.firstCommissionedTimestamp &&
      !this.data.decommissionedTimestamp
    )
  }

  @computed
  get sitePondName(): string {
    return `${this.data.siteName}: ${this.data.name}`
  }

  @computed
  get sitePondEditLink(): string {
    return `/sites/${this.data.siteId}/pond/${this.id}/edit`
  }

  @computed
  get fullName(): string {
    return pondFullName(this.data)
  }

  @computed
  get displayName(): string {
    return this.data.name
  }

  @computed
  get pendingBatchDoseDescription(): string | null {
    return this.data.pendingBatchDoseG
      ? `${this.data.pendingBatchDoseG}ltr dose pending`
      : null
  }

  @computed
  get pendingResetDescription(): string | null {
    return this.data.pendingReset
      ? `Reset pending`
      : null

  }
  @computed
  get PendingNotifyUserDescription(): string | null {
    return this.data.NotifyUserPending
      ? `User renotification pending`
      : null

  }


  @computed
  get siteName(): string {
    return this.data.siteName
  }

  public navigateSiteDashboard = (
    siteUrl: string = this.defaultSiteUrl,
  ) => () => {
    browserHistory.push(`${siteUrl}/pond/${this.id}/chart`)
  }

  public navigateSiteEdit = (siteUrl: string = this.defaultSiteUrl) => () => {
    browserHistory.push(`${siteUrl}/pond/${this.id}/edit`)
  }

  public navigateEdit = () => {
    browserHistory.push(this.sitePondEditLink)
  }

  get maxDryRainAbsorptionMm(): number {
    return (
      this.data.dispenserConfig.maxDryRainAbsorptionMm ||
      DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM
    )
  }

  get doseGramsPerMmRain(): number {
    return getDoseGramsPerMmRain(this.data)
  }

  get minDoseLitres(): number {
    return this.data.dispenserConfig.minDoseLitres || DEFAULT_MIN_DOSE_LITRES
  }

  private get defaultSiteUrl(): string {
    return `sites/${this.data.siteId}`
  }
}
