import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  Battery20TwoTone as Battery,
  CheckCircleTwoTone as NoneIcon,
  CloudOffTwoTone as Offline,
  ColorizeTwoTone as DoseFlow,
  FormatColorResetTwoTone as RainFault,
  InvertColorsOffTwoTone as FloccLevel,
  ReportTwoTone as CriticalIcon,
  SwapCallsTwoTone as SiphonFault,
  WarningTwoTone as LowIcon,
  Opacity as OverdosedIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { FC } from 'react';

import { AlarmKey, Severity } from '../model/alarm';
import { AlarmDoc } from './alarmDoc';
import { alarmColor } from './AlarmStyles';
import { ValueIcon } from './ValueIcon';

const SeverityIconMap: Record<Severity, React.ElementType<SvgIconProps>> = {
  CRITICAL: CriticalIcon,
  LOW: LowIcon,
  NONE: NoneIcon,
}

interface IAlarmMeta {
  icon: React.ElementType<SvgIconProps>
  props?: Partial<SvgIconProps>
}
const AlarmTypeIcon: Record<AlarmKey, IAlarmMeta | undefined> = {
  BATTERY_LEVEL: { icon: Battery, props: { fontSize: 'large' } },
  // DOSE_FLOW_FAULT: { icon: DoseFlow, props: { fontSize: 'large' } },
  FLOCC_LEVEL: { icon: FloccLevel },
  OFFLINE: { icon: Offline },
  RAIN_GAUGE_FAULT: { icon: RainFault, props: { fontSize: 'large' } },
  SIPHON_FAULT: { icon: SiphonFault },
  // VALVE_FAULT: { icon: RainFault, props: { fontSize: 'large' } },
  OVERDOSED_LEVEL: { icon: OverdosedIcon },
}

const alarmTypeIconElement = (alarm: AlarmDoc): Partial<IAlarmMeta> => {
  const iconElement = AlarmTypeIcon[alarm.alarmKey]
  return iconElement || { props: {} }
}

interface IProps {
  alarm: AlarmDoc
  className?: string
}

const useStyles = makeStyles(
  createStyles({
    icon: alarmColor,
  }),
)

export const AlarmIcon: FC<IProps> = ({ alarm, className }) => {
  const { icon: TypeIcon, props: IconProps } = alarmTypeIconElement(alarm)
  const theme = useTheme()
  const classes = useStyles({ theme, severity: alarm.severity })
  return (
    <ValueIcon
      icon={TypeIcon || SeverityIconMap[alarm.severity]}
      className={clsx(alarm.severity, classes.icon, className)}
      {...IconProps}
    />
  )
}
