import { Collection, Mode } from 'firestorter';
import { action, observable } from 'mobx';

import { DeletedSiteDoc } from '../../db/deletedSiteDoc';
import { SiteDoc } from '../../db/siteDoc';
import * as log from '../../log';
import { PondDeleteStore } from '../../pond/pondDeleteStore';
import { pondStore } from '../../pond/pondStore';

// import { unexpectedFormSubmitError } from '../../error'
export class SiteDeleteStore {

    @observable
    private initialDoc: any | null = null

    constructor(siteId?: string) {
        if (siteId) {
            this.setSiteId(siteId)
        }
    }
    public deletedSites = new Collection<DeletedSiteDoc>('deletedSites', {
        createDocument: (...args) => new DeletedSiteDoc(...args),
        mode: Mode.Off
    })

    public delete = async () => {
        if (this.initialDoc.data.isArchive) {
            const fields = {
                // [authStore.isAdmin ? 'adminNotes' : 'customerNotes']: notes,
                oldSiteId: this.initialDoc.id || '',
                ...this.initialDoc.data,
            }
            await this.deletedSites.add(fields)
            await this.removePonds()
            await this.initialDoc.delete()
        }
    }

    public removePonds = async () => {
        const ponds = await pondStore.pondsAtSite(this.initialDoc)
        ponds.docs.map(async (pond) => {
            const pondDeleteStore = new PondDeleteStore(pond.id)
            await pondDeleteStore.delete()
        })
    }

    @action
    private setSiteId(siteId: string) {
        this.initialDoc = new SiteDoc(`sites/${siteId}`, { mode: Mode.Off })
        this.initialDoc.fetch().catch(log.error)
    }
}
