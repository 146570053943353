import { DocsLayout, DocsList, PageTitle } from '@any-ui/core';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { authStore } from '../auth/authStore';
import { customerStore } from '../customer/customerStore';
import { DeviceDoc } from '../db/deviceDoc';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { DeviceRetrievalListItem } from './components/DeviceRetrievalListItem';
import { DeviceRetrievalListTitle } from './components/DeviceRetrievalListTitle';
import { deviceRetrievalsStore } from './deviceRetrievalsStore';

const useStyles = makeStyles<Theme, {}, any>(theme => ({
  defaultView: {
    gridColumnEnd: 'span 2',
    // tslint:disable-next-line:object-literal-sort-keys
    '&.decommissioned': {
      backgroundColor: 'rgba(0,0,0,0.15)',
      gridColumnEnd: 'span 1',
    },
    [theme.breakpoints.up('sm')]: {
      gridColumnEnd: 'span 9',
      // tslint:disable-next-line:object-literal-sort-keys
      '&.decommissioned': {
        gridColumnEnd: 'span 6',
      },
    },
  },
  divider: {},
  docsCollectionTitle: {},
  root: {
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(2),
    },
    gridTemplateColumns: 'repeat( auto-fit, minmax(1fr, 1fr) )',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(25px, 1fr) )',
    },
  },
  searchroot: {
    padding: '2px 4px',
    display: 'inline-flex',
    alignItems: 'center',
    width: 230,
    marginLeft: theme.spacing(1),
  },
  item: {
    marginRight: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  typographyRoot: {
    color: 'rgb(255, 255, 255, 0.7)',
    textAlign: 'center',
  },
  custgrid: {
    textAlign: 'right',
  },
}))

export const DeviceRetrievalListPage: FC = () => {
  const classes =  useStyles({})
  const [customer, setCustomer] = React.useState('-1')
  const [searchText, setSearchText] = React.useState('')
  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setCustomer(value)
    deviceRetrievalsStore.setCustomerId(value)
    await deviceRetrievalsStore.getCustomersDeviceRetrievals()
  }
  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }
  const searchCustomer = (searchText: string) => {
    return customerStore.customers.docs
      .filter(p => p.data.orgName.toLowerCase().indexOf(searchText) !== -1)
      .map(p => {
        return p.id
      }) as Array<any>;
  }
  const filterDevices = (doc: DeviceDoc) => {
    if (searchText) {
       const searchContent = searchText.toLowerCase()
       return doc.displayName.toLowerCase().indexOf(searchContent) !== -1 
       || searchCustomer(searchContent).indexOf(doc.data.ownerUid) !== -1
      
    }
    return true
  }
  const customers = customerStore.customers.docs;
  return useObserver(() => (
    <>
      <DocsLayout
        collection={deviceRetrievalsStore.deviceRetrievals}
        pageWidth="730px"
      >
        <PageTitle>Devices to Retrive</PageTitle>
        <Grid container spacing={3}>
          <Grid item sm={9}>
            <Search onTextChanged={onSearch}></Search>
          </Grid>
          <Grid item sm={3} className={classes.custgrid}>
            {authStore.isAdmin && (
              <DropDown
                handleChange={handleChange}
                placeholder={'All Customers'}
                value={customer}
                items={customers}
              ></DropDown>
            )}
          </Grid>
          <Grid item sm={12}>
          <DocsList<DeviceDoc>
              collection={deviceRetrievalsStore.deviceRetrievals }
              DefaultView={DeviceRetrievalListItem}
              preItems={<DeviceRetrievalListTitle />}
              filter={filterDevices}
              classes={classes}
            />
          </Grid>
          <Grid item sm={12}>
            {!deviceRetrievalsStore.deviceRetrievals.hasDocs ? (
              <Typography
                variant="h6"
                component="h6"
                className={classes.typographyRoot}
              >
                No Device for retrieval.
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </DocsLayout>
    </>
  ))
}
