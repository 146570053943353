  import Dialog from '@material-ui/core/Dialog'
  import Button from '@material-ui/core/Button';
  import DialogActions from '@material-ui/core/DialogActions';
  import DialogTitle from '@material-ui/core/DialogTitle'
  import React, { FC } from 'react'
  import { PondDoc } from '../../db/pondDoc'
  import { pondStore } from '../pondStore'
  
  export const ClearPondDoseDialog: FC<{
    pond: PondDoc
    onClose: () => void
    open: boolean
  }> = ({ pond, onClose, open }) => {
    const handleClose = () => {
      onClose();
    };
    const handleSubmit =()  => {
    pondStore.updatePond(pond, {
        pendingBatchDoseG: 0,
      })
      handleClose();
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Clear outstanding dose?</DialogTitle>
        <DialogActions>
        <Button onClick={handleClose}>No</Button>
          <Button onClick={handleSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }