import { DocsView } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { CompanyStaffDoc } from '../../db/companyStaffDoc';
import { sitesStore } from '../../site/store/sitesStore';
import { getShortDate } from '../../util/commonFunctions';
import { CompanyStaffListItemBase } from './CompanyStaffListItemBase';



export const CompanyStaffListItem: DocsView<CompanyStaffDoc> = ({ document, ...rest }) =>
  useObserver(() => (
    <CompanyStaffListItemBase
      fullName = {`${document.data.firstName} ${document.data.lastName}`}
      ownerUid = {document.data.ownerUid}
      email = {document.data.email}
      siteAssgined = {document.data.siteIds && sitesStore.sitesNamesByIds(document.data.siteIds)}
      phone= {document.data.phoneNumber}
      createdOn={getShortDate(document.data.addedTimestamp.toDate())}
      onClick={document.navigateEdit}
      {...rest}
    />
  ))
