import { DeepPartial } from '@any-ui/form';
import { Collection, Mode } from 'firestorter';
import { action, computed, observable } from 'mobx';

import { ownerStore } from '../auth/ownerStore';
import { DeviceDoc } from '../db/deviceDoc';
import { PondDoc } from '../db/pondDoc';
import { SiteDoc } from '../db/siteDoc';
import { AsUpdate, FieldValue } from '../firestore';
import * as log from '../log';
import {
  DEFAULT_DOSE_MG_PER_LITRE,
  DEFAULT_DOSE_TIME_SECONDS,
  DEFAULT_FLOWING_RAIN_MM_PER_HOUR,
  DEFAULT_MAX_DRY_AFTER_DAYS,
  DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM,
  DEFAULT_MIN_DOSE_MILLILITRES,
  //DEFAULT_RAIN_TYPE,
  M2_PER_HECTARE,
  //DEFAULT_CONNECTION_UPTIME_MINS,
  //DEFAULT_CONNECTION_UPTIME_SECS,
} from '../model/dispenserConfig';
import { getFloccRemainingLitres, IPond } from '../model/pond';
import { sitesStore } from '../site/store/sitesStore';
import { FormFields } from './EditPondForm';
import { pondStore } from './pondStore';
import { deviceHistoryStore } from '../device/deviceHistoryStore';
import { pondHistoryStore } from '../pond/pondHistoryStore';

// import { unexpectedFormSubmitError } from '../error'
export class PondEditStore {
  @computed
  get initialData(): DeepPartial<IPond> {
    return (this.initialDoc && this.initialDoc.data) || {}
  }

  @computed
  get initialValues(): DeepPartial<FormFields> {
    const { dispenserConfig = {}, ...baseRest } = this.initialData
    const { catchmentAreaHectares, doseRate, manualDoseL, ...configRest } = dispenserConfig
    return {
      dispenserConfig: {
        catchmentAreaM2:
          catchmentAreaHectares &&
          Math.round(catchmentAreaHectares * M2_PER_HECTARE),
        doseMgPerLitre: Math.round(
          doseRate
            ? doseRate * 1000000
            : DEFAULT_DOSE_MG_PER_LITRE,
        ),
        manualDoseL: manualDoseL && manualDoseL > 0 ? manualDoseL * 1000 : manualDoseL,
        flowingRainMmPerHour: DEFAULT_FLOWING_RAIN_MM_PER_HOUR,
        maxDryAfterDays: DEFAULT_MAX_DRY_AFTER_DAYS,
        maxDryRainAbsorptionMm: DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM,
        minimumDoseml: DEFAULT_MIN_DOSE_MILLILITRES,
        dosingTimeS: DEFAULT_DOSE_TIME_SECONDS,
        //connectionIntervalMins: DEFAULT_CONNECTION_UPTIME_MINS,
        //connectionUpTimeSecs: DEFAULT_CONNECTION_UPTIME_SECS,
        //rainType: DEFAULT_RAIN_TYPE,
        ...configRest,
      },
      ...baseRest,
    }
  }
  // @computed
  // get initialValues(): DeepPartial<FormFields> {
  //   const { dispenserConfig = {}, ...baseRest } = this.initialData
  //   const { catchmentAreaHectares, doseRate, ...configRest } = dispenserConfig
  //   return {
  //     dispenserConfig: {
  //       catchmentAreaM2: catchmentAreaHectares &&
  //       catchmentAreaHectares,
  //       doseMgPerLitre: doseRate ? doseRate : DEFAULT_DOSE_MG_PER_LITRE,
  //       flowingRainMmPerHour: DEFAULT_FLOWING_RAIN_MM_PER_HOUR,
  //       maxDryAfterDays: DEFAULT_MAX_DRY_AFTER_DAYS,
  //       maxDryRainAbsorptionMm: DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM,
  //       ...configRest,
  //     },
  //     ...baseRest,
  //   }
  // }

  @computed
  get initialOwnerUid(): string | null {
    return this.site
      ? this.site.data.ownerUid
      : ownerStore.defaultOwnerUid || null
  }

  @computed
  get floccRemainingLitres(): number | null {
    return this.initialDoc && getFloccRemainingLitres(this.initialDoc.data)
  }

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @computed
  get isAvailableDevicesLoading() {
    return this.allDevices.isLoading
  }

  @computed
  get siteName() {
    return this.site.displayName
  }

  @computed
  get availableDevices(): DeviceDoc[] {
    return this.allDevices.docs
      .filter(
        d =>
          !pondStore.usedDevicesIds.includes(d.id!) ||
          (this.initialDoc && d.id === this.initialDoc.data.dispenseDeviceId),
      )
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
  }

  public readonly isNew: boolean

  private readonly allDevices: Collection<DeviceDoc>
  private readonly site: SiteDoc

  @observable
  private requestDeviceOwnerUid: string | null = null

  private readonly initialDoc: PondDoc | null = null

  constructor(private readonly siteId: string, pondId?: string) {    
    const site = sitesStore.byId(siteId)
    if (!site) {
      throw new Error(`No site found for ID: ${siteId}`)
    }
    this.site = site
    this.allDevices = new Collection<DeviceDoc>('devices', {
      createDocument: (...args) => new DeviceDoc(...args),
      query: ref =>
        ref
          .where(
            'ownerUid',
            '==',
            this.requestDeviceOwnerUid || this.initialOwnerUid,
          )
          .where('deviceType', '==', 'dispense'),
    })
    this.isNew = !pondId
    if (pondId) {
      this.initialDoc = new PondDoc(`ponds/${pondId}`, { mode: Mode.Off })
      this.initialDoc.fetch().catch(log.error)
    }
  }

  public updateDeviceStatus = async (pond: any, newDeviceId: any = null, isArchive: any) => {
    let status: string | null = null;
    let deviceId: any
    if (!pond && newDeviceId) {
      status = 'Allocated'
      deviceId = newDeviceId;
    } else if (pond && pond.data.dispenseDeviceId && !newDeviceId) {
      status = 'Unallocated';
      deviceId = pond.data.dispenseDeviceId;
    } else if (pond && pond.data.dispenseDeviceId && newDeviceId) {
      status = 'Allocated';
      deviceId = newDeviceId;
      if (newDeviceId !== pond.data.dispenseDeviceId) {
        const device = await new DeviceDoc(
          `devices/${pond.data.dispenseDeviceId}`,
          { mode: Mode.Off },
        )
        await device.set({ status: 'Unallocated' }, { merge: true })
        await deviceHistoryStore.save(pond.data.dispenseDeviceId)
      }
    } else if (pond && newDeviceId) {
      status = 'Allocated';
      deviceId = newDeviceId;
    }
    if (deviceId && status) {
      const device = await new DeviceDoc(`devices/${deviceId}`, {
        mode: Mode.Off,
      })
      await device.set({ status }, { merge: true })
      await deviceHistoryStore.save(deviceId)
      return device
    } else {
      return null
    }

  }

  // public save = async (fields: FormFields) => {
  //   const { dispenserConfig, floccSetLitres, ...baseRest } = fields
  //   const { catchmentAreaM2, doseMgPerLitre, ...configRest } = dispenserConfig
  //   const writeFields: AsUpdate<IPond> = {
  //     ...baseRest,
  //     dispenserConfig: {
  //       catchmentAreaHectares: catchmentAreaM2 / M2_PER_HECTARE,
  //       doseRate: doseMgPerLitre / LIQUID_PAC_MG_PER_LITRE,
  //       ...configRest,
  //     },
  //   }
  //   if (floccSetLitres) {
  //     writeFields.floccSet = {
  //       remainingG: floccSetLitres * LIQUID_PAC_GRAMS_PER_LITRE,
  //       timestamp: FieldValue.serverTimestamp(),
  //     }
  //     writeFields.floccDosedG = 0
  //   }
  //   if (!this.initialDoc) {
  //     writeFields.ownerUid = this.site.data.ownerUid
  //     writeFields.siteId = this.site.id
  //     writeFields.siteName = this.site.displayName
  //   }
  //   try {
  //     if (this.initialDoc) {
  //       await this.initialDoc.set(writeFields, { merge: true })
  //     } else {
  //       await pondStore.ponds.add(writeFields)
  //     }
  //   } catch (e) {
  //     throw unexpectedFormSubmitError(e)
  //   }
  // }
  public save = async (fields: FormFields) => {
    // console.log('fields', fields);
    const {
      dispenserConfig,
      floccSetLitres,
      isArchive,
      dispenseDeviceId,
      ...baseRest
    } = fields
    const { catchmentAreaM2, doseMgPerLitre, manualDoseL, minimumDoseml, ...configRest } = dispenserConfig
    const writeFields: AsUpdate<IPond> = {
      ...baseRest,
      dispenserConfig: {
        catchmentAreaHectares: catchmentAreaM2 / M2_PER_HECTARE,
        doseRate: doseMgPerLitre / 1000000,
        manualDoseL: manualDoseL ? manualDoseL / 1000 : 0,
        minimumDoseml: minimumDoseml,
        ...configRest,
      },
      isArchive: isArchive ? isArchive : false,
      dispenseDeviceId,
    }
    if (floccSetLitres) {
      writeFields.floccSet = {
        // remainingG: floccSetLitres * LIQUID_PAC_GRAMS_PER_LITRE,
        remainingG: floccSetLitres,
        timestamp: FieldValue.serverTimestamp(),
      }
      writeFields.floccDosedG = 0
    }
    if (!this.initialDoc) {
      writeFields.ownerUid = this.site.data.ownerUid
      writeFields.siteId = this.site.id
      writeFields.siteName = this.site.displayName
    }

    /**
     * Updates the device status as per device assignment
     */

    await this.updateDeviceStatus(this.initialDoc, dispenseDeviceId, isArchive)

    /**
     * If pond is archive device status will changes to 'unallocated'
     * and device mapping will be removed
     */
    if (isArchive) {
      writeFields.dispenseDeviceId = null
    }
    if (this.initialDoc) {
      await this.initialDoc.set(writeFields, { merge: true })
      await pondHistoryStore.save(this.initialDoc.id)
    } else {
      let pondData: any = await pondStore.ponds.add(writeFields)
      await pondHistoryStore.save(pondData.id)
    }
    /** Update site status */
    // const siteId = this.initialDoc && this.initialDoc.data && this.initialDoc.data.siteId
    await pondStore.updateSiteStatus(this.siteId, dispenseDeviceId)
  }

  @action
  public setDeviceOwner(ownerUid: string) {
    this.requestDeviceOwnerUid = ownerUid
  }

  public getMetricsDataForPond = async (pondId: any) => {
    const metrics = await new Collection('metrics', {
      mode: Mode.Off,
      query: ref => ref.where('pondId', '==', pondId),
    }).fetch()

    const pondName: any = this.initialDoc && this.initialDoc.data.name ? this.initialDoc.data.name : '';

    let data: any = metrics.docs.length ? metrics.docs[0].data : null
    if (!data) {
      return {
        items: [],
        pondName
      }
    }
    let globalDate = 0
    let globalMonth = 0

    let obj: any = {}
    let key = 0

    metrics.docs.map((d: any) => {
      const currentDate: any = new Date(d.data.timestamp.toDate()).getDate()
      const currentMonth: any = new Date(d.data.timestamp.toDate()).getMonth()

      if (globalDate === currentDate && globalMonth === currentMonth) {

        obj[key].timestamp = d.data.timestamp.toDate()
        obj[key].RainMm = obj[key].RainMm + d.data.metrics.RainMm
        obj[key].DosedLitres = obj[key].DosedLitres + d.data.metrics.DosedLitres
      } else {
        key = key + 1
        obj[key] = {
          timestamp: d.data.timestamp.toDate(),
          RainMm: d.data.metrics.RainMm,
          DosedLitres: d.data.metrics.DosedLitres
        }
        globalDate = currentDate
        globalMonth = currentMonth
      }
    })
    let metricsDayArr = []
    for (let key in obj) {
      metricsDayArr.push(obj[key])
    }
    let now = new Date();
    let startDate = data ? new Date(data.timestamp.toDate()) : now
    let metricsAllDays = [];
    for (let d = startDate; d <= now; d.setDate(d.getDate() + 1)) {
      const filter = metricsDayArr.filter(p => {
        let timestamp = new Date(p.timestamp)
        return timestamp.getDate() === d.getDate() && timestamp.getMonth() === d.getMonth() && timestamp.getFullYear() === d.getFullYear()
      })

      if (filter.length) {
        metricsAllDays.push(filter[0])
      } else {
        let currentTime = JSON.stringify(d)
        metricsAllDays.push({
          timestamp: JSON.parse(currentTime),
          RainMm: 0,
          DosedLitres: 0
        })
      }

    }
    // console.log("metricsDayArr", metricsAllDays)
    return {
      items: metricsAllDays.reverse(),
      pondName: pondName
    }

  }
  public getMatricsDataForDevLogs = async (pondId: string = '') => {
    const metrics = await new Collection('metrics', {
      mode: Mode.Off,
      query: ref => ref.where('pondId', '==', pondId).orderBy('timestamp', 'desc'),
    }).fetch()

    const pondName: any = this.initialDoc && this.initialDoc.data.name ? this.initialDoc.data.name : '';

    let metricsList: any = [];
    metrics.docs.map((m: any) => {
      let temp = {
        pondId: m.data.pondId,
        timestamp: m.data.timestamp,
        ...m.data.metrics,
      }
      metricsList.push(temp)
    })
    return {
      metricsList,
      pondName: pondName
    }
  }

  getDeviceName = async (deviceId = "") => {
    const device = await new DeviceDoc(
      `devices/${deviceId}`,
      { mode: Mode.Off },
    )
    return device.data.serial;
  }

  public getPondHistory = async (pondId: string = '') => {
    const pondHistoryData = await new Collection('pondHistory', {
      mode: Mode.Off,
      query: ref => ref.where('pondId', '==', pondId).orderBy('addedTimestamp', 'desc'),
    }).fetch()
    let pondHistoryList: any = [];
    pondHistoryData.docs.map(async (m: any) => {
      let temp = {
        addedTimestamp: m.data.addedTimestamp,
        ...m.data.dispenserConfig,
        pondName: m.data.name,
        // device: m.data.dispenseDeviceId ? await this.getDeviceName(m.data.dispenseDeviceId) : ""
      }
      pondHistoryList.push(temp)
    })
    // console.log('this.pondHistory', pondHistoryList);
    return pondHistoryList
  }
}
