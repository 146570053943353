import {
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  IFormActionProps,
  requiredString,
  SelectField,
  SwitchField,
  TextField,
  validators,
} from '@any-ui/form';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { authStore } from '../../auth/authStore';
import { customerMenuItems } from '../../customer/customerMenu';
import { ISite } from '../../model/site';
import { SiteEditStore } from '../store/siteEditStore';

interface IClientProps extends IFormActionProps {
  store: SiteEditStore
}

export type FormFields = Pick<
  ISite,
  'name' | 'ownerUid' | 'address' | 'isActive' | 'isArchive'
> & {
  notes: string | null
}

// interface IOwnerIdFieldProps {
//   initialOwnerUid?: string
//   store: SiteEditStore
// }

export const EditSiteForm: FormComponent<FormFields, IClientProps> = ({
  store,
  onResult,
  ...formProps
}) => {
  const handleSubmit: FormSubmitHandler<FormFields> = async values => {
    await onResult({
      address: emptyToNull(values.address),
      name: requiredString(values.name),
      notes: emptyToNull(values.notes),
      ownerUid: requiredString(values.ownerUid),
      isActive: Boolean(values.isActive),
      isArchive: Boolean(values.isArchive),
    })
  }
  
  if (!formProps.initialValues.isActive)
    formProps.initialValues.isActive = false
    
  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...formProps}>
    <Form<FormFields> onSubmit={handleSubmit} {...formProps}>
      <TextField name="name" label="Name" validate={validators.required()} />
      {authStore.isAdmin && (
        <SelectField
          name="ownerUid"
          label="Owner"
          fullWidth
          validate={validators.required()}
        >
          {customerMenuItems({
            initialUid: formProps.initialValues.ownerUid,
            required: true,
          })}
        </SelectField>
      )}
      <TextField name="address" label="Address" rows={5} />
      <TextField name="notes" label="Notes" rows={4} />
      {((authStore.isAdmin || authStore.isCustomer) && !formProps.initialValues.isActive) && <SwitchField name="isArchive" label="Archive" />}
    </Form>
  ))
}
