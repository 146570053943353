import { DocsView, SelectableRoute } from '@any-ui/core'
import { List } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React from 'react'
import { SiteDoc } from '../../db/siteDoc'
import { SiteViewStore } from '../store/siteViewStore'
import { SiteActions } from './SiteActions'
import { SiteCard } from './SiteCard'

export const SiteViewCard: DocsView<
  SiteDoc,
  { document?: SiteDoc; siteUrl: string; store: SiteViewStore }
> = ({ document: ignore, siteUrl, store, ...rest }) => (
  <Box paddingBottom={1}>
    <List disablePadding>
      <SelectableRoute path={siteUrl} exact>
        <SiteCard siteUrl={siteUrl} document={store.siteDoc!} {...rest}>
          {store.canEdit && <SiteActions store={store} siteUrl={siteUrl} />}
        </SiteCard>
      </SelectableRoute>
    </List>
  </Box>
)
