import { IColl } from '@any-ui/core/docs';
import flatten from 'lodash/flatten';
import { computed } from 'mobx';

import { authStore } from '../auth/authStore';
import { Severity } from '../model/alarm';
import { pondStore } from '../pond/pondStore';
import { AlarmDoc, sortAlarms } from './alarmDoc';

export const greatestAlarmSeverity = (alarms: AlarmDoc[]): Severity => {
  return alarms.length > 0 ? alarms[0].severity : 'NONE'
}

class AlarmStore implements IColl<AlarmDoc> {
  @computed
  public get docs(): AlarmDoc[] {
    if (authStore.isAdmin) {
      return flatten(
        pondStore.ponds.docs.map(pond => pond.alarmsCollection.docs),
      ).sort(sortAlarms)
    } else {
      return flatten(
        pondStore.ponds.docs.filter(p => p.data.ownerUid === authStore.uid).map(pond => pond.alarmsCollection.docs)
      ).sort(sortAlarms)
    }

  }

  @computed
  get count(): number {
    return this.docs.length
  }

  @computed
  get activeCount(): number {
    return this.docs.reduce(
      (count, alarm) => (alarm.active ? count + 1 : count),
      0,
    )
  }

  @computed
  get greatestSeverity(): Severity {
    return greatestAlarmSeverity(this.docs)
  }

  public get hasDocs(): boolean {
    return this.docs.length > 0
  }

  public readonly isLoading: boolean = false
}

export const alarmStore = new AlarmStore()
