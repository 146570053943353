import { Theme } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

export const useStyles = makeStyles<Theme>(theme => ({
  dafault: {
    color: theme.palette.text.secondary,
  },
  selected: {
    '&, &:hover': {
      backgroundColor: theme.palette.text.secondary,
      color: 'black',
    },
  },
}))
export const DashHeaderBtn: FC<{ selected: boolean } & ButtonProps> = ({
  selected,
  ...rest
}) => {
  const classes =  useStyles({})
  return (
    <Button
      classes={{
        outlined: clsx(classes.default, {
          [classes.selected]: selected,
        }),
      }}
      {...rest}
    />
  )
}
