import { DocsEmpty, DocsGrid, DocsLayout, DocsSorter, PageAction, PageTitle } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { Button, Theme, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { AddIcon, DownloadIcon, ScanQrIcon } from '../app/Icons';
import { authStore } from '../auth/authStore';
import { customerStore } from '../customer/customerStore';
import { SiteDoc } from '../db/siteDoc';
import { ISite } from '../model/site';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { ISiteCardProps, SiteListCard } from './components/SiteListCard';
import { sitesStore } from './store/sitesStore';

const useStyles = makeStyles<Theme, {}, any>(theme => ({
  defaultView: {
    gridColumnEnd: 'span 2',
    // tslint:disable-next-line:object-literal-sort-keys
    '&.decommissioned': {
      backgroundColor: '#0F0F0F',
      gridColumnEnd: 'span 2',
    },
    [theme.breakpoints.up('sm')]: {
      gridColumnEnd: 'span 9',
      // tslint:disable-next-line:object-literal-sort-keys
      '&.decommissioned': {
        gridColumnEnd: 'span 9',
      },
    },
  },
  divider: {},
  docsCollectionTitle: {},
  root: {
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(2),
    },
    gridTemplateColumns: 'repeat( auto-fit, minmax(1fr, 1fr) )',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(25px, 1fr) )',
    },
  },
  searchroot: {
    padding: '2px 4px',
    display: 'inline-flex',
    alignItems: 'center',
    width: 230,
    marginLeft: theme.spacing(1),
  },
  item: {
    marginRight: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    MuiSvgIcon: {
      fontSize: '15px',
    },
  },
  scanbarcode: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  headertitle: {
    marginTop: 12,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  filter: {
    display: 'flex',
    'justify-content': 'flex-start',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      'justify-content': 'flex-end',
    },
  },

  roottitle: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  btn: {
    height: 40,
  },
  downloadBtn: {
    width: 106,
    height: 40,
    marginLeft: 5,
    lineHeight: 'inherit',
  },
  reports: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}))



export type FormFields = Pick<ISite, 'name' | 'ownerUid' | 'address'> & {
  notes: string | null
}

const sortData = [
  { id: '1', displayName: 'A - Z' },
  { id: '2', displayName: 'Z - A' },
  { id: '3', displayName: 'Active First' },
  { id: '4', displayName: 'Inactive First' },
]
export const SiteListPage: FC = () => {
  const classes = useStyles({})
  const [searchText, setSearchText] = React.useState('')
  const [selectedSort, setSitesSort] = React.useState('1')
  const [selectedCustomer, setCustomerFilter] = React.useState('-1')
  const [showHideArchiveSite, setShowHideArchiveSite] = React.useState(sitesStore.showHideArchiveSite)
  if (authStore.isCompanyStaff) {
    sitesStore.siteForCompanyStaff()
  }
  const getSortFields = (value: string): { name: string; type: string } => {
    switch (value) {
      case '1':
        return { name: 'name', type: 'asc' }
      case '2':
        return { name: 'name', type: 'desc' }
      case '3':
        return { name: 'isActive', type: 'desc' }
      case '4':
        return { name: 'isActive', type: 'asc' }
    }
    return { name: '', type: '' }
  }
  const onSortSite = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSitesSort(value)
    const { name, type } = getSortFields(value)
    sitesStore.sortSites(name, type, selectedCustomer)
  }
  const onFilterCustomer = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setCustomerFilter(value)
    const { name, type } = getSortFields(selectedSort)
    sitesStore.sitesFilter(name, type, value)
  }
  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }
  const searchCustomer = (searchText: string) => {
    return customerStore.customers.docs
      .filter(p => p.data.orgName.toLowerCase().indexOf(searchText) !== -1)
      .map(p => {
        return p.id
      }) as Array<any>
  }
  const filterSites = (doc: SiteDoc) => {
    if (searchText) {
      const searchContent = searchText.toLowerCase()
      return (
        doc.data.name.toLowerCase().indexOf(searchContent) !== -1 ||
        searchCustomer(searchContent).indexOf(doc.data.ownerUid) !== -1
      )
    }
    return true
  }
  const sorter: DocsSorter<SiteDoc> = (a, b) => {
    var nameA = a.data.name.toLowerCase(), nameB = b.data.name.toLowerCase();
    if (nameA < nameB) //sort string ascending
      return -1;
    if (nameA > nameB)
      return 1;
    return 0; //default return value (no sorting)
  }

  // const handleSubmit: FormSubmitHandler<FormFields> = async values => {}

  // console.log('sitesStore.sortSites', sitesStore.sites)
  return useObserver(() => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={1} className={classes.roottitle}>
          <PageTitle>
            <Grid item xs={11} sm={3} className={classes.headertitle}>
              Sites
            </Grid>
            <Grid item xs={4} sm={3} className={classes.scanbarcode}>
              <IconButton
                className={classes.iconButton}
                aria-label="scan"
                onClick={() => browserHistory.push('/scandevice')}
              >
                <Typography variant="body2" display="block" color="primary">
                  Scan
                </Typography>
                &nbsp;
                <ScanQrIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </PageTitle>
        </Grid>
        {(authStore.isAdmin || authStore.isCustomer) && (
          <>
            <Grid item xs={6} sm={3}>
              <Search onTextChanged={onSearch}></Search>
            </Grid>
            <Grid item container direction="row" className={classes.filter} justify="flex-end">

              <DropDown
                className={classes.item}
                value={selectedSort}
                handleChange={onSortSite}
                items={sortData}
              ></DropDown>
              {authStore.isAdmin && (
                <DropDown
                  className={classes.item}
                  handleChange={onFilterCustomer}
                  value={selectedCustomer}
                  placeholder={'All Customers'}
                  items={customerStore.customers.docs}
                ></DropDown>
              )}
              <PageAction
                Icon={AddIcon}
                label="Add site"
                className={classes.btn}
                onClick={SiteDoc.navigateNew}
              />
              <Button color="primary" className={`${classes.downloadBtn} ${classes.item}`} variant="contained" onClick={sitesStore.downloadCsv}>
                <DownloadIcon /> &nbsp;
                <span className={classes.reports}>Reports</span>
              </Button>
              <Button variant="contained" color="primary" onClick={() => {
                setShowHideArchiveSite(!showHideArchiveSite);
                sitesStore.showHideArchiveSite = !showHideArchiveSite
                sitesStore.showHideArchivSites();

              }}>{!sitesStore.showHideArchiveSite ? `See ` : `Hide `}archived Sites</Button>
            </Grid>
          </>
        )}
      </Grid>
      <DocsLayout collection={sitesStore.sites} pageWidth="100%">
        <DocsGrid<SiteDoc, ISiteCardProps>
          DefaultView={SiteListCard}
          collection={sitesStore.sites}
          filter={filterSites}
          sort={sorter}
          classes={classes}
        />
        <DocsEmpty>Add your first site to begin</DocsEmpty>
      </DocsLayout>
    </>
  ))
}
