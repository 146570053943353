import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { ElementType, FC } from 'react';

const useStyles = makeStyles<Theme>({
  label: {
    fontWeight: 400,
    height: 16,
    textAlign: 'center',
    width: 40,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 800,
    justifyContent: 'center',
    width: 40,
  },
})

interface IProps extends Partial<SvgIconProps> {
  label?: string
  icon: ElementType<SvgIconProps>
  className?: string
}

export const ValueIcon: FC<IProps> = ({
  icon: Icon,
  label,
  className,
  ...rest
}) => {
  const classes =  useStyles({})
  return (
    <Box className={clsx(classes.root, 'ValueIcon', className)}>
      <Icon classes={{ root: clsx(classes.icon) }} {...rest} />
      {label && <Box className={classes.label}>{label}</Box>}
    </Box>
  )
}
