import { ResponsiveTitle } from '@any-ui/core';
import { Theme } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

import logo from './logo/logo2_large.png';

const useStyles = makeStyles<Theme>(theme => ({
  image: {
    background: `url("${logo}") no-repeat center center`,
    backgroundSize: 'cover',
    display: 'inline-block',
    height: 194,
    marginBottom: theme.spacing(4),
    width: 134,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing(8)}px auto ${theme.spacing(10)}px auto`,
    maxWidth: 360,
  },
}))

export const LogoLarge: FC<BoxProps> = ({ className, ...props }) => {
  const classes =  useStyles({})
  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box className={classes.image} />
      <ResponsiveTitle mobile="h2" desktop="h2">
        AUTO-FLOC
      </ResponsiveTitle>
    </Box>
  )
}
