import { TStylesGenerator } from '@any-ui/core'
import { Theme } from '@material-ui/core'
import { Severity } from '../model/alarm'

export interface IAlarmColorProps {
  theme: Theme
  severity: Severity
}

export const getAlarmPalette = (theme: Theme, severity: Severity) => {
  switch (severity) {
    case 'CRITICAL':
      return theme.palette.alarms.critical
    case 'LOW':
      return theme.palette.alarms.low
    case 'NONE':
      return theme.palette.alarms.none
  }
}

export const alarmColor = ({ theme, severity }: IAlarmColorProps) => ({
  color: getAlarmPalette(theme, severity).main,
})

export const alarmColorText = ({ theme, severity }: IAlarmColorProps) => ({
  color: getAlarmPalette(theme, severity).light,
})

export const alarmBackground: TStylesGenerator<IAlarmColorProps> = (
  overrideStyles = {},
) => ({ theme, severity }) => {
  const styles =
    typeof overrideStyles === 'function'
      ? overrideStyles({ theme, severity })
      : overrideStyles
  const paletteColor = getAlarmPalette(theme, severity)
  return {
    backgroundColor: paletteColor.main,
    color: paletteColor.contrastText,
    ...styles,
  }
}
