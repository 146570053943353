import { useObserver } from 'mobx-react-lite'

import React, { FC } from 'react'
import { ContentPane } from './components/ContentPane'
import { NavigationPane } from './components/NavigationPane'
import { PaneLayout } from './components/PaneLayout'
import { SiteViewStore } from './store/siteViewStore'

export interface IProps {
  store: SiteViewStore
  siteUrl: string
}

export const SiteViewPage: FC<IProps> = ({ siteUrl, store }) =>
  useObserver(() => (
    <PaneLayout>
      <NavigationPane store={store} siteUrl={siteUrl} />
      <ContentPane store={store} siteUrl={siteUrl} />
    </PaneLayout>
  ))
