import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';

import { SiteViewStore } from '../store/siteViewStore';

export interface IPaneParams {
  store: SiteViewStore
  siteUrl: string
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
    width: '175%',
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(300px, 350px) 3fr',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'minmax(350px, 1fr) 3fr',
    },
  },
}))

const Grid: FC = ({ children }) => {
  const classes =  useStyles({})
  return <div className={classes.root}>{children}</div>
}

export const PaneLayout: FC = ({ children }) => <Grid>{children}</Grid>
