import {
  CardActionIconButton,
  CardSecondaryAction,
  DataCard,
  DisabledRoute,
  DocsView,
  SelectableRoute,
  useConfirm,
} from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { Grid, Theme, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { DeleteStaff, EditIcon, DownloadIcon, RefreshIcon, AlarmsIcon } from '../../app/Icons';
import { authStore } from '../../auth/authStore';
import { PondDoc } from '../../db/pondDoc';
import { DosePondIconButton } from '../dose/DosePondIconButton';
import { ClearPondDoseIconButton } from '../dose/ClearPondDoseIconButton'
import { PondDeleteStore } from '../pondDeleteStore';
import { PondAlarmIcon } from './PondAlarmIcon';
import { PondAlarmLine } from './PondAlarmLine';
import { PondInfoLine } from './PondInfoLine';
import { sitesStore } from '../../site/store/sitesStore';
import { PondEditStore } from '../pondEditStore';
import CardActions from '@material-ui/core/CardActions';
import { Refresh } from '@material-ui/icons';
import { pondStore } from '../pondStore'

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    alignItems: 'flex-start',
  },
  // tslint:disable-next-line:object-literal-sort-keys
  decommissioned: {
    '&.decommissioned': {
      '& .AuiDataCard-Typography': {
        fontSize: '0.8rem',
      },
      alignItems: 'center',
      opacity: 0.5,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short,
      }),
      // tslint:disable-next-line:object-literal-sort-keys
      '&:hover': {
        opacity: 1,
      },
      display: 'grid',
    },
  },
}))

export interface IPondCardProps {
  selected?: boolean
  canEdit: boolean
  siteUrl: string
  canView?: boolean
  siteId?: string
}

export const PondCard: DocsView<PondDoc, IPondCardProps> = ({
  document: pond,
  selected,
  canEdit,
  siteUrl,
  className,
  canView,
  siteId = '',
  ...rest
}) => {
  const classes = useStyles({})
  const store = useStoreClass(PondDeleteStore, pond.id)
  const pondEditStore = useStoreClass(PondEditStore, siteId, pond.id);
  const onDeletePond = useConfirm({
    message: 'Are you sure you want to delete the pond?',
    onCancel: () => { },
    onConfirm: async () => {
      await store.delete();
      // browserHistory.push('/companystaffs');
    },
    title: `Delete Pond`,
  });

  const getCanUserView = canEdit || canView ? true : false

  const downloadPondData = async () => {
    const pondData = await pondEditStore.getMetricsDataForPond(pond.id);
    if (pondData.items.length) {
      return sitesStore.downloadSiteCsv(pondData);
    } else {
      alert("Data not exist");
    }
  }
  const setResetPondField = async () => {
    pondStore.updatePond(pond, {
      pendingReset: true,
    })
  }
  const notify_user = async () => {
    // set a field in the db called 'notifyUser' to true
    pondStore.updatePond(pond, {
      NotifyUserPending: true,
    })
  }

  return useObserver(() => {
    const decommissioned = !selected && pond.isDeCommissioned
    return (
      <SelectableRoute path={`${siteUrl}/pond/${pond.id}`}>
        <DataCard
          selected={selected}
          onClick={pond.navigateSiteDashboard(siteUrl)}
          className={clsx(
            classes.root,
            { decommissioned, [classes.decommissioned]: decommissioned },
            className,
          )}
          icon={
            <PondAlarmIcon
              active={!!pond.activeAlarmsCount && pond.isCommissioned}
              data={!authStore.isViewer ? pond.activeAlarmsCount : 0}
              severity={pond.greatestSeverity}
            />
          }
          primaryText={pond.displayName}
          secondaryText={
            !authStore.isViewer &&
            pond.isCommissioned && (
              <>
                <PondInfoLine description={pond.floccRemainingDescription} />
                {pond.pendingBatchDoseDescription && (
                  <PondInfoLine
                    description={pond.pendingBatchDoseDescription}
                  />
                )}
                {pond.pendingResetDescription && (
                  <PondInfoLine
                    description={pond.pendingResetDescription}
                  />
                )}
                {pond.PendingNotifyUserDescription && (
                  <PondInfoLine
                    description={pond.PendingNotifyUserDescription}
                  />
                )}
                {pond.activeAlarms.docs.map(alarm => (
                  (alarm.alarmKey == "FLOCC_LEVEL" || alarm.alarmKey == "OFFLINE" || authStore.isAdmin) && (
                    <PondAlarmLine alarm={alarm} key={alarm.id} />
                  )
                ))}
              </>
            )
          }
          secondaryAction={
            getCanUserView && (
              <CardActions>             
                  {pond.isCommissioned && canEdit && (
                    <DisabledRoute path={`${siteUrl}/pond/:pondId/edit`}>
                      <DosePondIconButton pond={pond} />
                    </DisabledRoute>
                  )}
                  {pond.isCommissioned && canEdit && (
                    <DisabledRoute path={`${siteUrl}/pond/:pondId/edit`}>
                      <ClearPondDoseIconButton pond={pond} />
                    </DisabledRoute>
                  )}
                  {canEdit && (
                    <CardActionIconButton
                      aria-label="Download Report"
                      tooltip="Download Report"
                      onClick={downloadPondData}
                    >
                      <DownloadIcon />
                    </CardActionIconButton>
                  )}
                                  
                  <DisabledRoute path={`${siteUrl}/pond/:pondId/edit`}>
                    <CardActionIconButton
                      aria-label="Edit"
                      onClick={pond.navigateSiteEdit(siteUrl)}
                    >
                      <EditIcon />
                    </CardActionIconButton>
                  </DisabledRoute>
                  {pond.isCommissioned && canEdit && authStore.isAdmin && (
                    <CardActionIconButton
                      aria-label="Reset device"
                      tooltip="Reset device"
                      onClick={setResetPondField}
                    >
                      <RefreshIcon />
                    </CardActionIconButton>
                  )}
                  {pond.isCommissioned && canEdit && authStore.isAdmin && (
                    <CardActionIconButton
                      aria-label="Renotify user"
                      tooltip="Renotify user"
                      onClick={notify_user}
                      >
                      <AlarmsIcon />
                      </CardActionIconButton>
                   )}
                  {!pond.isCommissioned && canEdit && <DisabledRoute path={`${siteUrl}/pond/:pondId/edit`}>
                    <CardActionIconButton
                      aria-label="Delete"
                      onClick={onDeletePond}>
                      <DeleteStaff color="error" />
                    </CardActionIconButton>
                  </DisabledRoute>}
                  
              </CardActions>
            )
          }
          {...rest}
        />
      </SelectableRoute>
    )
  })
}
