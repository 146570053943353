// import { locationStore } from '@any-ui/core/navigation'
import { locationStore } from '@any-ui/core/navigation';
import { Collection, Mode } from 'firestorter';
import { action, computed, reaction } from 'mobx';

import { authStore } from '../auth/authStore';
import { DeviceRequestDoc } from '../db/deviceRequestDoc';

// import { reaction } from 'mobx'
class DeviceRequestStore {
  public deviceRequests = new Collection<DeviceRequestDoc>('deviceRequests', {
    createDocument: (...args) => new DeviceRequestDoc(...args),
    mode: Mode.On,
    query: ref => 
      authStore.isAdmin
        ? ref.where('isActive', '==', true)
        : authStore.isCustomer
        ? ref.where('ownerUid', '==', authStore.uid).where('isActive', '==', true)
        : null,
  })
  private readonly disposer: () => void
  private customerId = ''
  constructor() {
    this.disposer = reaction(
      () => locationStore.path.endsWith('/devices/request'),
      inDevicesRequest => {
        this.deviceRequests.mode = inDevicesRequest ? Mode.On : Mode.Auto
      },
      { fireImmediately: true },
    )
  }
  @computed
  get isLoading(): boolean {
    return this.deviceRequests.isLoading
  }
  @action
  public setCustomerId(customerId: string) {
    this.customerId = customerId
  }
  public getCustomersDeviceReq = async () => {
    if (this.customerId !== '-1') {
      this.deviceRequests = new Collection<DeviceRequestDoc>('deviceRequests', {
        createDocument: (...args) => new DeviceRequestDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('ownerUid', '==', this.customerId).where('isActive', '==', true),
      })
    } else 
      this.deviceRequests = new Collection<DeviceRequestDoc>('deviceRequests', {
        createDocument: (...args) => new DeviceRequestDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('isActive', '==', true),
      })
    }
}

export const deviceRequestStore = new DeviceRequestStore()
