import { Theme } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

import logo from './logo/logo2_25x36.png';

const useStyles = makeStyles<Theme>({
  image: {
    background: `url("${logo}") no-repeat center center`,
    backgroundSize: 'cover',
    display: 'inline-block',
    height: 36,
    width: 25,
  },
  root: {
    display: 'inline-flex',
    flexShrink: 0,
    // minWidth: 56,
  },
})

export const Logo: FC<BoxProps> = ({ className, ...props }) => {
  const classes =  useStyles({})
  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box className={classes.image} />
    </Box>
  )
}
