import { PathAction } from '@any-ui/core';
import React from 'react';

import { alarmStore } from '../alarms/alarmsStore';
import { AddIcon, AssignIcon, RetrieveIcon, TutorialIcon } from '../app/Icons';
import { CustomerListStore } from '../customer/components/customerListStore';
import {
  AdminUsersIcon,
  AlarmsIcon,
  CompanyStaff,
  CustomersIcon,
  DevicesIcon,
  History,
  Invoice,
  ProfileIcon,
  SignOutIcon,
  SiteIcon,
} from './Icons';

export const AlarmsAction = (
  <PathAction
    path="/alarms"
    Icon={AlarmsIcon}
    badge={() => alarmStore.activeCount}
  >
    Alarms
  </PathAction>
)

export const AdminUsersAction = (
  <PathAction path="/admin" Icon={AdminUsersIcon}>
    Admin Users
  </PathAction>
)

export const ProfileAction = (
  <PathAction path="/profile" Icon={ProfileIcon}>
    Profile
  </PathAction>
)

export const SignOutAction = (
  <PathAction path="/signout" Icon={SignOutIcon}>
    Sign out
  </PathAction>
)

export const SitesAction = (
  <PathAction path="/" root Icon={SiteIcon}>
    Sites
  </PathAction>
)

export const DevicesAction = (
  <PathAction path="/devices" Icon={DevicesIcon}>
    Devices
  </PathAction>
)

export const CustomersAction = (newCustomers: CustomerListStore) => (
  <PathAction path="/customers" Icon={CustomersIcon} badge={() => newCustomers.newUserCount}>
    Customers
  </PathAction>
)
export const DeviceRequestAction = (
  <PathAction path="/devices/request" Icon={AssignIcon}>
    Requested Devices
  </PathAction>
)
export const DeviceRetrieveAction = (
  <PathAction path="/devices/retrieve" Icon={RetrieveIcon}>
    Retrieve Devices
  </PathAction>
)
export const NewDeviceRequestAction = (
  <PathAction path="/devices/request/new" Icon={AddIcon}>
    New Devices Request
  </PathAction>
)
export const CompanyStaffAction = (
  <PathAction path="/companystaffs" Icon={CompanyStaff}>
    Company Staff
  </PathAction>
)
export const InvoiceAction = (
  <PathAction path="/invoice" Icon={Invoice}>
    Invoice
  </PathAction>
)
export const DeviceHistoryAction = (
  <PathAction path="/devicehistory" Icon={History}>
    Device History
 </PathAction>
)

