import { PageHeaderAction } from '@any-ui/core'
import Typography from '@material-ui/core/Typography'
import Refresh from '@material-ui/icons/Refresh'
import React, { FC } from 'react'
import { authStore } from './authStore'

export const NoRolePage: FC = () => (
  <>
    <PageHeaderAction
      label="Refresh"
      onClick={authStore.refreshTokenInBackground}
      Icon={Refresh}
    />
    <Typography variant="h4" align="center" gutterBottom>
      Thanks for signing up
    </Typography>
    <Typography align="center">
      Contact us to complete your account setup.
    </Typography>
  </>
)
