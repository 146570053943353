import pick from 'lodash/pick';
import { action, computed, observable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { authStore } from '../auth/authStore';
import { customerStore } from '../customer/customerStore';
import { AsUpdate } from '../firestore';
import { ICustomer } from '../model/customer';
import { FormFields } from './EditProfileForm';

// import { unexpectedFormSubmitError } from '../error'
type EmailVerificationState =
  | 'verified'
  | 'unverified'
  | 'resending'
  | 'sent'
  | 'failed'

export class ProfileEditStore {
  @computed
  get isCustomer(): boolean {
    return authStore.isCustomer
  }

  @computed
  get isLoading(): boolean {
    return authStore.isLoading || (this.isCustomer && customerStore.isLoading)
  }

  @computed
  get initialValues(): FormFields {
    const userProfile = authStore.buildUserProfile()
    if (!userProfile) {
      throw new Error('No user')
    }
    return {
      ...userProfile,
      newPassword: null,
      phoneNumber: customerStore.user && customerStore.user.data.phoneNumber,
    }
  }

  @computed
  get emailVerificationState(): EmailVerificationState {
    if (authStore.isEmailVerified) {
      return 'verified'
    }
    if (this.sendEmailVerificationPromise == null) {
      return 'unverified'
    }
    return this.sendEmailVerificationPromise.case<EmailVerificationState>({
      fulfilled: () => 'sent',
      pending: () => 'resending',
      rejected: () => 'failed',
    })
  }

  @observable
  private sendEmailVerificationPromise:
    | IPromiseBasedObservable<void>
    | undefined

  public async save(profile: FormFields) {
    await authStore.updateUser(profile)
    if (customerStore.user) {
      const customerFields: AsUpdate<ICustomer> = pick(profile, [
        'contactName',
        'email',
        'phoneNumber',
      ])
      await customerStore.user.update(customerFields)
    }
  }

  @action
  public sendEmailVerification = () => {
    this.sendEmailVerificationPromise = fromPromise(
      authStore.sendEmailVerification(),
    )
  }
}
