import { CardActionIconButton, CardActionsBar } from '@any-ui/core'
import { DisabledRoute } from '@any-ui/core'
import Box from '@material-ui/core/Box'
import React, { FC } from 'react'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { browserHistory } from '@any-ui/core/navigation';
import { PersonAddIcon } from '../../app/Icons'
import { AddCircleIcon } from '../../app/Icons'
import { SiteViewStore } from '../store/siteViewStore'
import { ShareViewerLinkIconButton } from '../viewers/ShareViewerLinkIconButton'
import {companyStaffStore} from '../../companyStaff/companyStaffStore'
import { authStore } from '../../auth/authStore'

const handleClick = (siteUrl: string) => {
  browserHistory.push(`${siteUrl}/siteStaff`)
}

export const SiteActions: FC<{
  siteUrl: string
  store: SiteViewStore
}> = ({ siteUrl, store }) => (
  <CardActionsBar>
    <ShareViewerLinkIconButton store={store} />
    <DisabledRoute path={`${siteUrl}/pond/:pondId/edit`}>
      <CardActionIconButton
        color="primary"
        aria-label="New pond"
        tooltip="New pond"
        onClick={store.siteDoc!.navigateNewPond}
      >
        <AddCircleIcon />
      </CardActionIconButton>
      </DisabledRoute>

      {/* Conditionally render the "Manage staff" button */}
    {authStore.isAdminOrCustomer && (
      <Tooltip title="Manage staff">
        <IconButton
          color="primary"
          aria-label="Manage staff"
          onClick={() => handleClick(siteUrl)}
        >
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
    )}
    <Box width={12}>&nbsp;</Box>
  </CardActionsBar>
)
