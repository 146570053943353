import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

// tslint:disable:object-literal-sort-keys
// @ts-ignore
const useStyles = makeStyles<Theme>(theme => ({
  serial: {
    fontWeight: 800,
  },
  customerName: {},
  status: {},
  allocatedStatus: {
    color: '#C0E800',
  },
  unAllocatedStatus: {
    color: '#FD4646',
  },
  offHireStatus: {
    color: '#676767',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 1fr 1fr',
  },
  notDeployed: {
    color: theme.palette.text.disabled,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}))

interface ICompanyStaffListItemBaseProps extends Partial<ListItemProps> {
  fullName: string
  email?: string
  siteAssgined: string
  phone: string | null
  createdOn?: string
  ownerUid?:string | null
}


export const CompanyStaffListItemBase: FC<ICompanyStaffListItemBaseProps> = 
({
  ownerUid,
  fullName,
  email,
  siteAssgined,
  phone,
  createdOn,
  className,
  ...rest
}) => {
  const classes =  useStyles({})

  return useObserver(() => (
    <ListItem
      // @ts-ignore
      button
      classes={{
        root: clsx(classes.root),
      }}
      className={clsx('DeviceListItemBase', className)}
      {...rest}
    >
      <Box className={clsx(classes.serial)}>{fullName}</Box>
      <Box className={clsx(classes.customerName)}>{email}</Box>
      <Box className={clsx(classes.deployedToPond)}>{siteAssgined}</Box>
      <Box className={clsx(classes.deployedToPond)}>{phone}</Box>
      <Box className={clsx(classes.deployedToPond)}>{createdOn}</Box>
    </ListItem>
  ))
}
