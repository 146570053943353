import { DocsView } from '@any-ui/core'
import React from 'react'
import { SiteDoc } from '../../db/siteDoc'
import { SiteViewStore } from '../store/siteViewStore'
import { SiteCard } from './SiteCard'

export interface ISiteCardProps {
  store: SiteViewStore
  siteUrl: string
}
export type TSiteListCard = DocsView<SiteDoc, ISiteCardProps>
export const SiteListCard: TSiteListCard = ({ document, siteUrl, ...rest }) => {
  return (
    <SiteCard
      document={document}
      siteUrl={`${/sites/}${document.id}`}
      {...rest}
    />
  )
}
