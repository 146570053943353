import { Coll, IColl } from '@any-ui/core/docs';
import { Collection } from 'firestorter';
import { computed } from 'mobx';

import { PondDoc } from '../../db/pondDoc';
import { SiteDoc } from '../../db/siteDoc';
import { pondStore } from '../../pond/pondStore';
import { sitesStore } from './sitesStore';

// import { unexpectedFormSubmitError } from '../../error'
export class SiteViewStore {
  @computed
  get isLoading(): boolean {
    return !!this.siteId && !this.siteDoc
  }

  @computed
  get siteDoc(): SiteDoc | null {
    return (this.siteId && sitesStore.byId(this.siteId)) || null
  }

  @computed
  get ponds(): IColl<PondDoc> {
    return this.siteDoc ? pondStore.pondsAtSite(this.siteDoc) : new Coll([])
  }
  @computed
  get isDeCommissioned(): boolean {
    return this.ponds.docs.filter(p => p.isDeCommissioned === true).length > 0;
  }

  constructor(
    public readonly canEdit: boolean,
    private readonly siteId: string | null,
    public readonly canView: boolean = false,
  ) { }

  public createViewer = async (): Promise<string> => {
    // try {
    //   const viewers = new Collection('viewers')
    //   const newDoc = await viewers.add({
    //     siteId: this.siteId,
    //   })
    //   return newDoc.id!
    // } catch (e) {
    //   throw unexpectedFormSubmitError(e)
    // }
    const viewers = new Collection('viewers')
    const newDoc = await viewers.add({
      siteId: this.siteId,
    })
    return newDoc.id!
  }
}
