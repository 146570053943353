import { DocsEmpty, DocsLayout, DocsList, DocsSorter, PageAction, PageTitle } from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import negate from 'lodash/negate';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { CustomerDoc } from '../db/customerDoc';
import { CustomerListStore } from './components/customerListStore';
import { NewUserItem } from './components/NewUserItem';
import { CustomerEditStore } from './customerEditStore';
import { CustomerListItem } from './CustomerListItem';
import { customerStore } from './customerStore';

// import { companyStaffStore } from '../companyStaff/companyStaffStore'

const enabledFilter = (customer: CustomerDoc) => customer.data.enabled
const disabledFilter = negate(enabledFilter)
// console.log('companyStaffStore', companyStaffStore.companyStaffs)
// console.log('companyStaffStoreId', companyStaffStore.byId('BpulsImqSFg7uDxvkiObOxsPaQI2'))
// console.log('companyStaffStoreSearch', companyStaffStore.searchCompanyStaff('users'))
// console.log('companyStaffStoreSort', companyStaffStore.sortCompanyStaff('contactName', 'desc'))
const sorter: DocsSorter<CustomerDoc> = (a, b) =>
  a.displayName.localeCompare(b.displayName)

const useStyles = makeStyles<Theme, {}, any>(theme => ({
  root: {
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(2),
    },
    gridTemplateColumns: 'repeat( auto-fit, minmax(1fr, 1fr) )',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(25px, 1fr) )',
    },
  },
  item: {
    marginRight: '5px',
  },
  headertitle: {
    marginTop: 12,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  smallheadertitle: {
    marginTop: 12,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    fontSize: 15,
    textAlign: 'center'
  },
  roottitle: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}))
export const CustomerListPage: FC = () => {
  const classes = useStyles({})

  const store = useStoreClass(CustomerEditStore, '0')
  const newCustomers = useStoreClass(CustomerListStore, store);
  console.log(newCustomers);
  return useObserver(() => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={1} className={classes.roottitle}>
          <PageTitle>
            <Grid item xs={11} sm={3} className={classes.headertitle}>
              Customers
            </Grid>
          </PageTitle>
        </Grid>
        <Grid item container direction="row" className={classes.filter} justify="flex-end">
          <PageAction
            Icon={PersonAdd}
            label="Add customer"
            onClick={CustomerDoc.navigateNew}
          />
        </Grid>
        <Grid item xs={6} >
          <PageTitle>
            <Grid item xs={3} sm={3} className={classes.smallheadertitle}>
              Active Customers
            </Grid>
          </PageTitle>
          <DocsLayout collection={customerStore.customers} pageWidth="100%">
            <DocsList<CustomerDoc>
              collection={customerStore.customers}
              DefaultView={CustomerListItem}
              filter={enabledFilter}
              sort={sorter}
            />
            <DocsEmpty>Add the first customer to begin</DocsEmpty>
          </DocsLayout>
        </Grid>
        <Grid item xs={6} >
          <PageTitle>
            <Grid item xs={3} sm={3} className={classes.smallheadertitle}>
              Pending Customers
            </Grid>
          </PageTitle>
          <DocsLayout collection={newCustomers} pageWidth="100%">
            <DocsList
              collection={newCustomers}
              DefaultView={NewUserItem}
            />
            <DocsEmpty>no pending customers </DocsEmpty>
          </DocsLayout>
        </Grid>
      </Grid>
    </>
  ))
}

