import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { EditSiteForm, FormFields } from './components/EditSiteForm';
import { SiteEditStore } from './store/siteEditStore';

export interface IRouteParams {
  siteId?: string
}

export const EditSitePage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const { siteId } = match.params
  const store = useStoreClass(SiteEditStore, siteId)
  const save = async (fields: FormFields) => {
    await store.save(fields)
    browserHistory.replace(`/sites`)
  }
  return useObserver(() => (
    <EditPage isLoading={store.isLoading} heading="Site setup">
      <EditSiteForm
        isNew={store.isNew}
        initialValues={store.initialValues}
        store={store}
        onResult={save}
      />
    </EditPage>
  ))
}
