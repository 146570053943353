import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import { CompanyStaffListItemBase } from './CompanyStaffListItemBase';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    backgroundColor: theme.palette.action.selected,
    fontSize: '1.1rem',
    // tslint:disable-next-line:object-literal-sort-keys
    '.MuiPaper-rounded &': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
  },
}))

export const CompanyStaffListTitle = () => {
  const classes =  useStyles({})
  return (
    <CompanyStaffListItemBase
      fullName="Full Name"
      email="E-mail"
      siteAssgined="Sites Assigned"
      phone="Phone"
      createdOn = "Created On"
      className={clsx('CompanyStaffListTitle', classes.root)}
      button={false}
    />
  )
}
