import { DocsHeader, Grow } from '@any-ui/core';
import { Theme } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Legend } from 'react-timeseries-charts';

import { DashboardStore } from '../dashboardStore';
import { DashHeaderBtn } from './DashHeaderBtn';

// @ts-ignore
export const useStyles = makeStyles<Theme>({
  root: {
    opacity: 0.5,
    transition: 'opacity ease-out 250ms',
    // tslint:disable-next-line:object-literal-sort-keys
    '&:hover': {
      opacity: 1,
    },
  },
})

export const DashHeader: FC<{ colorStyle: any; store: DashboardStore }> = ({
  colorStyle,
  store,
}) => {
  const classes =  useStyles({})
  return useObserver(() => (
    <DocsHeader paddingX={2} paddingY={3}>
      <ButtonGroup className={classes.root}>
      <DashHeaderBtn
          selected={store.rollUpWindow === 'weekly'}
          onClick={store.setRollUpWindowWeekly}
        >
          Weekly
        </DashHeaderBtn>
        <DashHeaderBtn
          selected={store.rollUpWindow === 'daily'}
          onClick={store.setRollUpWindowDaily}
        >
          Daily
        </DashHeaderBtn>
        <DashHeaderBtn
          selected={store.rollUpWindow === 'hourly'}
          onClick={store.setRollUpWindowHourly}
        >
          Hourly
        </DashHeaderBtn>
        
      </ButtonGroup>
      <Grow />
      <Legend
        style={colorStyle}
        align="right"
        marginBottom="0"
        categories={[
          { key: 'RainMm', label: 'Rain' },
          { key: 'DryAbsorptionMm', label: 'Dry absorption' },
          { key: 'DosedGrams', label: 'Dosed' },
          { key: 'BatchDosedGrams', label: 'Batch dosed' },
          { key: 'WantedDoseGrams', label: 'Dose outstanding' },
          { key: 'commissioned', label: 'Commissioned' },
          { key: 'ManualDosedLiteres', label: 'Manual dosed'},
          { key: 'SignalQualityPercent', label: 'Signal quality' },
          { key: 'SignalStrengthPercent', label: 'Signal strength' },
        ]}
      />
    </DocsHeader>
  ))
}
