export const HIBERNATE_VOLTS = 3.6
export const MAX_CHARGE_VOLTS = 4.1

export const batteryVoltsToPercentage = (
  volts?: number | null,
): number | null => {
  if (volts == null) {
    return null
  } else if (volts >= MAX_CHARGE_VOLTS) {
    return 100
  } else if (volts <= HIBERNATE_VOLTS) {
    return 0
  } else {
    return (
      ((volts - HIBERNATE_VOLTS) / (MAX_CHARGE_VOLTS - HIBERNATE_VOLTS)) * 100
    )
  }
}
