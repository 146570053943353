import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { CompanyStaffListPage } from './CompanyStaffListPage';
import { EditCompanyStaffPage } from './EditCompanyStaffPage';

export const CompanyStaffRoutes: FC<RouteComponentProps> = () => (
  <Switch>
    <Route path="/companystaffs" exact component={CompanyStaffListPage} />
    <Route path="/companystaffs/new" component={EditCompanyStaffPage} />
    <Route path="/companystaffs/:uid/edit" component={EditCompanyStaffPage} />

    <Redirect to="/" />
  </Switch>
)
