import { EditPage, useConfirm } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { DeviceStatus } from '../tools/DeviceStatus';
import { DeviceDeleteStore } from './deviceDeleteStore';
import { DeviceEditStore } from './deviceEditStore';
import { EditDeviceForm, FormFields } from './EditDeviceForm';

// import { DeviceRequestEditStore } from '../deviceRequest/deviceRequestEditStore'
// import { DeviceRetrievalEditStore } from './../deviceRetrieval/deviceRetrievalEditStore';
// import { deviceRequestStore } from '../deviceRequest/deviceRequestStore'

export interface IRouteParams {
  deviceId?: string
}

const useStyles = makeStyles({
  otherBtn: {
    minWidth: 160,
    marginTop: 20,
  },
  endhire: {
    display: 'flex',
    flexDirection: 'inherit'
  },
  bottomBtnContainer: {
    paddingBottom: 60
  }
})
export const EditDevicePage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const classes = useStyles({})
  const { deviceId } = match.params
  const store = useStoreClass(DeviceEditStore, deviceId)
  const deleteDeviceStore = useStoreClass(DeviceDeleteStore, deviceId);
  // console.log('DeviceRequestStore', deviceRequestStore.deviceRequests);
  // const storeDR = useStoreClass(DeviceRequestEditStore, '')
  // const storeCR = useStoreClass(DeviceRetrievalEditStore, deviceId)

  // const saveDR = async (fields: FormFields) => {
  //   const input: any = {
  //     // noOfDevice: 4,
  //     deliveryAddress: 'Mumbai',
  //     deliveryInstructions: 'No Instructions'
  //   }
  //   await storeDR.save(input)
  // }
  const save = async (fields: FormFields) => {
    // console.log(fields);
    await store.save(fields)
    browserHistory.goBack()
  }

  const onDelete = useConfirm({
    message: 'Are you sure you want to delete the device?',
    onCancel: () => { },
    onConfirm: async () => {
      await deleteDeviceStore.delete();
      browserHistory.goBack()
    },
    title: `Delete Device`,
  });
  // const saveT = async (fields: any) => {
  //   const input: any = {

  //       }
  //       await storeCR.save(input)
  // }
  return useObserver(() => (
    <>
      <EditPage isLoading={store.isLoading} heading="Device setup">
        <EditDeviceForm
          isNew={store.isNew}
          initialValues={store.initialValues}
          deployedToPond={store.deployedToPond}
          onDelete={onDelete}
          onResult={save}
        />
      </EditPage>
      <Grid container className={classes.bottomBtnContainer} spacing={1}>
        {store &&
          store.initialValues &&
          (store.initialValues.status === DeviceStatus.ALLOCATED ||
            store.initialValues.status === DeviceStatus.UNALLOCATED) && (
            <Grid item xs={6} sm={6} className={classes.endhire} justify="flex-end">
              <Button
                variant="outlined"
                className={classes.otherBtn}
                color="secondary"
                size="large"
                id={DeviceStatus.OFFHIRE}
                onClick={async () => {
                  await store.endHireDevice()
                  browserHistory.goBack()
                }}
              >
                End Hire
              </Button>
            </Grid>
          )}
        {store &&
          store.initialValues &&
          store.initialValues.status === DeviceStatus.ALLOCATED && (
            <Grid item xs={6} sm={6}>
              <Button
                variant="outlined"
                className={classes.otherBtn}
                color="secondary"
                size="large"
                id={DeviceStatus.UNALLOCATED}
                onClick={async () => {
                  await store.markUnallocated()
                  browserHistory.goBack()
                }}
              >
                Mark as Unallocated
              </Button>
            </Grid>
          )}
      </Grid>
    </>
  ))
}
