import { ActionsBar, Grow } from '@any-ui/core'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import copyToClipboard from 'copy-to-clipboard'
import React, { FC, useState } from 'react'
import { SiteViewStore } from '../store/siteViewStore'
import { ActionCaption } from './ActionCaption'
import { PrimaryAction } from './PrimaryAction'
import { ViewerLink } from './ViewerLink'

const createLink = (viewerId: string) =>
  `${window.location.origin}/viewers/${viewerId}`.toString()

export const ShareLinkDialog: FC<{
  store: SiteViewStore
  onClose: () => void
  open: boolean
}> = ({ store, onClose, open }) => {
  const [viewerLink, setViewerLink] = useState<string | null>(null)
  const [generating, setWorking] = useState(false)
  const handleClose = () => {
    onClose()
    setViewerLink(null)
  }
  const handleShare = async () => {
    setWorking(true)
    const value = await store.createViewer()
    const linkText = createLink(value)
    copyToClipboard(linkText)
    setViewerLink(linkText)
    setWorking(false)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        {!viewerLink ? `Create viewer link` : 'Link created'}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <ViewerLink viewerLink={viewerLink} onView={handleClose}>
          Anyone with this link can view all data about this site and it's ponds
          without a login. Create link?
        </ViewerLink>
      </DialogContent>
      <DialogContent>
        <ActionsBar>
          {generating ? (
            <>
              <ActionCaption>Generating link...</ActionCaption>
              <Grow />
              <CircularProgress size={32} />
            </>
          ) : !viewerLink ? (
            <>
              <Button onClick={onClose}>No</Button>
              <PrimaryAction onClick={handleShare}>{'Yes'}</PrimaryAction>
            </>
          ) : (
            <>
              <ActionCaption>Already copied to clipboard</ActionCaption>
              <Grow />
              <PrimaryAction onClick={handleClose}>Close</PrimaryAction>
            </>
          )}
        </ActionsBar>
      </DialogContent>
    </Dialog>
  )
}
