import { EditPage } from '@any-ui/core';
import { Card, Theme } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { SiteViewStore } from './store/siteViewStore';

const useStyles = makeStyles<Theme>(theme => ({
  title: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre',
  },
}))

export const ViewersSitePage: FC<{ store: SiteViewStore }> = ({ store }) => {
  const classes = useStyles({})
  return useObserver(() => {
    if (store.isLoading || !store.siteDoc) {
      return null
    }
    return (
      <EditPage isLoading={store.isLoading} heading={store.siteDoc.displayName}>
        <Card>
          <CardHeader
            title={store.siteDoc!.address}
            classes={{ title: classes.title }}
          />
        </Card>
      </EditPage>
    )
  })
}
