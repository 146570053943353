import { HttpsCallableCollAdapter, IDoc } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { observable } from 'mobx';

import { apiCall } from '../../firebase';
import { IUser, ListCountData } from '../../model/user';
import { CustomerEditStore } from '../customerEditStore';

const listNewUsers = apiCall<IUser[]>('listNewUsers', ListCountData)

export interface IUserDoc extends IDoc {
  data: IUser
  handleSelect: () => void
}

export class CustomerListStore extends HttpsCallableCollAdapter<IUserDoc> {
  @observable
  public newUserCount = 0;
  constructor(customerEditStore: CustomerEditStore) {
    super(
      () => listNewUsers({}),
      data => ({
        data,
        handleSelect: () => {
          browserHistory.push(`/customers/new/${data.email}/${data.contactName}`)
        },
        id: data.uid,
      }),
    )
   this.getNewUsers();
  }
   getNewUsers =  async () => {
    const user = await listNewUsers({})
     this.newUserCount = user.data.length;
  }

}


