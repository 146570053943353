import { computed } from 'mobx'
import { authStore } from './authStore'

class OwnerStore {
  @computed
  get defaultOwnerUid(): string | null {
    if (authStore.isCustomer) {
      return authStore.uid
    }
    return null
  }
}

export const ownerStore = new OwnerStore()
