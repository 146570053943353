import { DocsView } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { customerStore } from '../../customer/customerStore';
import { DeviceDoc } from '../../db/deviceDoc';
import { getDateWithTime } from '../../util/commonFunctions';
import { DeviceHistoryListItemBase } from './DeviceHistoryListItemBase';

export const DeviceHistoryListItem: DocsView<DeviceDoc> = ({ document, ...rest }) =>
  useObserver(() => (
    <DeviceHistoryListItemBase
      serial={document.displayName}
      customerName={
        (document.data.ownerUid &&
          customerStore.customerName(document.data.ownerUid)) ||
        undefined
      }
      deployedToPond={
        getDateWithTime(document.data.addedTimestamp.toDate())
      }
      status={document.data.status}
      onClick={document.navigateEdit}
      {...rest}
    />
  ))
