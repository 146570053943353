// import { Coll, IColl } from '@any-ui/core/docs';
// import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';
// import { computed } from 'mobx';

// import { AlarmDoc, sortAlarms } from '../alarms/alarmDoc';
// import { greatestAlarmSeverity } from '../alarms/alarmsStore';
// import { AlarmKey, IPondAlarms, Severity } from '../model/alarm';
// import { DEFAULT_MAX_DRY_RAIN_ABSORPTION_MM, DEFAULT_MIN_DOSE_LITRES } from '../model/dispenserConfig';
// import { toFixed } from '../model/format';
import { IPond } from '../model/pond';

export class DeletedPondDoc extends Document<IPond> {

}
