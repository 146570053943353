import {
  EmailField,
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  IFormActionProps,
  requiredString,
  SwitchField,
  TextField,
  validators,
} from '@any-ui/form';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { ICustomerUpdate } from '../model/customer';
import { phone } from '../userProfile/phoneValidator';

interface IClientProps extends IFormActionProps {
  existsInAuth?: boolean
}

const { email, required } = validators

export const EditCustomerForm: FormComponent<ICustomerUpdate, IClientProps> = ({
  existsInAuth = false,
  onResult,
  isNew,
  ...rest
}) => {
  const handleSubmit: FormSubmitHandler<ICustomerUpdate> = async values => {
    await onResult({
      contactName: emptyToNull(values.contactName),
      email: requiredString(values.email),
      enabled: Boolean(values.enabled),
      newPassword: emptyToNull(values.newPassword),
      orgName: requiredString(values.orgName),
      phoneNumber: emptyToNull(values.phoneNumber),
    })
  }
  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...rest}>
    <Form<ICustomerUpdate> onSubmit={handleSubmit} {...rest}>
      <EmailField
        name="email"
        label="Email address"
        helperText={
          existsInAuth && isNew ? 'This user has signed up themselves' : null
        }
        validate={email()}
        autoFocus
      />
      <TextField
        name="phoneNumber"
        label="Mobile number"
        helperText="For alarm SMS"
        validate={phone()}
      />
      <TextField name="orgName" label="Company name" validate={required()} />
      <TextField name="contactName" label="Contact name" />
      <SwitchField name="enabled" label="Enabled" />
      {/* <PasswordField
        name="newPassword"
        label="Password"
        placeholder={existsInAuth ? 'Set by user' : undefined}
        validate={length({
          ...passwordLengthValidationOptions,
          allowBlank: existsInAuth,
        })}
      /> */}
    </Form>
  ))
}
