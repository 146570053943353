import { fieldError } from '@any-ui/form'
import { Mode } from 'firestorter'
import { computed, observable } from 'mobx'

import { authStore } from '../auth/authStore'
import { CompanyStaffDoc } from '../db/companyStaffDoc'
import { SiteDoc } from '../db/siteDoc'
import { template } from '../emailTemplates/companystaffRegisterTemplate'
import { apiCall, firebase } from '../firebase'
import { FieldValue } from '../firestore'
import * as log from '../log'
import {
  ApplyCompanyStaffToUserData,
  IApplyCompanyStaffToUserResult,
  Notification,
} from '../model/companyStaffModal'
import { GetUserByEmailData, IUser } from '../model/user'
import { generateRandomString, getHtmlTemplate } from '../util/commonFunctions'
import { IFormFields as FindFormFields } from './FindUserForm'

// import { unexpectedFormSubmitError } from '../error'

const getUserByEmail = apiCall<IUser>('getUserByEmail', GetUserByEmailData)
const applyCompanyStaffToUser = apiCall<IApplyCompanyStaffToUserResult>(
  'applyCompanyStaffToUser',
  ApplyCompanyStaffToUserData,
)

const sendEmail = apiCall<boolean>(
  'notifications',
  Notification
)


export class CompanyStaffEditStore {
  public readonly isNew: boolean

  @computed
  get initialValues(): any {
    if (this.initialDoc) {
      return this.initialDoc.data
    }
    if (this.foundUser) {
      return this.foundUser
    }
    if (this.searchedEmail) {
      return {
        email: this.searchedEmail,
        enabled: true,
      }
    }
    return null
  }

  @computed
  get existsInAuth(): boolean {
    return (this.initialDoc && this.initialDoc.hasData) || !!this.foundUser
  }

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @observable
  private searchedEmail: string | null = null

  @observable
  private foundUser: IUser | null = null
  private userResult: IUser | null = null

  private readonly initialDoc: CompanyStaffDoc | null = null

  constructor(uid?: string) {
    this.isNew = !uid
    if (uid) {
      this.initialDoc = new CompanyStaffDoc(`companyStaffs/${uid}`, {
        mode: Mode.Off,
      })
      this.initialDoc.fetch().catch(log.error)
    }
  }

  public find = async ({ email }: FindFormFields) => {
    try {
      const result = await getUserByEmail({ email })
      this.foundUser = result.data
    } catch (e) {
      if ((e as firebase.functions.HttpsError).code !== 'not-found') {
        throw fieldError<FindFormFields>(
          'email',
          'Search failed, you can retry',
        )
      }
    } finally {
      this.searchedEmail = email
    }
  }
  /**
    * Assigns Company staff to site
    */
  public assignCustomersStaffToSites = async (siteId: string, companyStaffId: string) => {
    const site = await new SiteDoc(`sites/${siteId}`, { mode: Mode.Off }).fetch()
    const companyStaffIds = (site.data.companyStaffIds && site.data.companyStaffIds.length) ? site.data.companyStaffIds : [];
    if (companyStaffIds.indexOf(companyStaffId) === -1) {
      companyStaffIds.push(companyStaffId)
      await site.set({ companyStaffIds }, { merge: true })
      return true
    } else {
      return false
    }
  }

  public removeCustomersStaffFromSites = async (siteId: string, companyStaffId: string) => {
    const site = await new SiteDoc(`sites/${siteId}`, { mode: Mode.Off }).fetch();
    const companyStaffIds = (site.data.companyStaffIds && site.data.companyStaffIds.length) ? site.data.companyStaffIds : [];
    
    const index = companyStaffIds.indexOf(companyStaffId);
    if (index !== -1) {
      companyStaffIds.splice(index, 1); // Remove the companyStaffId from the array
      await site.set({ companyStaffIds }, { merge: true });
      return true;
    } else {
      return false; // The companyStaffId was not found in the array
    }
  }

/**Checking if users exist in firebase account */
  public findUserData = async ({ email }: FindFormFields) => {
    try {
      let result = await getUserByEmail({ email })
      this.userResult = result.data
    } catch (e) {
      // console.log('e', e);
    }
  }
  public save = async (companyStaff: any) => {
    await this.findUserData({ email: companyStaff.email })
    try {
      authStore.isLoading = true;

      const initialcompanyStaffUuid =
        (this.initialDoc && this.initialDoc.id) || null
      let uid: string
      let finalData: any
      if (!initialcompanyStaffUuid && !this.userResult) {
        companyStaff.newPassword = generateRandomString()
      }
      if (authStore.isCustomer) {
        companyStaff.ownerUid = authStore.uid
      }
      companyStaff.enabled = true
      if (authStore.uid && initialcompanyStaffUuid === authStore.uid) {
        const companyStaffData = {
          ...companyStaff,
          contactName: `${companyStaff.firstName} ${companyStaff.lastName}`,
        }
        finalData = {
          ...companyStaff,
        }
        await authStore.updateUser(companyStaffData);
        uid = authStore.uid
      } else {
        finalData = {
          ...companyStaff,
          addedTimestamp: FieldValue.serverTimestamp() || null,
        }
        const updateResult = await applyCompanyStaffToUser({
          companyStaff,
          uid: initialcompanyStaffUuid,
        })
        uid = updateResult.data.uid
      }
      finalData.siteIds.map(async (s: any) => {
        await this.assignCustomersStaffToSites(s, uid)
      })

      /** Send email to company staff on registration */
      if (!initialcompanyStaffUuid && !this.userResult) {
        const templateData = {
          fullName: `${companyStaff.firstName} ${companyStaff.lastName}`,
          email: companyStaff.email,
          password: companyStaff.newPassword
        }
        const finalTemplate = getHtmlTemplate(template, templateData);
        await sendEmail({
          toEmail: companyStaff.email,
          subject: 'Welcome to Autoflocc',
          template: finalTemplate
        })
      }
      await new CompanyStaffDoc(`companyStaffs/${uid}`).set(finalData, {
        merge: true,
      });
    } catch (e) {
      alert('email validation failed: auth/email-already-exists');
    } finally {
      authStore.isLoading = false;
    }
  }

  public select = (user: IUser) => {
    this.foundUser = user
    this.searchedEmail = user.email
  }

}
