import { Tooltip } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import React, { FC, ReactNode } from 'react'

export interface IViewerLinkProps {
  viewerLink: string | null
  onView: VoidFunction
  children: ReactNode
}

export const ViewerLink: FC<IViewerLinkProps> = ({
  viewerLink,
  onView,
  children,
}) => {
  const handleView = () => {
    if (viewerLink) {
      window.open(viewerLink, '_blank')
      onView()
    }
  }
  return viewerLink ? (
    <Tooltip title="Open link in new tab">
      <Link color="primary" onClick={handleView}>
        {viewerLink}
      </Link>
    </Tooltip>
  ) : (
    <>{children}</>
  )
}
