import { DocsLayout, DocsList, DocsSorter, PageAction, PageTitle } from '@any-ui/core';
import { Grid, Theme, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/AddOutlined';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { authStore } from '../auth/authStore';
import { customerStore } from '../customer/customerStore';
import { DeviceDoc } from '../db/deviceDoc';
import { DeviceStatus } from '../tools/DeviceStatus';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { DeviceListItem } from './components/DeviceListItem';
import { DeviceListTitle } from './components/DeviceListTitle';
import { deviceStore } from './deviceStore';

// added by me
import { apiCall } from '../firebase';
import {
  Notification,
} from '../model/customer';
import { generateRandomString, getHtmlTemplate } from '../util/commonFunctions';
import { template } from '../emailTemplates/customerRegisterTemplate';
import { update } from 'lodash';
const useStyles = makeStyles<Theme, {}, any>({
  defaultView: {},
  divider: {
    opacity: 0.25,
  },
  docsCollectionTitle: {},
  root: {},
  typographyRoot: {
    color: 'rgb(255, 255, 255, 0.7)',
    textAlign: 'center',
  },
  custgrid: {
    textAlign: 'right',
  },
})

const deployedNum = (device: DeviceDoc) => (device.deployedToPond ? 1 : 0)
const deviceStatusFileter = [
  { id: DeviceStatus.ALLOCATED, displayName: 'Allocated devices' },
  { id: DeviceStatus.UNALLOCATED, displayName: 'Unallocated devices' },
  { id: DeviceStatus.OFFHIRE, displayName: 'Off Hire' },
]
const sorter: DocsSorter<DeviceDoc> = (a, b) =>
  deployedNum(b) - deployedNum(a) || a.displayName.localeCompare(b.displayName)

export const DeviceListPage: FC = () => {
  const classes =  useStyles({})
  const [selectedDevice, setDeviceFilter] = React.useState('-1')
  const [selectedCustomer, setCustomerFilter] = React.useState('-1')
  const [searchText, setSearchText] = React.useState('')
  const onFilterDevice = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setDeviceFilter(value)
    deviceStore.deviceFilter(value, selectedCustomer)
  }
  const onFilterCustomer = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setCustomerFilter(value)
    deviceStore.deviceFilter(selectedDevice, value)
  }
  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }
  const searchCustomer = (searchText: string) => {
    return customerStore.customers.docs
      .filter(p => p.data.orgName.toLowerCase().indexOf(searchText) !== -1)
      .map(p => {
        return p.id
      }) as Array<any>;
  }
  const filterDevice = (doc: DeviceDoc) => {
    if (searchText) {
      const searchContent = searchText.toLowerCase();
      return (
        doc.data.status.toLowerCase().indexOf(searchContent) !==
          -1 ||
        doc.data.serial.toLowerCase().indexOf(searchContent) !== -1 ||
        searchCustomer(searchContent).indexOf(doc.data.ownerUid) !== -1
      )
    }
    return true
  }



// const  pp = async () =>{
//   let updatedCount = 1;
//   const devicedata = await deviceStore.getDeviceDataWithPondId('aocXmy10fpOTww0slmyT')
//   if(devicedata ){

//       const pondsCount = devicedata?.data.doseCount
//       updatedCount = pondsCount ? pondsCount+updatedCount : updatedCount
//       await devicedata.set({ pondsCount: updatedCount}, {merge: true})

//       if(pondsCount && pondsCount > 10){
//         console.log('emailtriggerd')       
//       }
//   }
  
//   return `done`
// }


  return useObserver(() => (
    <DocsLayout collection={deviceStore.devices} pageWidth="800px">
      <PageTitle>Devices</PageTitle>
      {authStore.isAdmin && (
        <PageAction
          Icon={Add}
          label="Add devices"
          onClick={DeviceDoc.navigateNew}
        />
      )}
      <Grid container spacing={3}>
        <Grid item sm={7}>
          <Search onTextChanged={onSearch}></Search>
        </Grid>

        <Grid item sm={2} className={classes.custgrid}>
          {authStore.isAdmin && (
            <DropDown
              handleChange={onFilterCustomer}
              value={selectedCustomer}
              placeholder={'All Customers'}
              items={customerStore.customers.docs}
            ></DropDown>
          )}
        </Grid>

        <Grid item sm={3} className={classes.custgrid}>
          <DropDown
            value={selectedDevice}
            handleChange={onFilterDevice}
            placeholder={'All Device'}
            items={deviceStatusFileter}
          ></DropDown>
        </Grid>
        <Grid item sm={12}>
          <h4>Total devices : {deviceStore.devices.docs.length}</h4>
          <DocsList<DeviceDoc>
            collection={deviceStore.devices}
            DefaultView={DeviceListItem}
            preItems={<DeviceListTitle />}
            filter={filterDevice}
            sort={sorter}
            classes={classes}
          />
        </Grid>
        <Grid item sm={12}>
          {!deviceStore.devices.hasDocs ? (
            <Typography
              variant="h6"
              component="h6"
              className={classes.typographyRoot}
            >
              Add the first device to begin
            </Typography>
          ) : null}
        </Grid>
      </Grid> 
    </DocsLayout>
  ))
}
