import { DocsView } from '@any-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import ListItem from '@material-ui/core/ListItem'
import ListItemButton from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { AccountCircle } from '@material-ui/icons'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { IUserDoc } from './customerListStore'

export const NewUserItem: DocsView<IUserDoc> = ({ document }) =>
  useObserver(() => {
    const user = document.data
    return (
      <ListItem button>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary={user.contactName} secondary={user.email} />
      </ListItem>
    )
  })
