import { PageTitle } from '@any-ui/core'
import { Grid, Theme, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { useObserver, observer } from 'mobx-react-lite'
import React, { FC, useState, useEffect } from 'react'

import MonthPicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// import DatePicker from 'react-month-picker'
// import Grid from "@material-ui/core/Grid";
// import 'date-fns'

// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { customerStore } from '../../customer/customerStore'
import { sitesStore } from '../../site/store/sitesStore'
import { DropDown } from '../../tools/DropDown'
import {
  getMonths,
  getShortDate,
  getShortMonthYear,
  month_names,
} from '../../util/commonFunctions'
// import 'css/month-picker.css'

const useStyles = makeStyles<Theme, {}, any>(theme => ({
  item: {
    marginRight: 5,
    marginTop: 10,
  },
  invoiceBtn: {
    marginTop: 10,
  },
  warning: {
    color: '#FFCC00',
  },
}))
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  header: {
    textAlign: 'center',
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },

  tableCol: {
    // width: '11.11%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
  tableCellHeader: {
    margin: 'auto',
    fontWeight: 'bold',
    marginTop: 5,
    fontSize: 11,
  },
  total: {
    textAlign: 'right',
    fontSize: 13,
    marginTop: 15,
    marginRight: 30,
    marginBottom: 10,
  },
  details: {
    textAlign: 'left',
    fontSize: 13,
    marginTop: 15,
    marginRight: 15,
    marginBottom: 10,
  },
  detailsValue: {
    fontSize: 11,
  },
  thanks: {
    marginTop: 10,
  },
  commonCol: {
    width: '11.67%',
  },
  emailCol: {
    width: '16.5%',
  },
  amountChargedCol: {
    width: '8%',
  },
  NoOfDaysCol: {
    width: '5.5%',
  },
} as any)

const InvoiceDocument = (props: any) => {
  // console.log('props', props)
  let total = 0
  const todayDate = new Date()
  // const modifiedSelectedMonth = Math.abs(JSON.parse(props.selectedMonth == '-12' ? '0' : props.selectedMonth))
  // if (props.selectedMonth < 0) {
  //   todayDate.setFullYear(todayDate.getFullYear() - 1)
  const modifiedSelectedMonth = props.selectedMonth
  // const  d = new Date(modifiedSelectedMonth)
  // console.log(d)
  // Wed Feb 29 2012 11:00:00 GMT+1100 (EST)
  // d.setDate(d.getDate() + 20)

  const now = modifiedSelectedMonth
  let current = new Date()
  if (now.getMonth() == 11) {
    current = new Date(now.getFullYear() + 1, 0, 20)
  } else {
    current = new Date(now.getFullYear(), now.getMonth() + 1, 20)
  }
  // console.log('current', current)
  const monthh = month_names[current.getMonth()]
  const yr = current.getFullYear()
  const dd = current.getDate()
  const finalDat = `${dd}-${monthh}-${yr}`
  // console.log('finale', finalDat)
  // console.log('hhhjhjhjhj', d)
  // }
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <Text>Autofloc invoice </Text>
        </View>
        {/* <View style={styles.details}>
          <Text>For month : {getShortMonthYear(+modifiedSelectedMonth)}</Text>
          <Text> Invoice Due: {getShortDate(new Date(todayDate.getFullYear(), +modifiedSelectedMonth + 1, 20))}</Text>
        </View> */}
        <View style={styles.details}>
          <Text>
            For month : {month_names[modifiedSelectedMonth.getMonth()]},{' '}
            {modifiedSelectedMonth.getFullYear()}
          </Text>
          <Text> Invoice Due: {finalDat}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Customer</Text>
            </View>
            <View style={[styles.tableCol, styles.emailCol]}>
              <Text style={styles.tableCellHeader}>Email</Text>
            </View>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Site</Text>
            </View>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Site Address</Text>
            </View>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Pond</Text>
            </View>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Device</Text>
            </View>
            <View style={[styles.tableCol, styles.commonCol]}>
              <Text style={styles.tableCellHeader}>Device Added</Text>
            </View>
            <View style={[styles.tableCol, styles.NoOfDaysCol]}>
              <Text style={styles.tableCellHeader}>No Of Days</Text>
            </View>
            <View style={[styles.tableCol, styles.amountChargedCol]}>
              <Text style={styles.tableCellHeader}>Amount Charged</Text>
            </View>
          </View>
          {props &&
            props.data &&
            props.data.map((item: any, index: number) => {
              total = total + +item.Amount
              return (
                <View key={index} style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.CustomerName}</Text>
                  </View>
                  <View style={[styles.tableCol, styles.emailCol]}>
                    <Text style={styles.tableCell}>
                      {item.Email.substring(0, 15)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {item.Email.substring(15, item.Email.length)}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.Site} </Text>
                  </View>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.SiteAddress} </Text>
                  </View>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.Pond}</Text>
                  </View>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.Device}</Text>
                  </View>
                  <View style={[styles.tableCol, styles.commonCol]}>
                    <Text style={styles.tableCell}>{item.DeviceAdded}</Text>
                  </View>
                  <View style={[styles.tableCol, styles.NoOfDaysCol]}>
                    <Text style={styles.tableCell}>{item.NoOfDays}</Text>
                  </View>
                  <View style={[styles.tableCol, styles.amountChargedCol]}>
                    <Text style={styles.tableCell}>${item.Amount}</Text>
                  </View>
                </View>
              )
            })}
        </View>
        <View style={styles.total}>
          <Text>Total Amount : ${total}</Text>
        </View>
        <View style={styles.details}>
          <Text>Please pay this into account</Text>
          <Text>Silt Control Solutions ltd</Text>
          <Text>12- 45637- 212234 -122332</Text>
          <Text style={styles.thanks}>Thanks for your business </Text>
        </View>
      </Page>
    </Document>
  )
}

export const InvoicePage: any = observer(function InvoicePage({
  navigation,
}: any) {
  const classes = useStyles({})
  const [selectedCustomer, setSelectedCustomer] = useState('-1')
  const [selectedSite, setSelectedSite] = useState('-1')
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  // const  d = new Date("2012-02-29")
  // console.log(d)
  // Wed Feb 29 2012 11:00:00 GMT+1100 (EST)

  // d.setDate(d.getDate() + 1)
  // console.log(d)
  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth().toString())
  const [show, setHide] = useState(false)
  const [isFuture, setIsFuture] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState([] as Array<any>)

  useEffect(() => {
    //this is to make sure that customer data fetching is done then generate invoice
    if (customerStore.isLoading === false) {
      setHide(false)
      generateInvoiceForAllCustomers(
        selectedCustomer,
        selectedSite,
        selectedMonth,
      )
    }
  }, [customerStore.isLoading])

  const onCustomerChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setHide(false)
    const selectdId = event.target.value as string
    setSelectedCustomer(selectdId)
    setSelectedSite('-1')
    sitesStore.setSelectedCustomerId(selectdId)
    if (selectdId.toString() === '-1') {
      await generateInvoiceForAllCustomers(
        selectdId,
        selectedSite,
        selectedMonth,
      )
    } else {
      await generateInvoice(selectdId, selectedSite, selectedMonth)
    }
  }
  const onSelectedMonthChange = async (ev: Date) => {
    setHide(false)
    const selectdId = ev as Date
    setInvoiceDetails([])
    setIsFuture(false)
    setSelectedMonth(selectdId)
    const today = new Date()
    if (
      selectdId.getFullYear() !== today.getFullYear() ||
      selectdId.getMonth() <= today.getMonth()
    ) {
      setIsFuture(false)
      if (selectedCustomer) {
        if (selectedCustomer.toString() === '-1') {
          await generateInvoiceForAllCustomers(
            selectedCustomer,
            selectedSite,
            selectdId,
          )
        } else {
          await generateInvoice(selectedCustomer, selectedSite, selectdId)
        }
      }
    } else {
      setIsFuture(true)
    }
  }
  const onSiteChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setHide(false)
    const selectdId = event.target.value as string
    setSelectedSite(selectdId)
    if (selectdId.toString() === '-1') {
      await generateInvoiceForAllCustomers(
        selectedCustomer,
        selectdId,
        selectedMonth,
      )
    } else {
      await generateInvoice(selectedCustomer, selectdId, selectedMonth)
    }
  }
  const generateInvoice = (
    customerId: string,
    siteId: string,
    selectedMonth: Date,
  ) => {
    setInvoiceDetails([])
    setHide(false)
    sitesStore
      .generateInvoice(customerId, siteId, selectedMonth)
      .then(invoiceDetails => {
        setTimeout(() => {
          setInvoiceDetails(invoiceDetails as Array<any>)
          setHide(true)
        }, 2000)
      })
  }
  // const onMonthChange = (event: React.ChangeEvent<{ value: unknown }>) =>{
  //   setSelectedMonth(event.target.value as string);
  // }
  const generateInvoiceForAllCustomers = async (
    customerId: string,
    siteId: string,
    selectedMonth: Date,
  ) => {
    const customers = await customerStore.customers.docs
    const promises: any[] = []
    const customersInvoice: any[] = []
    customers.map(cust => {
      promises.push(
        sitesStore.generateInvoice(cust.id || '', siteId, selectedMonth),
      )
    })
    const result = await Promise.all(promises)
    result.map(res => {
      customersInvoice.push(...res)
    })
    setTimeout(() => {
      setInvoiceDetails(customersInvoice as Array<any>)
      setHide(true)
    }, 2000)
  }
  const enabledCustomers = customerStore.customers.docs.filter(
    c => c.data.enabled,
  )

  const loadingData = !!customerStore.isLoading || false
  return useObserver(() => (
    <>
      <PageTitle>Generate Invoice</PageTitle>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          {/* <DropDown
            className={classes.item}
            handleChange={onSelectedMonthChange}
            value={selectedMonth}
            items={getMonths()}
          ></DropDown> */}
          {/* <Datepicker  dateFormat="yyyy-mm"/> */}

          <DropDown
            className={classes.item}
            handleChange={onCustomerChange}
            value={selectedCustomer}
            placeholder={'All Customers'}
            items={enabledCustomers.sort((a, b) => {
              if (a.displayName < b.displayName) {
                return -1
              }
              if (a.displayName > b.displayName) {
                return 1
              }
              return 0
            })}
            // items={activeCustomers}
          ></DropDown>
          <DropDown
            className={classes.item}
            handleChange={onSiteChange}
            value={selectedSite}
            placeholder={'All Sites'}
            items={sitesStore.customerSites}
          ></DropDown>
          <MonthPicker
            selected={selectedMonth}
            dateFormat="MM/yyyy"
            onChange={onSelectedMonthChange}
            showMonthYearPicker
          />

          {/* <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Start from year selection"
            value={{year: 214, month :5}}
            onChange={onSelectedMonthChange}
          /> */}
          {/* <DropDown
            className={classes.item}
            handleChange={onSelectedMonthChange}
            value={selectedMonth}
            items={getMonths().map(p => {
              p.displayName = p.id > -1 ? `${p.displayName} - ${new Date().getFullYear()}` : `${p.displayName} - ${new Date().getFullYear() - 1}`
              // p.displayName = `${p.displayName} - ${new Date().getFullYear()}`
              return p;
            })}
          ></DropDown> */}
        </Grid>
        <Grid item xs={12} sm={12} className={classes.invoiceBtn}>
          {show && !loadingData && !isFuture && (
            <PDFDownloadLink
              document={
                <InvoiceDocument
                  data={invoiceDetails}
                  selectedMonth={selectedMonth}
                />
              }
              fileName="invoice.pdf"
              style={{
                textDecoration: 'none',
                padding: '10px',
                color: '#FFF',
                borderRadius: 6,
                marginTop: 5,
                backgroundColor: 'rgb(232, 166, 32)',
                border: '1px solid rgb(232, 166, 32)',
              }}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download Invoice'
              }
            </PDFDownloadLink>
          )}
          {isFuture && (
            <h2 className={classes.warning}>
              You can not generate future invoice
            </h2>
          )}
          {/* <Button
            color="primary"
            variant="contained"
            className={classes.invoiceBtn}
            onClick={()=>{sitesStore.generateInvoice(selectedCustomer,selectedSite)}}
          >
            <Invoice /> &nbsp;
            <span className={classes.reports}>Generate Invoice</span>
          </Button> */}
        </Grid>
      </Grid>
    </>
  ))
})
