import { DeepPartial } from '@any-ui/form';
import { Collection, Mode } from 'firestorter';
import { computed } from 'mobx';

import { authStore } from '../auth/authStore';
import { ownerStore } from '../auth/ownerStore';
import { DeviceDoc } from '../db/deviceDoc';
import { PondDoc } from '../db/pondDoc';
import { AsUpdate, FieldValue } from '../firestore';
import * as log from '../log';
import { IDevice } from '../model/device';
import { pondStore } from '../pond/pondStore';
import { deviceStore } from './deviceStore';
import { FormFields } from './EditDeviceForm';
import { deviceHistoryStore } from './deviceHistoryStore'

export class DeviceEditStore {
  public readonly isNew: boolean

  @computed
  get deployedToPond(): PondDoc | null {
    return !this.isLoading && !pondStore.ponds.isLoading && this.deviceId
      ? pondStore.withDispenserDeviceId(this.deviceId)
      : null
  }

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @computed
  get initialValues(): DeepPartial<FormFields> {
    const doc: Partial<IDevice> =
      (this.initialDoc && this.initialDoc.data) || {}
    return {
      ownerUid: ownerStore.defaultOwnerUid,
      ...doc,
    }
  }


  // private readonly initialDoc: DeviceDoc | null = null
  private readonly initialDoc: any | null = null

  constructor(private readonly deviceId?: string) {
    this.isNew = !deviceId
    if (deviceId) {
      // this.deployedToPond = pondStore.withDispenserDeviceId(deviceId)
      this.initialDoc = new DeviceDoc(() => `devices/${deviceId}`, {
        mode: Mode.Off,
      })
      this.initialDoc.fetch().catch(log.error)
    }
  }

  public deviceStatus = (fields: FormFields) => {
    if (this.deployedToPond) {
      return 'Allocated'
    } else if (fields.siteId || fields.ownerUid) {
      return 'Unallocated'
    } else {
      return 'Off Hire'
    }
  }

  public save = async (fields: FormFields) => {
    if (this.initialDoc) {
      const writeFields: any = fields;
      if (this.initialDoc.data.ownerUid !== fields.ownerUid) {
        writeFields.addedTimestamp = FieldValue.serverTimestamp()
      }
      writeFields.status = this.deviceStatus(fields)
      await this.initialDoc.set(writeFields, { merge: true })
      await deviceHistoryStore.save(this.initialDoc.id)
    } else {
      const data: AsUpdate<IDevice> = {
        addedTimestamp: FieldValue.serverTimestamp(),
        deviceType: 'dispense',
        writtenConfig: null,
        ...fields,
        status: this.deviceStatus(fields),
      }
      const latestDevice: any = await deviceStore.devices.add(data)
      await deviceHistoryStore.save(latestDevice.id)
    }
  
  }
  public saveDeviceRequest = async (fields: FormFields) => {
    if (this.initialDoc) {
      await this.initialDoc.set(fields, { merge: true })
    } else {
      const data: AsUpdate<IDevice> = {
        addedTimestamp: FieldValue.serverTimestamp(),
        deviceType: 'dispense',
        writtenConfig: null,
        ...fields,
        status: this.deviceStatus(fields),
      }
      await deviceStore.devices.add(data)
    }
  }

  public endHireDevice = async () => {
    try {
      /** Update device status to End Hire */
      authStore.isLoading = true
      const initialDoc = new DeviceDoc(() => `devices/${this.deviceId}`, {
        mode: Mode.Off,
      })
      initialDoc.fetch().then(async p => {
        await p.set(
          {
            status: 'Off Hire',
            siteId: null,
            isRetrieved: false,
          },
          { merge: true },
        )
        await deviceHistoryStore.save(this.deviceId)
      })
      /** Remove mapping between Pond and device and make pond as Archive */
      const ponds = await new Collection('ponds', {
        mode: Mode.Off,
        query: ref => ref.where('dispenseDeviceId', '==', this.deviceId),
      }).fetch()

      if (ponds.docs.length) {
        const pondToUpdate = await new PondDoc(`ponds/${ponds.docs[0].id}`).fetch()
        await pondToUpdate.set({ isArchive: true, dispenseDeviceId: null, siteId: null }, { merge: true })
        await pondStore.updateSiteStatus(pondToUpdate.data.siteId, null)
      }
      authStore.isLoading = false
    } catch (e) {
      authStore.isLoading = false
    }
  }

  public markUnallocated = async () => {
    try {
      authStore.isLoading = true
      /** Update device status to Unallocated */
      const initialDoc = new DeviceDoc(() => `devices/${this.deviceId}`, {
        mode: Mode.Off,
      })
      initialDoc.fetch().then(async p => {
        await p.set({ status: 'Unallocated' }, { merge: true })
        await deviceHistoryStore.save(this.deviceId)
      })
      /** Remove mapping between Pond and device */
      const ponds = await new Collection('ponds', {
        mode: Mode.Off,
        query: ref => ref.where('dispenseDeviceId', '==', this.deviceId),
      }).fetch()

      if (ponds.docs.length) {
        const pondToUpdate = await new PondDoc(`ponds/${ponds.docs[0].id}`).fetch()
        await pondToUpdate.set({ dispenseDeviceId: null }, { merge: true })
        await pondStore.updateSiteStatus(pondToUpdate.data.siteId, null)
      }
      authStore.isLoading = false
    } catch (e) {
      authStore.isLoading = false
    }

  }


}
