import React, { FC } from 'react'
import {
  IListItemIconAlarmProps,
  ListItemIconAlarm,
} from '../../alarms/ListItemIconAlarm'
import { PondIcon } from '../../app/Icons'

export const PondAlarmIcon: FC<
  IListItemIconAlarmProps & { className?: string }
> = props => (
  <ListItemIconAlarm {...props}>
    <PondIcon />
  </ListItemIconAlarm>
)
