import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { CompanyStaffEditStore } from './companyStaffEditStore';
import { EditCompanyStaffForm, FormFields } from './EditCompanyStaffForm';

export interface IRouteParams {
  uid?: string
}

export const EditCompanyStaffPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const { uid } = match.params
  const store = useStoreClass(CompanyStaffEditStore, uid)
  const save = async (fields: FormFields) => {
    // console.log(fields)
    await store.save(fields)
    browserHistory.goBack()
  }
  return useObserver(() => (
    <EditPage isLoading={store.isLoading} heading="Staff Member">
      <EditCompanyStaffForm
        isNew={store.isNew}
        initialValues={store.initialValues}
        uid={uid}
        onResult={save}
      />
    </EditPage>
  ))
}
