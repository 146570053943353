import { Placeholder } from '@any-ui/core'
import Box from '@material-ui/core/Box'
import { useObserver } from 'mobx-react-lite'
import SiteStaffPage from './siteStaff'
import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import DashboardPage from '../../dashboard/DashboardPage'
import { EditPondPage } from '../../pond/EditPondPage'
import { EditSitePage } from '../EditSitePage'
import { IPaneParams } from './PaneLayout'
import { ViewersSitePage } from '../ViewersSitePage'
import { ViewEditPondPage } from '../../pond/ViewEditPondPage'
import { EditCompanyStaffPage } from '../../companyStaff/EditCompanyStaffPage'

export const ContentPane: FC<IPaneParams> = ({ store, siteUrl }) =>
  useObserver(() => (
    <Box>
      <Placeholder isLoading={store.isLoading}>
        <Switch>
          <Route
            path={`${siteUrl}/pond/:pondId/chart`}
            component={DashboardPage}
          />
          <Route
            path={`/sites/:siteId/pond/new/edit`}
            component={EditPondPage}
          />
          <Route exact path={'/sites/:siteID/siteStaff'} component={SiteStaffPage} props={siteUrl}/>
          <Route path={'/sites/:siteID/siteStaff/new'} component={EditCompanyStaffPage}/>
          <Route
            path={`/sites/:siteId/pond/:pondId/edit`}
            component={EditPondPage}
          />
          <Route path={`/sites/new`} component={EditSitePage} />
          <Route path={`/sites/:siteId`} component={EditSitePage} />
          <Route
            path={`/viewers/:viewerId/pond/:pondId/edit`}
            component={(rest: any) => <ViewEditPondPage props={rest} siteStore={store!} />}
          />
          <Route
            path={`/viewers`}
            component={() => <ViewersSitePage store={store!} />}
          />
          <Redirect to={siteUrl} />
        </Switch>
      </Placeholder>
    </Box>
  ))
