import { PageContainer } from '@any-ui/core'
import { useTheme } from '@material-ui/styles'
import { auth } from 'firebaseui'
import React, { FC } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import styled, { ThemeProvider } from 'styled-components'
import { LogoLarge } from '../app/LogoLarge'
import { firebase } from '../firebase'

// noinspection CssMissingComma
const Wrapper = styled.div`
  .firebaseui-container {
    background-color: ${p => p.theme.palette.background.paper};
    margin-bottom: 15px;
    min-height: 150px;
    padding-top: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
  .firebaseui-container .firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-container .firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-title,
  .firebaseui-input {
    color: white;
  }
  .firebaseui-subtitle,
  .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .firebaseui-label {
    color: rgba(255, 255, 255, 0.54);
  }
  .firebaseui-input-toggle-on {
    background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_white_24dp.png);
  }
  .firebaseui-input-toggle-off {
    background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_off_white_24dp.png);
  }
  .firebaseui-form-actions
    .mdl-button--raised.mdl-button--colored
    .firebaseui-button {
    background: rgba(0, 0, 0, 0.1);
  }
  .firebaseui-id-dismiss-info-bar {
    display: block;
  }
  .firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
  }
  .mdl-button--raised.mdl-button--colored {
    border-radius: 100px;
    background-color: #E8A621;
  }
  .mdl-button--raised.mdl-button--colored:hover {
    background-color: #F6B637;
  }
  .mdl-textfield.is-focused .mdl-textfield__input:after {
    outline-width: 0;
    border-color: red; !important;
  }
`
export const SignInPage: FC = () => {
  const theme = useTheme()
  return (
    <PageContainer>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <LogoLarge />
          <StyledFirebaseAuth
            uiConfig={{
              credentialHelper: auth.CredentialHelper.NONE,
              signInOptions: [
                {
                  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                  requireDisplayName: true,
                },
              ],
            }}
            firebaseAuth={firebase.auth()}
          />
        </Wrapper>
      </ThemeProvider>
    </PageContainer>
  )
}
