import { DocsView } from '@any-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import { browserHistory } from '@any-ui/core/navigation';
import ListItem from '@material-ui/core/ListItem'
import ListItemButton from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { AccountCircle } from '@material-ui/icons'
import { useObserver } from 'mobx-react-lite'
import React, { useState } from 'react';
import { CompanyStaffDoc } from '../../db/companyStaffDoc'
import {companyStaffStore} from '../companyStaffStore'
import { Notifications, NotificationsOff } from '@material-ui/icons'; 

type companyStaffInactiveListItemProps = {
  siteId: string;
}

export const CompanyStaffInactiveListItem: DocsView<CompanyStaffDoc> = ({ document, siteId }) => {

    const user = document.data
    const [notification, setNotification] = useState(companyStaffStore.notificationOn);
    const handleEnableStaff = () => {
      const uid = document.id;
      companyStaffStore.enableStaff(uid)
    }

    const handleAddStaff = () => {
      const uid = document.id;
      companyStaffStore.addSiteToCompanyStaff(uid, siteId!)
    }

    const handleEditStaff = () => {
      const uid = document.id
      browserHistory.push(`/companystaffs/${uid}/edit`)
    }
    
    const handleNotification = () => {
      const uid = document.id;
      companyStaffStore.toggleNotification(uid, user.isContactPoint);
      setNotification(companyStaffStore.notificationOn);
      
    }

    return  useObserver(() => {
      return (
      <ListItem button>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText 
          primary={user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''}
          secondary={user.email} />
        <IconButton edge="end">
        <ListItemSecondaryAction onClick={handleAddStaff} title="add user to pond">
          <Add />
         </ListItemSecondaryAction> </IconButton>
         <IconButton edge="end">
        <ListItemSecondaryAction onClick={handleNotification} title="Enable Notification">
        { user.isContactPoint ? <Notifications /> : <NotificationsOff />}
         </ListItemSecondaryAction> </IconButton>
      </ListItem>
      )
  })
}