import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { Bullet, CustomerAddress } from '../../app/Icons';
import { authStore } from '../../auth/authStore';
import { customerStore } from '../../customer/customerStore';
import { SiteDoc } from '../../db/siteDoc';

const useStyles = makeStyles({
  location: {
    display: 'flex',
  },
  address: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width:180
  },
  iconfont: {
    fontSize: 10,
    marginTop: 5,
    marginRight: 3,
  },
  inactive: {
    color: '#5D5D5D',
  },
})
export const SiteOwner: FC<{ siteDoc: SiteDoc }> = ({ siteDoc }) => {
  const classes =  useStyles({})

  const renderActive = () => {
    return (
      <Typography
        component={'span'}
        variant={'body2'}
        className={classes.location}
      >
        <Bullet color="secondary" className={classes.iconfont} />{' '}
        <Typography color="secondary" component={'span'} variant={'body2'}>
          Active
        </Typography>
      </Typography>
    )
  }
  const renderInActive = () => {
    return (
      <Typography
        component={'span'}
        variant={'body2'}
        className={classes.location}
      >
        <Bullet
          fontSize="small"
          className={`${classes.iconfont} ${classes.inactive}`}
        />{' '}
        <Typography
          className={classes.inactive}
          component={'span'}
          variant={'body2'}
        >
          Inactive
        </Typography>
      </Typography>
    )
  }
  const renderAddress = () => {
    return (
      <Typography
        variant="caption"
        display="block"
        className={classes.location}
      >
        <CustomerAddress className={classes.iconfont} />
        <Typography
          color="textSecondary"
          className={classes.address}
          variant="caption"
          display="block"
        >
          <Tooltip title={siteDoc.data.address}>
              <span>{siteDoc.data.address}</span>
          </Tooltip>
         
        </Typography>
      </Typography>
    )
  }
  return useObserver(() =>
    authStore.isViewer
      ? null
      : (
          <>
            {authStore.isAdmin && customerStore.ownerName(siteDoc)} <br />
            {renderAddress()}
            {siteDoc.data.isActive ? renderActive() : renderInActive()}
          </>
        ) || <>&nbsp;</>,
  )
}
