import { DocsEmpty, DocsGrid, DocsRootClassKey, DocsSorter } from '@any-ui/core';
import { IDocCollectionProps } from '@any-ui/core/DocsCollection';
import { Button, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC, useState } from 'react';

import { PondDoc } from '../../db/pondDoc';
import { IPondCardProps, PondCard } from '../../pond/components/PondCard';
import { IPaneParams } from './PaneLayout';
import { SiteViewCard } from './SiteViewCard';

const pondCommissionedFilter = (pond: PondDoc) => pond.isCommissioned
const pondDecommissionedFilter = (pond: PondDoc) => pond.isDeCommissioned
const pondNeverCommissionedFilter = (pond: PondDoc) => pond.isNeverCommissioned

const sorter: DocsSorter<PondDoc> = (a, b) =>
  a.displayName.localeCompare(b.displayName)

const useStyles = makeStyles<Theme, {}, DocsRootClassKey>(theme => ({
  defaultView: {},
  divider: {},
  docsCollectionTitle: {
    color: 'rgba(255,255,255,0.15)',
    fontSize: '1rem',
  },
  root: {
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}))

export const NavigationPane: FC<IPaneParams> = ({ store, siteUrl }) => {
  const classes = useStyles({})
  const [showHideDecommissioned, setShowHideDecommissioned] = useState(false)
  return useObserver(() => {
    const pondDocProps: IDocCollectionProps<PondDoc, IPondCardProps> = {
      DefaultView: PondCard,
      ViewProps: { siteUrl, canEdit: store.canEdit, canView: store.canView, siteId: store.siteDoc?.id },
      classes,
      collection: store.ponds,
      sort: sorter,
      wrapper: 'naked',
    }
    return (
      <Box>
        {store.siteDoc && (
          <SiteViewCard store={store} document={{} as any} siteUrl={siteUrl} />
        )}
        {store.canEdit && (
          <DocsGrid<PondDoc, IPondCardProps>
            {...pondDocProps}
            docsTitle="Commissioning"
            filter={pondNeverCommissionedFilter}
          />
        )}
        <DocsGrid<PondDoc, IPondCardProps>
          {...pondDocProps}
          docsTitle="Active"
          filter={pondCommissionedFilter}
        />

        {(store.isDeCommissioned && store.canEdit) && <Button variant="contained" color="primary" onClick={() => {
          setShowHideDecommissioned(!showHideDecommissioned);
        }}>{!showHideDecommissioned ? `See ` : `Hide `}Decommissioned Ponds</Button>}

        {(store.canEdit && showHideDecommissioned) && (
          <DocsGrid<PondDoc, IPondCardProps>
            {...pondDocProps}
            docsTitle="Decommissioned"
            filter={pondDecommissionedFilter}
          />
        )}
        <DocsEmpty>Add your first site to begin</DocsEmpty>
      </Box>
    )
  })
}
