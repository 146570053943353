import { Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

export const useStyles = makeStyles<Theme>({
  root: {
    display: 'block',
  },
})

export interface IPondInfoLineProps extends TypographyProps {
  className?: string
  description: string
}

export const PondInfoLine: FC<IPondInfoLineProps> = ({
  className,
  description,
}) => {
  const classes =  useStyles({})

  return (
    <span
      style={{ display: 'block' }}
      className={clsx(classes.root, className)}
    >
      {description}
    </span>
  )
}
