import {
  ConfirmActions,
  DocsEmpty,
  DocsLayout,
  DocsList,
  PageTitle,
} from '@any-ui/core'
import { Paper } from '@material-ui/core'
import negate from 'lodash/negate'
import { useObserver } from 'mobx-react-lite'

import React, { FC } from 'react'
import { IAdminUsersProps, IUserDoc } from '../store/adminUsersStore'
import { SelectNewView } from './SelectNewView'

const customersFilter = (user: IUserDoc) => user.data.roles.includes('customer')
const selfRegisteredFilter = negate(customersFilter)

export const SelectNewPage: FC<IAdminUsersProps> = ({ store }) =>
  useObserver(() => (
    <DocsLayout collection={store.nonAdminUsers}>
      <PageTitle>Select new admin</PageTitle>
      <Paper>
        <DocsList<IUserDoc>
          wrapper="naked"
          filter={selfRegisteredFilter}
          collection={store.nonAdminUsers}
          DefaultView={SelectNewView}
        />
        <DocsList<IUserDoc>
          wrapper="naked"
          filter={customersFilter}
          docsTitle="Customers"
          collapseHistoryStateKey="nonAdminCustomerListDisabled"
          collection={store.nonAdminUsers}
          DefaultView={SelectNewView}
        />
        <ConfirmActions
          onCancel={store.cancel}
          cancelProps={{
            color: 'primary',
          }}
          confirmProps={{
            hide: true,
          }}
        />
      </Paper>
      <DocsEmpty>no admin users</DocsEmpty>
    </DocsLayout>
  ))
