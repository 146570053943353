import { cons } from 'fp-ts/lib/Array'
import * as _ from 'lodash'
import { matchesProperty } from 'lodash'

export const month_names = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export const generateRandomString = (length = 7) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const getHtmlTemplate = (template: string, templateData: any) => {
  try {
    const compiled = _.template(template)
    return compiled(templateData)
  } catch (error) {
    console.error(error)
    return
  }
}

export const getMonths = () => {
  return [
    { displayName: 'Jan', id: -12 },
    { displayName: 'Feb', id: -1 },
    { displayName: 'Mar', id: -2 },
    { displayName: 'Apr', id: -3 },
    { displayName: 'May', id: -4 },
    { displayName: 'Jun', id: -5 },
    { displayName: 'July', id: -6 },
    { displayName: 'Aug', id: -7 },
    { displayName: 'Sep', id: -8 },
    { displayName: 'Oct', id: -9 },
    { displayName: 'Nov', id: -10 },
    { displayName: 'Dec', id: -11 },
    { displayName: 'Jan', id: 0 },
    { displayName: 'Feb', id: 1 },
    { displayName: 'Mar', id: 2 },
    { displayName: 'Apr', id: 3 },
    { displayName: 'May', id: 4 },
    { displayName: 'Jun', id: 5 },
    { displayName: 'July', id: 6 },
    { displayName: 'Aug', id: 7 },
    { displayName: 'Sep', id: 8 },
    { displayName: 'Oct', id: 9 },
    { displayName: 'Nov', id: 10 },
    { displayName: 'Dec', id: 11 },
  ]
}
export const calculateDays = (startDate: Date, endDate: Date) => {
  let days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
  if (days < 1) {
    days = 0
  } else {
    // days = +days.toString().split('.')[0]
    days = Math.ceil(+days.toFixed(2))
  }
  return days
}
export const getShortDate = (date: Date) => {
  const day = date.getDate()
  const year = date.getFullYear()
  const month_index = date.getMonth()
  return `${day}-${month_names[month_index]}-${year}`
}
export const getDateWithTime = (date: Date) => {
  const getDate = getShortDate(date)
  const getDateTime = `${getDate} ${date.toTimeString().slice(0, 8)}`
  return getDateTime
}
export const getShortMonthYear = (
  month_index: number,
  originalMonthNumber: number = 0,
) => {
  let year = new Date().getFullYear()
  if (originalMonthNumber < 0) {
    year--
  }
  return `${month_names[month_index]} - ${year}`
}
export const daysInMonth = (
  days: number,
  month: number,
  year: number,
  modifiedMonth: Date,
) => {
  const todayDate = new Date()
  const currentMonth = todayDate.getMonth()
  let tdays: number = 0

  // month =  month+1;
  const alldays = new Date(year, month+1, 0).getDate()
  const modifedDays = new Date(
    new Date().getFullYear(),
    modifiedMonth.getMonth()+1,
    0,
  ).getDate()
  // console.log('yr', new Date().getFullYear(), modifiedMonth.getMonth())
  
  if (month === currentMonth) {
    // console.log('===1======')
    //this will check if added device month and current month are equal
    if (days < todayDate.getDate()) {
      tdays = todayDate.getDate() - days
    }
  } else if (month === modifiedMonth.getMonth()) {
    // console.log('===2======')

    // this will check if added device month and selected month from dropdown are same
    if (days < alldays) {
      tdays = alldays - days
    }
  } 
  // else if (currentMonth === modifiedMonth.getMonth()) {
  //   console.log('===13=====', currentMonth, )

  //   //this will check if current month and month from dropdown are same
  //   tdays = todayDate.getDate()
  // } 
  else {
    // console.log('====4=====', modifedDays)
    //else take all the days of selected from dropdown
    tdays = modifedDays;
  }
  // console.log('ppp', tdays)

  return tdays
}

export const calulateAddedMonth = (date: Date) => {
  let data: number
  if (date.getFullYear() === 2020) {
    data = -Math.abs(date.getMonth())
  } else {
    data = date.getMonth()
  }
  return data
}


export  const  getyear = (month: number) =>{
  // console.log('=====month===', month, Math.sign(month))
  let yr : Number;
  if(month === -12){
    yr = 2020;
  }else if(Math.sign(month) === -1){
    yr = 2020
  }else {
    yr = 2021
  }
return  yr;

}