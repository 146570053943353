import { useConfirm } from '@any-ui/core';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { deviceStore } from '../../device/deviceStore';

const useStyles = makeStyles<Theme>(theme => ({
  label: {
    fontSize: 10,
  },
}))
interface IDeviceAssginedProps {
  deviceId: any
}

export const DeviceRetriev: FC<IDeviceAssginedProps> = ({
  deviceId,
  ...props
}) => {
  const classes =  useStyles({});
 
  // const store = useStoreClass(DeviceRequestEditStore, assignedDevice);
  const onAssginedClick = useConfirm({
    message: 'Are you sure you want to confirm that this device is retrieved back?',

    onCancel: () => {},
    onConfirm: async () => {
      await deviceStore.updateDeviceRetrival(deviceId)
    },
    title: `Device(s) Retrieval`,
  });
 
  return useObserver(() => {
    return (
      <Button
        variant="contained"
        color="primary"
        classes={{ label: classes.label }}
        onClick={onAssginedClick}
      >
        Device Retrieved
      </Button>
    )
  })
}
