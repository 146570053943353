import { IColl } from '@any-ui/core/docs';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { AlarmDoc } from '../../alarms/alarmDoc';
import { greatestAlarmSeverity } from '../../alarms/alarmsStore';
import { IListItemIconAlarmProps, ListItemIconAlarm } from '../../alarms/ListItemIconAlarm';
import { SiteIcon } from '../../app/Icons';
import { authStore } from '../../auth/authStore';

interface IProps extends Partial<IListItemIconAlarmProps> {
  alarms: IColl<AlarmDoc>
  className?: string
}

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    color: "#448AFF",//theme.palette.text.secondary,
    // tslint:disable-next-line:object-literal-sort-keys
    '.decommissioned &': {
      color: 'rgba(255,255,255,0.25)',
      // color: theme.palette.text.disabled,
    },
    fontSize: '2rem',
    minWidth: 32,
    width: 32,
  },
}))

export const SiteAlarmIcon: FC<IProps> = ({ alarms, ...rest }) => {
  const classes =  useStyles({})
  return useObserver(() => (
    <ListItemIconAlarm
      color="primary"
      variant="data"
      data={!authStore.isViewer ? alarms.docs.length : 0}
      active={!!alarms.docs.length}
      severity={greatestAlarmSeverity(alarms.docs)}
      {...rest}
    >
      <SiteIcon classes={{ root: classes.icon }} />
    </ListItemIconAlarm>
  ))
}
