import { IDoc } from '@any-ui/core'
import { distanceInWords, isAfter, subDays } from 'date-fns'
import { computed } from 'mobx'
import { PondDoc } from '../db/pondDoc'
import { Timestamp } from '../firestore'

import {
  AlarmKey,
  AlarmKeyMapSeverityMapDescription,
  IAlarm,
  Severity,
} from '../model/alarm'
import { alarmMetric, pondFullName } from '../model/pond'

export const sortAlarms = (a: AlarmDoc, b: AlarmDoc) => {
  if (a.severity === b.severity) {
    return 0
  }
  if (a.severity === 'CRITICAL') {
    return -1
  }
  if (a.severity === 'NONE') {
    return 1
  }
  if (b.severity === 'CRITICAL') {
    return 1
  }
  if (b.severity === 'NONE') {
    return -1
  }
  return 0
}

const fillerAlarm = (): IAlarm => ({
  severity: 'NONE',
  timestamp: Timestamp.fromDate(new Date()),
})

export class AlarmDoc implements IDoc {
  public readonly id: string
  public readonly alarmKey: AlarmKey

  @computed
  get pondName(): string {
    return pondFullName(this.pond.data)
  }

  @computed
  get description(): string {
    const label =
      AlarmKeyMapSeverityMapDescription[this.alarmKey][this.severity]
    if (this.metric == null) {
      return label
    }
    return `${label}: ${this.metric}`
  }

  @computed
  get metric(): string | null {
    return alarmMetric(this.pond.data, this.alarmKey)
  }

  @computed
  get displayTimestamp(): string {
    return `${distanceInWords(this.timestamp, new Date())} ago`
  }

  @computed
  get active(): boolean {
    return this.severity !== 'NONE'
  }

  @computed
  get recentlyResolved(): boolean {
    return !this.active && isAfter(this.timestamp, subDays(new Date(), 1))
  }

  @computed
  get alarm(): IAlarm {
    return this.pond.alarms[this.alarmKey] || fillerAlarm()
  }

  @computed
  get severity(): Severity {
    return this.alarm.severity
  }

  @computed
  get timestamp(): Date {
    return this.alarm.timestamp.toDate()
  }

  private readonly pond: PondDoc

  constructor(alarmKey: AlarmKey, pond: PondDoc) {
    this.alarmKey = alarmKey
    this.pond = pond
    this.id = `${pond.id}_${alarmKey}`
  }

  public navigateSiteDash = () => {
    this.pond.navigateSiteDashboard()()
  }
}
