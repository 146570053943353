import { createStyles, FormControl, InputBase, makeStyles, MenuItem, Select, Theme, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

export interface ISearchProps {
  handleChange: any
  placeholder?: string
  value?: string
  items?: any
  className?:string
}
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,

      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    select: {
      height: 40,
      maxWidth: 150,
    },
  }),
)
export const DropDown: FC<ISearchProps> = ({
  handleChange,
  placeholder,
  value,
  items,
  className
}) => {
  const classes =  useStyles({})
  return (
    <FormControl>
      <Select
        value={value}
        className={`${classes.select} ${className}`}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {placeholder && <MenuItem value="-1">{placeholder}</MenuItem>}
        {items &&
          items.map((p: any) => {
            return (
              <MenuItem value={p.id} key={p.id}>
                {p.displayName}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}
