import { HttpsCallableCollAdapter } from '@any-ui/core'
import { browserHistory } from '@any-ui/core/navigation'
import { apiCall } from '../../firebase'
import {
  IUser,
  ListUsersWithoutRoleData,
  UpdateUserRoleData,
} from '../../model/user'
import { AdminUsersStore, IUserDoc } from './adminUsersStore'

const listUsersWithoutRole = apiCall<IUser[]>(
  'listUsersWithoutRole',
  ListUsersWithoutRoleData,
)
const updateUserRole = apiCall('updateUserRole', UpdateUserRoleData)

export class NonAdminUsersCollection extends HttpsCallableCollAdapter<
  IUserDoc
> {
  private readonly adminStore: AdminUsersStore

  constructor(adminStore: AdminUsersStore) {
    super(
      () => listUsersWithoutRole({ role: 'admin' }),
      data => ({
        data,
        handleSelect: async () => {
          browserHistory.goBack()
          await updateUserRole({ uid: data.uid, role: 'admin' })
          this.adminStore.reload()
        },
        id: data.uid,
      }),
    )
    this.adminStore = adminStore
  }
}
