import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { DeviceRequestListPage } from '../deviceRequest/DeviceRequestListPage';
import { EditDeviceRequestPage } from '../deviceRequest/EditDeviceRequestPage';
import { DeviceRetrievalListPage } from '../deviceRetrieval/DeviceRetrievalListPage';
import { DeviceListPage } from './DeviceListPage';
import { EditDevicePage } from './EditDevicePage';

export const DeviceRoutes: FC<RouteComponentProps> = () => (
  <Switch>
    <Route path="/devices" exact component={DeviceListPage} />
    <Route path="/devices/:deviceId/edit" component={EditDevicePage} />
    <Route path="/devices/new" component={EditDevicePage} />
    <Route path="/devices/request/new" exact component={EditDeviceRequestPage} />
    <Route path="/devices/request" exact component={DeviceRequestListPage} />
    <Route path="/devices/retrieve"  component={DeviceRetrievalListPage} />
    <Route path="/devices/request/:deviceId/edit" exact component={EditDeviceRequestPage} />
    <Redirect to="/" />
  </Switch>
)
