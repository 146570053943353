import { AppProviders, PageContainer, PageError, PageLoading } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { authStore } from '../auth/authStore';
import { SignInPage } from '../auth/SignInPage';
import { TermsConditionPage } from '../termsAndConditions/TermsConditionPage';
import { termsConditionsStore } from '../termsAndConditions/termsConditionsStore';
import { AdminRoot } from './AdminRoot';
import { CompanyStaffRoot } from './CompanyStaffRoot';
import { CustomerRoot } from './CustomerRoot';
import { themeOptions } from './style';
import { ViewerRoot } from './ViewerRoot';


const AuthPath: FC = () =>
  useObserver(() =>
    authStore.isLoading ? (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    ) : authStore.error ? (
      <PageContainer>
        <PageError error={authStore.error} />
      </PageContainer>
    ) : termsConditionsStore.isTermCondition && !termsConditionsStore.termsAndConditions.isLoading && authStore.isAuthenticated && !authStore.isViewer ? (
      <PageContainer>
        <TermsConditionPage />
      </PageContainer>
    ) : authStore.isAdmin ? (
      <AdminRoot />
    ) : (authStore.isCustomer) ? (
      <CustomerRoot />
    ) : authStore.isViewer ? (
      <ViewerRoot />
    ) : ( authStore.isCompanyStaff || authStore.isSignedUp) ? (
      <CompanyStaffRoot />
    ) : (
                    <SignInPage />
                  ),
  )

export const App: FC = () => (
  <AppProviders themeOptions={themeOptions}>
    <AuthPath />
  </AppProviders>
)
