import { PageContainer } from '@any-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { AlarmsListPage } from '../alarms/AlarmsListPage';
import { authStore } from '../auth/authStore';
import { NoRolePage } from '../auth/NoRolePage';
import { CompanyStaffRoutes } from '../companyStaff/CompanyStaffRoutes';
import { CustomerRoutes } from '../customer/CustomerRoutes';
import { DeviceHistoryPage } from '../device/DeviceHistoryPage';
import { DeviceRoutes } from '../device/DeviceRoutes';
import { ScanQrCodePage } from '../site/ScanQrCodePage';
import { SiteListPage } from '../site/SiteListPage';
import { SiteRoutes } from '../site/SiteRoutes';
import { ViewerSiteViewPage } from '../site/ViewerSiteViewPage';
import { SignOutRoute } from '../tools/SignOut';
import { EditProfilePage } from '../userProfile/EditProfilePage';
import { AdminRoutes } from '../usersAdmin/AdminRoutes';
import { InvoicePage } from './invoice/InvoicePage';

export const TopRoutes: FC = () =>
  useObserver(() => (
    <PageContainer>
      <Switch>
        <Route
          exact
          path="/"
          component={authStore.isAuthenticated ? SiteListPage : NoRolePage}
        />
        <Route path="/profile" component={EditProfilePage} />
        <Route path="/signout" component={SignOutRoute} />
        {authStore.isAuthenticated && (
          <Route path="/sites" component={SiteRoutes} />
        )}
        {authStore.isAuthenticated && (
          <Route exact path="/alarms" component={AlarmsListPage} />
        )}
        {authStore.isAdmin && (
          <Route path="/customers" component={CustomerRoutes} />
        )}
        {(authStore.isAdmin || authStore.isCustomer) && (
          <Route path="/devices" component={DeviceRoutes} />
        )}
        {authStore.isAdmin && (
          <Route path="/admin" component={AdminRoutes} />
        )}
        {authStore.isCustomer && (
          <Route path="/companystaffs" component={CompanyStaffRoutes} />
        )}
        {authStore.isAdmin && (
          <>
            <Route path="/invoice" component={InvoicePage} />
            <Route path="/devicehistory" component={DeviceHistoryPage} />
          </>
        )}
       
        <Route path="/viewers/:viewerId" component={ViewerSiteViewPage} />
        <Route path="/scandevice" component={ScanQrCodePage} />
        <Redirect to="/" />
      </Switch>
    </PageContainer>
  ))
