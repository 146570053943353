import { DocsLayout, DocsList, PageAction, PageTitle } from '@any-ui/core';
import { Grid, Theme, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { CompanyStaffDoc } from '../db/companyStaffDoc';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { companyStaffStore } from './companyStaffStore';
import { CompanyStaffListItem } from './components/CompanyStaffListItem';
import { CompanyStaffListTitle } from './components/CompanyStaffListTitle';

const useStyles = makeStyles<Theme, {}, any>({
  defaultView: {},
  divider: {
    opacity: 0.25,
  },
  docsCollectionTitle: {},
  root: {},
  typographyRoot: {
    color: 'rgb(255, 255, 255, 0.7)',
    textAlign: 'center',
  },
  custgrid: {
    textAlign: 'right',
  },
})

const sortData = [
  { id: '1', displayName: 'A - Z' },
  { id: '2', displayName: 'Z - A' },
  { id: '3', displayName: 'Email A - Z' },
  { id: '4', displayName: 'Email Z - A' },
]

export const CompanyStaffListPage: FC = () => {
  const classes =  useStyles({})
  const [selectedSort, setCmpStaffSort] = React.useState('-1')
  const [searchText, setSearchText] = React.useState('')
  const onSortCmpStaff = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setCmpStaffSort(value)
    switch (value) {
      case '1':
        companyStaffStore.sortCompanyStaff('firstName', 'asc')
        break
      case '2':
        companyStaffStore.sortCompanyStaff('firstName', 'desc')
        break
      case '3':
        companyStaffStore.sortCompanyStaff('email', 'asc')
        break
      case '4':
        companyStaffStore.sortCompanyStaff('email', 'desc')
        break
      case '-1':
        companyStaffStore.sortCompanyStaff('all', 'desc')
        break
    }
    //deviceStore.deviceFilter(value, selectedCustomer)
  }
  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }
  const searchCompanyStaffs = (doc: CompanyStaffDoc) => {
    if (searchText) {
      const searchContent = searchText.toLowerCase();
      return (
        (doc.data.firstName && doc.data.firstName.toLowerCase().indexOf(searchContent) !== -1)
        ||
        doc.data.email.toLowerCase().indexOf(searchContent) !== -1
      )
    }
    return true
  }
  return useObserver(() => (
    <DocsLayout collection={companyStaffStore.companyStaffs} pageWidth="800px">
      <PageTitle>Company Staff</PageTitle>
      <PageAction
        Icon={Add}
        label="Add Staff Member"
        onClick={CompanyStaffDoc.navigateNew}
      />
      <Grid container spacing={3}>
        <Grid item sm={7}>
          <Search onTextChanged={onSearch}></Search>
        </Grid>

        <Grid item sm={2} className={classes.custgrid}></Grid>

        <Grid item sm={3} className={classes.custgrid}>
          <DropDown
            value={selectedSort}
            handleChange={onSortCmpStaff}
            placeholder={'Creation Date'}
            items={sortData}
          ></DropDown>
        </Grid>
        <Grid item sm={12}>
          <DocsList<CompanyStaffDoc>
            collection={companyStaffStore.companyStaffs}
            DefaultView={CompanyStaffListItem}
            preItems={<CompanyStaffListTitle />}
            filter={searchCompanyStaffs}
            classes={classes}
          />
        </Grid>
        <Grid item sm={12}>
          {!companyStaffStore.companyStaffs.hasDocs ? (
            <Typography
              variant="h6"
              component="h6"
              className={classes.typographyRoot}
            >
              Add the first device to begin
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </DocsLayout>
  ))
}
