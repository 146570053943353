import { Collection, Mode } from 'firestorter'
import { action, computed, observable } from 'mobx'

import { authStore } from '../../auth/authStore'
import { customerStore } from '../../customer/customerStore'
import { CompanyStaffDoc } from '../../db/companyStaffDoc'
import { DeviceDoc } from '../../db/deviceDoc'
import { PondDoc } from '../../db/pondDoc'
import { SiteDoc } from '../../db/siteDoc'
import { narrowByOwnershipOrViewerSite } from '../../db/visibility'
import { deviceStore } from '../../device/deviceStore'
import { FieldPath } from '../../firestore'
import { pondStore } from '../../pond/pondStore'
import { CsvDataService } from '../../tools/CsvDataService'
import { DeviceStatus } from '../../tools/DeviceStatus'
import {
  calculateDays,
  daysInMonth,
  getShortDate,
  calulateAddedMonth,
} from '../../util/commonFunctions'
import { format } from 'date-fns'
import { add } from 'lodash'

// import { OffHiredDeviceDoc } from '../../db/offHiredDeviceDoc.ts'

export interface InvoiceData {
  sites: Array<SiteDoc>
  downloadData: Array<any>
  pondData: Collection<PondDoc>
  devicesData: Collection<DeviceDoc>
  customerName: string | undefined
  email: string | undefined
  isInvoice: boolean
  customerId: string
  siteId?: string | undefined
  invoiceMonth?: number
}
class SitesStore {
  public sites = new Collection<SiteDoc>('sites', {
    createDocument: (...args) => new SiteDoc(...args),
    mode: Mode.On,
    query: ref =>
      narrowByOwnershipOrViewerSite(
        ref.where('isArchive', '==', false),
        FieldPath.documentId(),
      ),
  })

  @observable
  public customerSites: Array<SiteDoc> = []
  @observable
  public showHideArchiveSite: boolean = false

  @computed
  get isLoading(): boolean {
    return this.sites.isLoading
  }

  public byId(siteId: string): SiteDoc | undefined {
    const result: any = this.sites.docs.find(site => site.id === siteId)
    return result
  }

  public siteName(uid: string): string | undefined {
    const doc = this.byId(uid)
    return doc && doc.displayName
  }
  public sitesNamesByIds(ids: Array<string>) {
    const result: any = this.sites.docs
      .filter(p => {
        return ids.some(q => q === p.id)
      })
      .map(p => p.displayName)
      .join(',')
    return result
  }
  public sitesByCustomer(ownerUid: string | undefined | null) {
    return this.sites.docs.filter(p => p.data.ownerUid === ownerUid)
  }

  @action
  public setSelectedCustomerId = (id: string) => {
    this.customerSites = this.sites.docs.filter(p => p.data.ownerUid === id)
  }
  @action
  public showHideArchivSites = () => {
    this.sites = new Collection<SiteDoc>('sites', {
      createDocument: (...args) => new SiteDoc(...args),
      mode: Mode.On,
      query: ref =>
        narrowByOwnershipOrViewerSite(
          ref.where('isArchive', '==', this.showHideArchiveSite),
          FieldPath.documentId(),
        ),
    })
  }
  /**
   * List Company staff user based on ownerId to show in
   * dropdown to assign the Company staff to site
   */
  public getCustomersStaff = async (ownerId: string) => {
    const companyStaffUsers = await new Collection('companyStaffs', {
      mode: Mode.Off,
      query: ref => ref.where('ownerUid', '==', ownerId),
    }).fetch()
    return companyStaffUsers
  }

  /**
   * Assigns Company staff to site
   */
  public assignSiteToCustomersStaff = (
    siteId: string,
    companyStaff: Array<string>,
  ) => {
    companyStaff.map(async c => {
      const companyStaffUser = await new CompanyStaffDoc(`companyStaffs/${c}`, {
        mode: Mode.Off,
      }).fetch()
      const siteIds =
        companyStaffUser.data.siteIds && companyStaffUser.data.siteIds.length
          ? companyStaffUser.data.siteIds
          : []
      if (siteIds.indexOf(siteId) === -1) {
        siteIds.push(siteId)
        await companyStaffUser.set({ siteIds }, { merge: true })
        return true
      } else {
        return false
      }
    })
  }

  /**
   * @TODO isArchive filter is not implemented yet
   * @param field name, status
   * @param sort ase, desc
   */
  // public sortSites = async (field: string, sort: any) => {
  //   const sortedSites = new Collection('sites', {
  //     mode: Mode.On,
  //     query: ref => ref.orderBy(field, sort),
  //   }).fetch()
  //   // this.sites = sortedSites
  //   return sortedSites || null
  // }

  public sortSites(field: string, sort: any, ownerId: string = '') {
    if (ownerId === '-1') {
      this.sites = new Collection<SiteDoc>('sites', {
        createDocument: (...args) => new SiteDoc(...args),
        mode: Mode.On,
        query: ref =>
          narrowByOwnershipOrViewerSite(
            ref.where('isArchive', '==', false).orderBy(field, sort),
          ),
      })
    } else {
      this.sitesFilter(field, sort, ownerId)
    }
  }
  public sitesFilter = async (
    field: string,
    sort: any,
    ownerId: string = '',
  ) => {
    if (ownerId !== '-1') {
      this.sites = new Collection<SiteDoc>('sites', {
        createDocument: (...args) => new SiteDoc(...args),
        mode: Mode.On,
        query: ref =>
          narrowByOwnershipOrViewerSite(
            ref
              .where('isArchive', '==', false)
              .where('ownerUid', '==', ownerId)
              .orderBy(field, sort),
          ),
      })
    } else {
      this.sortSites(field, sort, ownerId)
    }
  }

  /**
   * List Sites on companystaff login
   */
  public async siteForCompanyStaff() {
    if (authStore.isCompanyStaff) {
      this.sites = new Collection<SiteDoc>('sites', {
        createDocument: (...args) => new SiteDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref.where('companyStaffIds', 'array-contains', authStore.uid),
      })
    }
  }
  public async downloadCsv() {
    authStore.isLoading = true
    const customerData = customerStore.customers.docs
    const pondData = pondStore.ponds

    if (!deviceStore.devices.hasDocs) {
      await deviceStore.getDevicesOnDemand()
    }
    const devicesData = deviceStore.devices
    const sitesData = sitesStore.sites
    const downloadData: any[] = []
    devicesData.docs.forEach(device => {
      if (device.data.status !== DeviceStatus.OFFHIRE) {
        const customer = customerData.filter(
          p => p.id === device.data.ownerUid,
        )[0]
        const addedDate = new Date(device.data.addedTimestamp.toDate())
        const todayDate = new Date()
        const days = calculateDays(addedDate, todayDate)
        // const site = sitesData.docs.find(p => p.data.ownerUid === customer.id && p.id === device.data.siteId)
        // const pond = site && pondData.docs.find(p => p.data.siteId === site.id && p.data.dispenseDeviceId === device.id)
        const pond: any = pondData.docs.find(
          p => p.data.dispenseDeviceId === device.id,
        )
        const site =
          pond &&
          sitesData.docs.find(
            p =>
              p.data.ownerUid === device.data.ownerUid &&
              p.id === pond.data.siteId,
          )
        let data: any = {
          CustomerName: customer.displayName,
          Site: site && site.data.name,
          Pond: pond && pond.data.name,
          Device: device && device.data.serial,
          DeviceAdded: getShortDate(addedDate),
          NoOfDays: days,
        }
        downloadData.push(data)
      } else {
        // const addedDate = new Date(device.data.addedTimestamp.toDate())
        let data: any = {
          CustomerName: '',
          Site: '',
          Pond: '',
          Device: device.data.serial,
          DeviceAdded: '', //getShortDate(addedDate),
          NoOfDays: 0,
        }
        downloadData.push(data)
      }
    })
    downloadData.sort((a, b) => {
      if (a.CustomerName < b.CustomerName) {
        return -1
      }
      if (a.CustomerName > b.CustomerName) {
        return 1
      }
      return 0
    })
    const CustomerData = downloadData.filter(p => p.CustomerName)
    const endHireDevice = downloadData.filter(p => !p.CustomerName)
    CustomerData.push(...endHireDevice)

    CsvDataService.exportToCsv(
      `Reports_ ${new Date().toLocaleDateString()}.csv`,
      CustomerData,
    )
    authStore.isLoading = false
  }
  public async generateInvoice(
    customerId: string,
    siteId: string,
    invoiceMonth: Date,
  ) {
    const sitesData = sitesStore.sites.docs
    const pondData = pondStore.ponds
    // if (!deviceStore.devices.hasDocs) {
    //   await deviceStore.getDevicesOnDemand();
    // }
    const devicesData: any = await deviceStore.addOffHiredDevices(
      customerId,
      invoiceMonth,
    )
    const downloadData: any[] = []
    let sites
    if (siteId !== '-1') {
      sites = sitesData.filter(
        p => p.id === siteId && p.data.ownerUid === customerId,
      )
    } else {
      sites = sitesData.filter(p => p.data.ownerUid === customerId)
    }
    const customerName = customerStore.customers.docs.find(
      p => p.id === customerId,
    )
    const invMonth = invoiceMonth ? +invoiceMonth : 0
    const args: InvoiceData = {
      sites,
      downloadData,
      pondData,
      devicesData,
      customerName: customerName && customerName.data.contactName,
      email: customerName && customerName.data.email,
      isInvoice: true,
      customerId,
      siteId,
      invoiceMonth: invMonth,
    }
    sitesStore.preparedData(args)
    return downloadData
  }

  public preparedData(args: InvoiceData) {
    const {
      sites,
      downloadData,
      pondData,
      devicesData,
      customerName,
      email,
      isInvoice,
      siteId,
      invoiceMonth = 0,
    } = args
    const modifiedMonth = new Date(invoiceMonth)
    // console.log('modified month', modifiedMonth)
    // const modifiedMonth = invoiceMonth === -12 ? 0 : invoiceMonth
    // let modifiedFullDate = new Date(
    //   new Date().getFullYear(),
    //   Math.abs(modifiedMonth),
    //   0,
    // )
    // if (modifiedMonth < 0) {
    //   modifiedFullDate = new Date(
    //     new Date().getFullYear(),
    //     Math.abs(modifiedMonth),
    //     0,
    //   )
    // }
    // const modifiedMonth = Math.abs(invoiceMonth === -12 ? 0 : invoiceMonth)
    devicesData.docs.forEach(device => {
      // console.log(device.data.status,device.data.serial, new Date(device.data.addedTimestamp.toDate()))
      // console.log(device.data.serial, device.data.status, new Date(device.data.addedTimestamp.toDate()))
     
      // if (device.data.ownerUid && device.data.ownerUid === customerId) {

      //const site = sites.find(p => p.data.ownerUid === device.data.ownerUid && p.id === device.data.siteId);

      // const pond = pondData.docs.find(p => p.data.dispenseDeviceId === device.id)
      const pond: any = pondData.docs.find(
        p => p.data.dispenseDeviceId === device.id,
      )
      const site =
        pond &&
        sites.find(
          p =>
            p.data.ownerUid === device.data.ownerUid &&
            p.id === pond.data.siteId,
        )

      let bill = '0'
      let days = 0
      let addedDate
      // const addedDateMonth = calulateAddedMonth(
      //   new Date(device.data.addedTimestamp.toDate()),
      // )
      // addedDate = new Date(device.data.addedTimestamp.toDate())
       addedDate = new Date(device.data.addedTimestamp.toDate())
       const addedDateMonth = new Date(addedDate)
      // console.log('added', new Date(addedDate)>new Date(modifiedMonth))

          // addedDate.setDate(addedDate.getDate()-1)
      const status = device.data.status
      const retrivalDate = device.data.retrivalTimestamp
        ? new Date(device.data.retrivalTimestamp.toDate())
        : ''

      //const retrivalDate = device.data.retrivalTimestamp ? new Date(device.data.retrivalTimestamp.toDate()) : ''
      //  if(retrivalDate)   retrivalDate.setDate(retrivalDate.getDate()-1)
      const todayDate = new Date()
      // if (invoiceMonth < 0) {
      //   todayDate.setFullYear(todayDate.getFullYear() - 1)
      // }
      const addedTimestamp = new Date(addedDate).getTime()/1000
      // console.log('serial', device.data.serial,addedDate)
      if (isInvoice) {
        // console.log('test1', status,new Date(addedDate).getTime(),addedDate)
        //console.log(addedTimestamp, invoiceMonth)
  // console.log(device.data.serial, addedDate, addedTimestamp >= invoiceMonth/1000)
        if (
          (status === 'Allocated' || status === 'Unallocated') &&
          // Math.sign(modifiedMonth) !== -1 &&
          addedTimestamp <= invoiceMonth/1000
        ) {
          days = daysInMonth(
            addedDate.getDate(),
            addedDate.getMonth(),
            addedDate.getFullYear(),
            modifiedMonth,
          )
          // days = days ? days - 1 : 0;
        } else if (
          (status === 'Allocated' || status === 'Unallocated') &&
          // Math.sign(modifiedMonth) === -1 &&
          addedTimestamp >= invoiceMonth/1000 &&
          addedDate.getFullYear() === modifiedMonth.getFullYear()
          && addedDate.getMonth() <= modifiedMonth.getMonth()
        ) {
          days = daysInMonth(
            addedDate.getDate(),
            addedDate.getMonth(),
            addedDate.getFullYear(),
            modifiedMonth,
          )
          // days = days ? days - 1 : 0
        } else if(status === 'Off Hire' && retrivalDate){ 
          // console.log(device.data.serial, added)
         
          days = retrivalDate.getDate();
          // console.log('days', days, device.data.serial, retrivalDate, addedDate)
          const addeddays = addedDate.getDate()
          const retrivedays = retrivalDate.getDate()
          if(addedDate.getMonth() === retrivalDate.getMonth()) {
           
            if(addeddays < retrivedays){
              days = retrivedays- addeddays
            }else{
              days = 0;
            }          

          }     
          // console.log('before days', retrivalDate.getDate(), retrivalDate)
          else if(modifiedMonth.getMonth() < retrivalDate.getMonth()){ 
          
            const nowdays= new Date(
              new Date().getFullYear() - 1,
              Math.abs(invoiceMonth) + 1,
              0,
            ).getDate()
            days = nowdays- addeddays;
          }         
          // console.log('days', days);
        
        } 
         else {
          return
        }

        if (device && device.data.deviceRate && isInvoice) {
          bill = (+device.data.deviceRate * days).toFixed(2)
        }
      } else {
        days = calculateDays(addedDate, todayDate)
      }

      // console.log('days', days)

      let data: any = {
        CustomerName: customerName,
        Email: email,
        Site: site && site.data.name,
        SiteAddress: site && site.data.address,
        Pond: pond && pond.data.name,
        Device: device.data.serial,
      }
      if (isInvoice) {
        data.Amount = bill
        data.DeviceAdded = getShortDate(addedDate)
      }
      if (addedDate && !isInvoice) {
        data.DeviceAdded = getShortDate(addedDate)
        data.NoOfDays = days
      } else {
        data.NoOfDays = days
      }
      if (siteId !== '-1') {
        if (site) {
          downloadData.push(data)
        }
      } else {
        downloadData.push(data)
      }

      // }
    })
    downloadData.sort((a, b) => {
      if (a.Site < b.Site) {
        return -1
      }
      if (a.Site > b.Site) {
        return 1
      }
      return 0
    })
  }
  public async downloadSiteCsv(data: any) {
    authStore.isLoading = true
    const finalData: any[] = []

    data.items.map((item: any) => {
      const finalItem = {
        Date: format(item.timestamp, 'DD-MM-YYYY'),
        ...item,
      }
      delete finalItem.timestamp
      finalData.push(finalItem)
    })

    CsvDataService.exportToCsv(
      `${data.pondName}_Logs_${new Date().toLocaleDateString()}.csv`,
      finalData,
    )
    authStore.isLoading = false
  }
}
export const sitesStore = new SitesStore()
