import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

// tslint:disable:object-literal-sort-keys
// @ts-ignore
const useStyles = makeStyles<Theme>(theme => ({
  customerName: {},
  noOfDevice: {
    textAlign: 'center',
  },
  deliveryInstructions: {},
  root: {
    display: 'grid',
    textAlign: 'left',
    gridTemplateColumns: '1fr 1fr 2fr 2fr 1fr'
  },
  notDeployed: {
    color: theme.palette.text.disabled,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}))

interface IDeviceListItemBaseProps extends Partial<ListItemProps> {
  noOfDevice: string
  customerName?: string
  deliveryAddress?: string
  deliveryInstructions?: string
  action?: any
}

export const DeviceRequestListItemBase: FC<IDeviceListItemBaseProps> = ({
  noOfDevice,
  customerName,
  deliveryAddress,
  deliveryInstructions,
  action,
  className,
  ...rest
}) => {
  const classes = useStyles({})
  return useObserver(() => (
    <ListItem
      // @ts-ignore
      button
      classes={{
        root: clsx(classes.root),
      }}
      className={clsx('DeviceRequestListItemBase', className)}
      {...rest}
    >
      <Box className={clsx(classes.customerName)}>{customerName}</Box>
      <Box className={clsx(classes.noOfDevice)}>{noOfDevice}</Box>
      <Box className={clsx(classes.deliveryAddress)}>{deliveryAddress}</Box>
      <Box className={clsx(classes.deliveryInstructions)}>
        {deliveryInstructions}
      </Box>
      <Box className={clsx(classes.deliveryInstructions)}>{action}</Box>
    </ListItem>
  ))
}
