import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { FormFields, ViewEditPondForm } from './ViewEditPondForm';
import { PondEditStore } from './pondEditStore';

import { SiteViewStore } from '../site/store/siteViewStore';

export interface IRouteParams {
  pondId?: string
  siteId: string
}

export const ViewEditPondPage: FC<{ props: RouteComponentProps<IRouteParams>, siteStore: SiteViewStore }> = ({
  props,
  siteStore
}) => {
  const { pondId } = props?.match?.params

  let store: PondEditStore;

  store = useStoreClass(PondEditStore, siteStore?.siteDoc?.id || '', pondId)

  const save = async (fields: FormFields) => {
    // no permission
  }

  const cancel = () => {
    return browserHistory.goBack()
  }

  return useObserver(() => (
    <EditPage
      isLoading={store.isLoading}
      heading={store.isNew ? 'New Pond' : 'Edit Pond'}
    >
      <ViewEditPondForm
        isNew={store.isNew}
        initialValues={store.initialValues}
        store={store}
        onResult={save}
        onCancel={cancel}
        cancelLabel='Back'
      />
    </EditPage>
  ))
}
