import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { CustomerListPage } from './CustomerListPage';
import { EditCustomerPage } from './EditCustomerPage';

export const CustomerRoutes: FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/customers" component={CustomerListPage} />
    <Route path="/customers/:uid/edit" component={EditCustomerPage} />
    <Route exact path="/customers/new/" component={EditCustomerPage}  />
    <Route exact path="/customers/new/:email/:contactName" component={EditCustomerPage} />
    <Redirect to="/customers" />
  </Switch>
)
