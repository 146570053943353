import { ListItemProps } from '@material-ui/core/ListItem'
import useTheme from '@material-ui/core/styles/useTheme'
import { createStyles, makeStyles } from '@material-ui/styles'
import { useObserver } from 'mobx-react-lite'
import React, { FC } from 'react'
import { AlarmDoc } from '../../alarms/alarmDoc'
import { alarmColorText } from '../../alarms/AlarmStyles'
import { PondInfoLine } from './PondInfoLine'

export interface IProps extends ListItemProps {
  alarm: AlarmDoc
}

const useStyles = makeStyles(
  createStyles({
    root: alarmColorText,
  }),
)

export const PondAlarmLine: FC<IProps> = ({ alarm }) => {
  const theme = useTheme()
  const classes = useStyles({ theme, severity: alarm.severity })
  return useObserver(() => (
    <PondInfoLine description={alarm.description} className={classes.root} />
  ))
}
