import { Button, Checkbox, FormControlLabel, Grid, TextField, Theme, Typography,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC, useState } from 'react';

import { termsConditionsStore } from './termsConditionsStore';

const useStyles = makeStyles<Theme, {}, any>(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    container: {
        justifyContent: 'center',
    },
    title: {
        color: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
        fontFamily: 'Montserrat, Biryani, Arial Black, sans-serif',
        fontSize: 34,
    },
    notice:{
        color: 'red',
        textAlign: 'center',
        fontFamily: 'Montserrat, Biryani, Arial Black, sans-serif',
        fontSize: 15,
    },
    term: {
        marginLeft: 50,
        marginRight: 50,
        maxHeight: window.innerHeight - 300,
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxHeight: window.innerHeight - 280,
            marginLeft: 100,
            marginRight: 100,
        },
    },
    signature: {
        marginTop: 15,
        width: 300,
    },
}))

export const TermsConditionPage: FC = () => {
    const classes = useStyles({})
    const [isProcced, setIsProcced] = useState(false);
    const [agree, setAgree] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const [esign, setEsign] = useState('')
    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setIsScroll(true);
            if (agree) {
                setIsProcced(true);
            }
            else {
                setIsProcced(false);
            }
        }
    }
    const onAgree = (event: any) => {
        setAgree(event.target.checked)
        if (isScroll && event.target.checked) {
            setIsProcced(true);
        } else {
            setIsProcced(false);
        }
    }
    const onProcced = () => {
        if(agree) {
            const values = {
                isAccepted: true,
                eSignature: esign
            }
            termsConditionsStore.createUser(values);
        }
        
    }
    return useObserver(() => (
        <>
            <Grid container className={classes.container}>
                <Grid item>
                    <h4 className={classes.title}>AUTOFLOC SYSTEM - TERMS OF USE</h4>
                </Grid>
                <Grid item className={classes.term} onScroll={handleScroll}>
                    <Typography variant="body1" gutterBottom >
                        <div>
                            <p>This Agreement is between Silt Control Solutions Limited (<strong>AutoFloc</strong>) and you (<strong>User</strong>), together the <strong>Parties</strong>. </p>
                            <p>It is proposed that AutoFloc leases Equipment to the User, and the User uses the AutoFloc System to operate and monitor the Equipment. </p>
                            <p>By creating an account with AutoFloc and acknowledging acceptance of these terms of use (<strong>Terms</strong>) the User accepts, and demonstrates its intention to be bound by, the provisions of these Terms, and such acceptance shall constitute and be deemed to be proper and lawful execution of this agreement by the User. </p>
                            <p><strong>IT IS HEREBY AGREED</strong> that AutoFloc will provide to the User a lease to use the Equipment and a licence to use the Application and Website described in this agreement on the following terms and conditions.</p>

                            <h3>1. DEFINITIONS  </h3>
                            <p><strong>Application </strong>means AutoFloc application to be used in conjunction with the Equipment. </p>
                            <p><strong>AutoFloc System</strong> means the Application, Equipment, Website, Services, and any related item(s) or service provided by AutoFloc to the User in connection with the Equipment. </p>
                            <p><strong>Business Day</strong> means a day on which registered banks are open for business in Auckland, New Zealand, excluding Saturdays, Sundays and public holidays. </p>
                            <p><strong>Charges </strong>means the amount payable by User to AutoFloc, including the rental charges and/or service fees together with any and all delivery/removal charges, charges for parts and labour, all excess use charges, charges for damage to or loss of the Equipment, cleaning charges, default interest charges for late payment and all other charges, costs and expenses that are specified as being payable by User, such charges to be at AutoFloc&rsquo;s applicable rates and/or prices from time to time as set out in the Application<strong>,</strong> on the Website or otherwise advised to User by AutoFloc in writing. </p>
                            <p><strong>Event of Default</strong> occurs if User: </p>
                            <p>(a) &nbsp; becomes, or is deemed to be, insolvent or bankrupt; </p>
                            <p>(b) &nbsp; makes an assignment for the benefit of, or enters into or makes any arrangement or composition with, its creditors (other than AutoFloc); </p>
                            <p>(c) &nbsp; goes into receivership or has a receiver, administrator, trustee or manager (including a statutory manager) appointed in respect of User and/or all or any of User's property; </p>
                            <p>(d) &nbsp; passes a resolution, or any proceeding is commenced, for User's winding up or liquidation (other than for the purposes of a solvent reconstruction); or </p>
                            <p>(e) &nbsp; has: </p>
                            <p>(i) &nbsp;&nbsp; breached a provision of this agreement that is incapable of remedy; or </p>
                            <p>(ii) &nbsp;&nbsp; failed to remedy any breach of this agreement that is capable of remedy within 10 Business Days of notice of that breach having been given stating the nature of the breach and requiring remedy. </p>
                            <p><strong>Equipment </strong>means the Equipment to be supplied to User by AutoFloc as ordered by the User in the Application or Website, where such order is confirmed by AutoFloc. </p>
                            <p><strong>Force Majeure Event</strong> means any event or circumstance (whether arising from natural causes, human agency or otherwise) that is beyond the reasonable control of AutoFloc, including strikes, lockouts or other labour disputes, riot, civil commotion, fire, flood, drought, loss or delay at sea, pandemic, epidemic, breakdown or war (whether declared or not). </p>
                            <p><strong>Good Condition</strong> means good condition for a piece of Equipment bearing in mind its age and usage, and fair wear and tear excepted. </p>
                            <p><strong>GST</strong> means goods and services tax as defined in the Goods and Services Tax Act 1985 (the <strong>GST Act</strong>). </p>
                            <p><strong>Hire Period</strong> means the period for which a particular piece of Equipment is hired by the User, as described in clause 5.1. </p>
                            <p><strong>PPSA </strong>means the Personal Property Securities Act 1999. </p>
                            <p><strong>Services </strong>means the delivery, installation, setup demonstration, servicing, repair, support and/or other services to be provided by AutoFloc to User in respect of the Equipment under these Terms. </p>
                            <p><strong>Website</strong> means <a style={{ color: '#FFFFFF' }} target="_blank" rel="noopener noreferrer" href="https://www.autofloc.com/">https://www.autofloc.com/</a> </p>
                            <h3>2. LEASE  </h3>
                            <p>2.1 &nbsp; Subject always to the User's compliance with these Terms, AutoFloc grants to the User an operating lease in respect of the Equipment for the applicable Hire Period for use in conjunction with the wider AutoFloc System, for its business purposes. </p>
                            <p>2.2 &nbsp; AutoFloc may revoke this lease at any point and has no obligation to give the User notice of such revocation<strong>.</strong> </p>
                            <h3>3. LICENCE  </h3>
                            <p>3.1 &nbsp; Subject always to the User's compliance with these Terms, AutoFloc grants to the User a limited, non-exclusive, non sublicensable, non-assignable, and non-transferable license to use the AutoFloc System (including the Application and the Website) for its business purposes. </p>
                            <p>3.2 &nbsp; AutoFloc may revoke this license at any point and has no obligation to give the User notice of such revocation. </p>
                            <p>3.3 &nbsp; The Application and Website are provided "as-is" and, to the maximum extent permitted by applicable law, AutoFloc, its affiliates, licensors, participating financial institutions, third- party content or service providers, retailers, distributors, dealers and suppliers (collectively, "Suppliers") disclaim all guarantees and warranties, regarding the Application and Website, other than those explicitly provided for in these Terms, including any warranty of fitness for a particular purpose, title, merchantability, and non-infringement. AutoFloc does not warrant that the Application and Website are secure, free from bugs, viruses, interruption, errors, or other program limitations.&nbsp; AutoFloc does not warrant that software or services provided as part of the AutoFloc System are secure, free from bugs, viruses, interruption, errors, or other program limitations. The User acknowledges that it has entered into this Terms relying on its own judgment and not upon any warranty or representation made by AutoFloc. &nbsp; </p>
                            <h3>4. INTELLECTUAL PROPERTY  </h3>
                            <p>4.1 &nbsp; Ownership of the Autofloc System, and all rights, including but not limited to intellectual property rights in the AutoFloc System and all related materials are and shall remain the exclusive property of AutoFloc, and the User shall have no right, title or interest in them. </p>
                            <p>4.2 &nbsp; The structure, design organization and code of the Equipment, Application and Website are the valuable trade secrets and confidential information of AutoFloc and its suppliers and are protected by patent, copyright, trade secret and other intellectual property laws. </p>
                            <p>4.3 &nbsp; This agreement does not grant the User any rights to trademarks of AutoFloc. </p>
                            <p>4.4 &nbsp; The User shall not: </p>
                            <p>(a) &nbsp; copy, alter, modify, reproduce, reverse assemble, reverse compile or enhance the Application, Website or any piece of Equipment; </p>
                            <p>(b) &nbsp; remove any proprietary or copyright notices, labels or marks from the Equipment; </p>
                            <p>(c) &nbsp; sell, rent or provide any form of the Autofloc System, or access to, the Autofloc System to any un-authorised third party; </p>
                            <p>(d) &nbsp; use the Autofloc System in a manner prohibited by any governmental agency or any applicable laws, restrictions or regulations; </p>
                            <p>(e) &nbsp; use the Autofloc System in any manner that violates the rights of any person or other than for the purpose for which it is intended to be used; </p>
                            <p>(f) &nbsp;&nbsp; modify or create any derivative work of the Application, Website or Equipment; </p>
                            <p>(g) &nbsp; decompile, replicate, disassemble, translate or otherwise reverse engineer or attempt to derive source code (or the underlying ideas, algorithms, structure or organisation) of the Application, Website or Equipment; </p>
                            <p>(h) &nbsp; rent, transfer, assign, sub-lease, sub-licence or grant any rights in the Autofloc System to any person; or </p>
                            <p>(i) &nbsp;&nbsp; remove any proprietary notices, branding, labels, or marks on or related to the Application, Website or Equipment. </p>
                            <p>4.5 &nbsp; The User acknowledges that any intellectual property arising directly or indirectly out of the performance of these Terms or the Services is, or shall on creation be, the property of AutoFloc. </p>
                            <p>4.6 &nbsp; The User must notify AutoFloc of any actual, threatened, or suspected infringement of any intellectual property right and of any claim by any third party that any use of the AutoFloc System infringes any rights of any other person, as soon as that infringement or claim comes to the User's notice. The User shall do all such things as may reasonably be required by AutoFloc to assist AutoFloc in pursuing or defending any proceedings in relation to any such infringement or claim. </p>
                            <p>4.7 &nbsp; The User indemnifies AutoFloc against any loss, costs, expenses, demands or liability, whether direct, indirect or consequential or otherwise, and whether arising in agreement, tort (including in each case negligence), or equity or otherwise, arising out of a claim by a third party alleging infringement of that third party&rsquo;s intellectual property rights if such claim arises from infringement, suspected infringement or alleged infringement due to: </p>
                            <p>(a) &nbsp; use of any of the AutoFloc System in combination by any means and in any form with software, hardware or asset not specifically approved by AutoFloc or in a manner or for a purpose not reasonably contemplated or authorised by AutoFloc; and/or </p>
                            <p>(b) &nbsp; a breach by the User of this clause 4. </p>
                            <h3>5. HIRE PERIOD AND RISK  </h3>
                            <p>5.1 &nbsp; The Hire Period for a piece of Equipment: </p>
                            <p>(a) &nbsp; begins at the time the Equipment is delivered by Autofloc, and confirmation of such delivery is showing on the Application or Website;&nbsp;and </p>
                            <p>(b) &nbsp; runs until the User or AutoFloc ends the Hire Period for the Equipment via the Application or Website pursuant to these Terms. </p>
                            <p>5.2 &nbsp; Notwithstanding clause 12.1(b), the minimum Hire Period will be for a period of 4 weeks from the date that the Hire Period begins. </p>
                            <p>5.3 &nbsp; Risk in the Equipment will pass to User when it &nbsp;has been delivered by AutoFloc, or where it will be delivered on behalf of Autofloc, from the time the Equipment leaves Autofloc's premises, and will remain with User until AutoFloc removes the Equipment at the end of the Hire Period and updates the status accordingly on the Website and Application. </p>
                            <h3>6.  CHARGES AND PAYMENT  </h3>
                            <p>6.1 &nbsp; User will pay AutoFloc the Charges for each Hire Period as invoiced and on the dates and in the instalments specified and/or as required by AutoFloc from time to time. </p>
                            <p>6.2 &nbsp; User's obligations to pay any amounts under these Terms to AutoFloc will continue despite any mechanical defect in or breakdown of, theft of, or accident or damage caused to the Equipment. </p>
                            <p>6.3 &nbsp; On return of the Equipment, the Charges payable will be calculated by AutoFloc (in its sole discretion) to the later of the end of the Hire Period and the date of return of the Equipment and if there is any amount outstanding for payment by User, User will immediately pay such amount to AutoFloc. </p>
                            <p>6.4 &nbsp; User will pay all AutoFloc's costs and expenses (including legal costs and expenses incurred on a solicitor/own client basis) incurred by AutoFloc in the enforcement of its rights and remedies under these Terms and at law, upon demand being made by AutoFloc. </p>
                            <p>6.5 &nbsp; Without prejudice to any other rights it may have (whether at law or otherwise), AutoFloc may, in the event any amount owing to AutoFloc is not received in full on or before the due date, charge interest at AutoFloc&rsquo;s current bank overdraft rate plus a margin of 5% on any amount outstanding, calculated on a daily basis from the due date for payment until the date full payment is received by AutoFloc. </p>
                            <p>6.6 &nbsp; All amounts stated in and payable by User exclude GST and User will also pay the amount of any GST (if the payment is for a taxable supply as defined in the GST Act and a tax invoice is provided). If GST is not paid as required, all penalties, interest or costs incurred in connection with the consequential late payment of GST will also be payable by User. </p>
                            <p>6.7 &nbsp; Any payments due by User to AutoFloc under these Terms will be payable in cleared funds and without setoff or deduction to AutoFloc in the manner advised by AutoFloc. </p>
                            <p>6.8 &nbsp; If a date for payment of any amount under these Terms falls on a day other than a Business Day then such amount must be paid on the immediately preceding Business Day unless otherwise agreed by AutoFloc. </p>
                            <h3>7. DELIVERY AND REMOVAL  </h3>
                            <p>7.1 &nbsp; Unless otherwise advised by AutoFloc, AutoFloc will make available and/or deliver the Equipment to User at the delivery address specified in the Application. </p>
                            <p>7.2 &nbsp; At Autofloc's discretion, Autofloc may provide training materials and a demonstration to User upon delivery so that User can install the Equipment themselves. </p>
                            <p>7.3 &nbsp; Notwithstanding clause 7.1 and to the maximum extent permitted by law, User acknowledges that: </p>
                            <p>(a) &nbsp; unless otherwise agreed in writing, any delivery date specified is approximate only and time is not of the essence; and </p>
                            <p>(b) &nbsp; under no circumstances will AutoFloc be liable for any costs incurred by User as a result of any delay in delivery (whether caused by factors beyond AutoFloc&rsquo;s control or otherwise). </p>
                            <p>7.4 &nbsp; Upon the expiry of the Hire Period and/or termination of these Terms, User shall allow for the removal of the Equipment by AutoFloc. </p>
                            <p>7.5 &nbsp; Upon the return or recovery of the Equipment, AutoFloc will inspect the Equipment to determine whether it is, in AutoFloc's opinion, in Good Condition. If, in AutoFloc's opinion, the Equipment is not in Good Condition, User will pay to AutoFloc upon demand, the costs for all repairs or other work required to restore the Equipment to that condition. </p>
                            <p>7.6 &nbsp; User grants to AutoFloc (and its authorised agents), or will procure that AutoFloc is granted, an irrevocable right and authority to enter, and for AutoFloc to bring AutoFloc's (or its authorised agent's) vehicle at any time onto, the place where the Equipment is to be used or is located to provide Services and/or remove the Equipment for the provision of Services as agreed between the Parties from time to time or on the expiry of the Hire Period or on termination of these Terms pursuant to clause 12, or if AutoFloc believes the Equipment is at risk pursuant to clause 11.4. AutoFloc will not be liable for costs, damages or expenses or any other losses whatsoever incurred by User or a third party as a result of this action, nor liable in tort or otherwise in any way whatsoever. </p>
                            <h3>8. CARE AND OPERATION OF EQUIPMENT  </h3>
                            <p>8.1 &nbsp; User will: </p>
                            <p>(a) &nbsp; immediately notify AutoFloc in writing of any defect, damage or fault in or to, or loss or theft of Equipment; </p>
                            <p>(b) &nbsp; at its own cost, ensure that the Equipment is kept in Good Condition; </p>
                            <p>(c) &nbsp; ensure that all servicing and repair of the Equipment is provided by AutoFloc (at AutoFloc's cost) exclusively unless otherwise agreed by AutoFloc in writing; </p>
                            <p>(d) &nbsp; at all times comply with all policies, procedures and directions from time to time provided by AutoFloc, including all terms of any manual; </p>
                            <p>(e) &nbsp; if applicable, only allow suitably qualified and licensed operators to use the Equipment; </p>
                            <p>(f) &nbsp;&nbsp; ensure the Equipment is only used for the purposes for which it is designed and manufactured and in accordance with AutoFloc's instructions; </p>
                            <p>(g) &nbsp; not remove, deface or obscure any marks of identification, ownership or registration on the Equipment; and </p>
                            <p>(h) &nbsp; not allow any person to use the Equipment in a way that breaches the law or the terms of any insurance or these Terms or otherwise in a dangerous, reckless or negligent manner. </p>
                            <p>8.2 &nbsp; User grants to AutoFloc the right, at all reasonable times and upon reasonable notice and without undue interference with User&rsquo;s business, to: </p>
                            <p>(a) &nbsp; enter (with its employees, agents and experts, if appropriate) upon or into the premises where any Equipment may be; </p>
                            <p>(b) &nbsp; inspect the state of repair of any Equipment; </p>
                            <p>(c) &nbsp; carry out such tests on any Equipment as AutoFloc may deem appropriate; </p>
                            <p>(d) &nbsp; observe the use of any Equipment; and/or </p>
                            <p>(e) &nbsp; do any act, matter or thing which may be required to give proper effect to and ensure User's compliance with, or protect AutoFloc's rights under, these Terms. </p>
                            <p>No notice of the intention to inspect will be required where, in the sole opinion of AutoFloc, urgent access to any Equipment is required to preserve the interests of AutoFloc and/or the condition or security of any Equipment. </p>
                            <p>8.3 &nbsp; Without prejudice to any other rights it may have (whether at law or otherwise), if AutoFloc considers the Equipment is not in Good Condition due to the fault of the User, it will advise User and may: </p>
                            <p>(a) &nbsp; attend to or procure the repair or other maintenance required to restore the Equipment to Good Condition (at User's cost); and/or </p>
                            <p>(b) &nbsp; remove the Equipment and terminate these Terms in accordance with clauses 11.4 and 12. </p>
                            <h3>9. INSURANCE  </h3>
                            <p>9.1 &nbsp; Without in any way limiting User's obligations under these Terms, AutoFloc may require User to keep the Equipment insured to its full insurable value with an independent insurer for loss, theft, damage or destruction during the period that the Equipment is held at User's risk (as contemplated under clause 5.2). </p>
                            <p>9.2 &nbsp; AutoFloc may require User to provide written confirmation of User's insurance that is satisfactory in every respect to AutoFloc in its sole direction. If AutoFloc is not satisfied with User's compliance with this clause, AutoFloc may insure the Equipment itself and charge User for the cost of such insurance, decline to lease the Equipment to User or require its immediate return/removal of the Equipment under clause 11.4. </p>
                            <p>9.3 &nbsp; User will advise AutoFloc immediately if the Equipment is lost, stolen, damaged or destroyed, or if applicable if User has committed any act, or any circumstance has arisen, that has given, or may give, rise to a claim under User's insurance or may in any way prejudice, invalidate or result in the cancellation of such policy. </p>
                            <p>9.4 &nbsp; AutoFloc will be entitled to receive all monies payable under User's insurance (if any), and if such monies are received by User, User will hold such amounts on trust for AutoFloc and will arrange for the full amount to be transferred to AutoFloc at the earliest possible opportunity. User will pay all premiums and the amount of the excess under User's insurance. </p>
                            <p>9.5 &nbsp; Any amounts received by AutoFloc pursuant to clause 9.4 above may be applied in or towards the repair or replacement of the Equipment. If the cost of any repairs or replacement Equipment exceeds the insurance monies received by AutoFloc from the relevant insurer, User will pay the balance of such repair or replacement costs to AutoFloc upon demand being made by AutoFloc. </p>
                            <p>9.6 &nbsp; Notwithstanding the other provisions of this clause 9, User acknowledges and agrees that it leases the Equipment at its own risk and indemnifies AutoFloc against any and all loss (including any economic or consequential loss) in respect of any loss or damage to the Equipment. </p>
                            <h3>10. TITLE  </h3>
                            <p>10.1 &nbsp; User acknowledges that AutoFloc is, and will remain at all times, the legal and beneficial owner of the Equipment. User's interest in the Equipment is as lessee only and these Terms confer no other right or interest in or to the Equipment on User, including no right for User to purchase the Equipment at any time, whether during or after the Hire Period or expiry of these Terms. </p>
                            <h3>11. SECURITY INTEREST  </h3>
                            <p>11.1 &nbsp; Without limiting any other provisions of these Terms, User acknowledges that these Terms constitutes a security agreement for the purposes of the PPSA, which provides for a security interest in AutoFloc's favour in respect of any Equipment provided by AutoFloc to User, and all User's present and future rights in relation to such Equipment, to secure the payment by User to AutoFloc of the Charges payable under these Terms. </p>
                            <p>11.2 &nbsp; On request by AutoFloc, User will promptly do all things (including signing any documents) and provide all information necessary to enable AutoFloc to perfect and maintain the perfection of any security interest User grants to AutoFloc (including by registration of a financing statement). </p>
                            <p>11.3 &nbsp; User agrees: </p>
                            <p>(a) &nbsp; that nothing in sections 114(1)(a), 133 and 134 of the PPSA will apply to these Terms; </p>
                            <p>(b) &nbsp; to waive all User's rights under sections 116, 120(2), 121, 125, 126, 127, 129 and 131 of the PPSA; and </p>
                            <p>(c) &nbsp; to waive User's rights to receive a copy of the verification statement confirming registration. </p>
                            <p>11.4 &nbsp; User agrees that AutoFloc (at its sole discretion) and/or its authorized agent may repossess the Equipment at any time if it believes the Equipment is at risk. If AutoFloc and/or its authorized agent does repossess the Equipment pursuant to this clause, clause 7.5 relating to exclusion of liability will apply. </p>
                            <h3>12. TERMINATION  </h3>
                            <p>12.1 &nbsp; Without prejudice to any of either party's rights or remedies under these Terms, at law or otherwise: </p>
                            <p>(a) &nbsp; either party may terminate these Terms by giving the other party notice (however, the User cannot terminate this Terms under this clause if, at the time of termination, it is currently hiring a piece of Equipment); or </p>
                            <p>(b) &nbsp; AutoFloc may terminate these Terms immediately by written notice to User, if an Event of Default occurs. </p>
                            <p>12.2 &nbsp; Upon termination of these Terms, AutoFloc will arrange for and carry out the removal of Equipment in accordance with clause 7 of these Terms. </p>
                            <h3>13. WARRANTIES  </h3>
                            <p>13.1 &nbsp; User acknowledges and warrants to AutoFloc prior to signing these Terms and during the term of these Terms, that: </p>
                            <p>(a) &nbsp; it has inspected the Equipment and made its own assessment as to the performance, condition and safety of the Equipment and has satisfied itself that the Equipment is fit and suitable for User's intended use; </p>
                            <p>(b) &nbsp; it has not relied, nor will it rely, on any representation, statement or warranty made by AutoFloc about the performance condition or safety of the Equipment or the fitness or suitability of the Equipment for User's intended use; </p>
                            <p>(c) &nbsp; information entered into the Website and Application by User is accurate and acknowledges that the Application, Website and Equipment rely on accurate information provided by the User; </p>
                            <p>(d) &nbsp; it has the power to, and has taken all necessary action to, enable User to enter into, and comply with, User's obligations under these Terms; </p>
                            <p>(e) &nbsp; all information provided by User to AutoFloc is true, accurate and not misleading in any way; and </p>
                            <p>(f) &nbsp;&nbsp; it is in trade (as defined in section 2(1)) of the Consumer Guarantees Act 1993 and that, pursuant to section 43 of the Consumer Guarantees Act 1993, the provisions of that Act are expressly excluded and it is fair and reasonable to do so. </p>
                            <p>13.2 &nbsp; All warranties, conditions, descriptions or representations about the Equipment (for example, but without limitation, about quality, fitness for purpose or safety) are expressly excluded to the maximum extent permitted by law, except for any warranties, conditions, descriptions or representations provided by any third party manufacturer of the Equipment or part thereof. </p>
                            <h3>14. LIABILITY  </h3>
                            <p>14.1 &nbsp; Subject to clause 14.2 but notwithstanding any other provision of these Terms, to the maximum extent permitted by law, AutoFloc's liability arising out of, or in connection with, these Terms in respect of any claim whether under contract, tort (for negligence or otherwise) or on any other basis, is limited to the lesser of the total Charges paid by User to AutoFloc under these Terms and three month's rental charge instalments payable by User to AutoFloc under these Terms. </p>
                            <p>14.2 &nbsp; AutoFloc will not, in any case, be liable to the User for exemplary, punitive, indirect, consequential losses or damages or loss of profits. </p>
                            <h3>15. FORCE MAJEURE  </h3>
                            <p>15.1 &nbsp; Notwithstanding any other provision of these Terms, non-performance by AutoFloc of any of its obligations under these Terms will be excused, without liability for non-performance, during the time and to the extent that performance is prevented, wholly or substantially, by a Force Majeure Event either affecting AutoFloc or affecting User to such an extent that AutoFloc is unable to perform its relevant obligations. </p>
                            <p>15.2 &nbsp; AutoFloc will promptly advise User (to the extent possible), specifying the cause and extent of its inability to perform any of its obligations and the likely duration of such non-performance. </p>
                            <h3>16. INDEMNITY  </h3>
                            <p>16.1 &nbsp; AutoFloc shall not be liable to the User or any other party in any manner whatsoever whether direct or indirect arising out of: the User's connection to the internet or the User's hardware, software or systems, the User's acts or defaults in relation to errors in input or statistical misinformation, a default or malfunction in the systems, devices or services of any third party utilised by the User, or the User's use of third party services or a defect or fault due to a third party unrelated to AutoFloc. </p>
                            <p>16.2 &nbsp; To the maximum extent permitted by law, User will indemnify and keep indemnified AutoFloc and its employees, agents and contractors, and hold all of them harmless from and against: </p>
                            <p>(a) &nbsp; all loss, costs, claims, damages, expenses (including indirect, economic and consequential loss and all legal costs and expenses incurred on a solicitor/own client basis) incurred by AutoFloc, or any of its employees, agents or subcontractors; and </p>
                            <p>(b) &nbsp; all actions, suits, claims, demands and any other proceedings whatsoever made or brought against AutoFloc or any of its employees, agents or contractors; </p>
                            <p>caused by any breach of these Terms, or by any representation made, or any, act or omission, or negligence committed, by User or by any of User's employees, agents, contractors or Users, including (without limitation) any act, omission or negligence arising out of the use of the Equipment. </p>
                            <h3>17. COLLECTION AND USE OF INFORMATION  </h3>
                            <p>17.1 &nbsp; User authorises AutoFloc to collect, retain and use any information about the User and its employees, agents and contractors (and has those parties consent to such disclosure, retention and use), for the purposes of: </p>
                            <p>(a) &nbsp; providing the Autofloc System and operating its business; </p>
                            <p>(b) &nbsp; assessing the User's creditworthiness; </p>
                            <p>(c) &nbsp; enforcing any rights under these Terms; </p>
                            <p>(d) &nbsp; marketing any goods and/or services available from AutoFloc or its related companies; and </p>
                            <p>(e) &nbsp; exercising or enforcing any right that AutoFloc has under these Terms or at law. </p>
                            <p>17.2 &nbsp; The User authorises AutoFloc to disclose any information obtained to any person for the purposes set out in clause 17.1. </p>
                            <p>17.3 &nbsp; Where the User is a natural person the authorities under clauses 17.1 and 17.2 are authorities or consents for the purposes of the Privacy Act 1993. </p>
                            <h3>18. SURVIVAL  </h3>
                            <p>18.1 &nbsp; User&rsquo;s obligations under clauses 4, 6.1, 6.2 6.6, 10, 11, 12.2, 13, 14 , 16 17 and 18 will survive the expiry or termination of these Terms. </p>
                            <h3>19. GENERAL  </h3>
                            <p>19.1 &nbsp; The User will not attempt to compromise AutoFloc's security environment or service availability through hacking, denial of service attacks or similar actions. The User will be responsible for the actions of its employees, agents, sub-contractors and any other person who in breach of these Terms. The User shall take reasonable steps to protect the information on its computers, devices and systems, including installing anti-virus software, regularly updating its software, password protecting its files, and not permitting third party access to its computers, devices and systems.&nbsp; The User must comply with the terms of any applicable third party license arrangements when using the Application, Website or Equipment. </p>
                            <p>19.2 &nbsp; AutoFloc may subcontract, assign or transfer all or any part of its rights or obligations under these Terms, without User&rsquo;s consent. User may not subcontract, assign or transfer all or any part of its rights or obligations under these Terms without AutoFloc's prior written consent. If User is a company, any change in the legal or beneficial control of User will be deemed an assignment for the purposes of this clause and will require AutoFloc's prior written consent. </p>
                            <p>19.3 &nbsp; The termination of these Terms for whatever reason will not affect AutoFloc's rights, powers and remedies with respect to any breach by User under these Terms. </p>
                            <p>19.4 &nbsp; To the maximum extent permitted by law, these Terms constitutes the entire understanding and agreement of the Parties relating to the matters contemplated by these Terms and supersedes and extinguishes all prior agreements, arrangements, understandings or representations between the Parties relating to such matters. User acknowledges and agrees that the Equipment is being supplied by AutoFloc and hired by User in trade and that, pursuant to section 5D of the Fair Trading Act 1986, sections 9, 12(a) and 13 of that Act are expressly excluded and it is fair and reasonable to do so. </p>
                            <p>19.5 &nbsp; No failure, neglect, forbearance or delay on the part of AutoFloc to exercise or enforce any right or remedy available to it under these Terms will be construed as a waiver of such right or remedy. No single or partial exercise of any right or remedy under these Terms will preclude the exercise or any other right or remedy or preclude the further exercise of any right or remedy. </p>
                            <p>19.6 &nbsp; Any terms found to be void, unenforceable or illegal may, to that extent be severed from these Terms without affecting the validity, legality or enforceability of the remaining provisions. </p>
                            <p>19.7 &nbsp; User will execute and do all documents, deeds, acts and things as may reasonably be required by AutoFloc to carry out and give effect to the terms and intention of these Terms. </p>
                            <p>19.8 &nbsp; User warrants it has had the opportunity to obtain independent legal advice about its obligations under these Terms, and confirms these Terms is fair and reasonable. </p>
                            <p>19.9 &nbsp; These Terms are governed by the laws of New Zealand and each party submits to the exclusive jurisdiction of the Courts of New Zealand. </p>
                        </div>
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        name="name"
                        label="E-Signature"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        value={esign}
                        onChange={(e) => { setEsign(e.target.value.toUpperCase()) }}
                        InputProps={{ style: { textTransform: 'uppercase' } }}
                        autoFocus
                        className={classes.signature}
                    />
                    
                </Grid>
                <Grid container className={classes.container}>
                    <FormControlLabel
                        control={<Checkbox name="checkedF" checked={agree} onChange={onAgree} color="primary" />}
                        label="I agree to the terms and conditions"
                    />
                </Grid>
                {!isScroll ? <Grid  className={classes.container}>
                <Grid item>
                    <h4 className={classes.notice}>To proceed further you need to scroll through all the T&C’s</h4>
                </Grid>
                </Grid>:null}
                <Grid container className={classes.container}>
                    <Button variant="contained" color="primary"  onClick={onProcced}>
                        PROCEED
                    </Button>
                </Grid>
            </Grid>
        </>
    ))
}
