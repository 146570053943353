import { locationStore } from '@any-ui/core/navigation';
import { Collection, Mode } from 'firestorter';
import { action, reaction } from 'mobx';

import { DeviceDoc } from '../db/deviceDoc';
import { DeviceStatus } from '../tools/DeviceStatus';

// import { authStore } from '../auth/authStore';
// import { CompanyStaffDoc } from '../db/companyStaffDoc';
// import { any } from 'io-ts';
// import { IDevice } from '../model/device';
// import { async } from '@firebase/util';

// import { any } from 'io-ts'

class DeviceRetrievalsStore {
  public deviceRetrievals = new Collection<DeviceDoc>('devices', {
    createDocument: (...args) => new DeviceDoc(...args),
    mode: Mode.On,
    query: ref =>
      ref
        .where('isRetrieved', '==', false)
        .where('status', '==', DeviceStatus.OFFHIRE),
  })
  private readonly disposer: () => void
  private customerId = ''
  constructor() {
    this.disposer = reaction(
      () => locationStore.path.startsWith('/retrieve'),
      inDevices => {
        this.deviceRetrievals.mode = inDevices ? Mode.On : Mode.Auto
      },
      { fireImmediately: true },
    )
  }
  @action
  public setCustomerId(customerId: string) {
    this.customerId = customerId
  }
  public updateDeviceRetrival = async (deviceId: string) => {
    const device = await new DeviceDoc(`devices/${deviceId}`, {
      mode: Mode.Off,
    })
    await device.set({ isRetrieved: true, ownerUid: null }, { merge: true })
  }
  public getCustomersDeviceRetrievals = async () => {
    if (this.customerId !== '-1') {
      this.deviceRetrievals = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref
            .where('ownerUid', '==', this.customerId)
            .where('isRetrieved', '==', false)
            .where('status', '==', DeviceStatus.OFFHIRE),
      })
    } else {
      this.deviceRetrievals = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref
            .where('isRetrieved', '==', false)
            .where('status', '==', DeviceStatus.OFFHIRE),
      })
    }
  }
}

export const deviceRetrievalsStore = new DeviceRetrievalsStore()
