import {
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  NumberField,
  requiredNumber,
  requiredString,
  TextField,
  validators,
} from '@any-ui/form';
import { IconButton } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { DeleteStaff } from '../app/Icons';
import { IDeviceRequest } from '../model/deviceRequest';
import { DeviceRequestStatus } from '../model/deviceReqStatus';

interface IProps {
  onDelete: any
}

export type FormFields = Pick<
  IDeviceRequest,
  | 'noOfDevice'
  | 'deliveryAddress'
  | 'ownerUid'
  | 'deliveryInstructions'
  | 'status'
>

export const EditDeviceRequestForm: FormComponent<FormFields, IProps> = ({
  onResult,
  onDelete,
  ...formProps
}) => {
  const handleSubmit: FormSubmitHandler<FormFields> = async values => {
    await onResult({
      ownerUid: emptyToNull(values.ownerUid),
      noOfDevice: requiredNumber(values.noOfDevice),
      deliveryAddress: requiredString(values.deliveryAddress),
      deliveryInstructions: requiredString(values.deliveryInstructions),
      status: DeviceRequestStatus.PENDING
    })
  }



  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...formProps}>
    <Form<FormFields>
      onSubmit={handleSubmit}
      {...formProps}
      submitLabel="Submit Request"
    >
      <NumberField
        name="noOfDevice"
        label="Number of devices needed"
        validate={validators.required()}
        autoFocus
      />
      <TextField
        name="deliveryAddress"
        label="Address to send device"
        rows={4}
      />
      <TextField
        name="deliveryInstructions"
        label="Delivery instructions"
        rows={4}
      />
      {(formProps.initialValues && formProps.initialValues.status === DeviceRequestStatus.PENDING) &&
        <IconButton onClick={onDelete} >
          <DeleteStaff color="secondary" />
        </IconButton>
      }

    </Form>
  ))
}
