import { CardActionIconButton } from '@any-ui/core'
import React, { FC, useState } from 'react'
import { ShareIcon } from '../../app/Icons'
import { SiteViewStore } from '../store/siteViewStore'
import { ShareLinkDialog } from './ShareLinkDialog'

export const ShareViewerLinkIconButton: FC<{
  store: SiteViewStore
}> = ({ store }) => {
  const [showDialog, setShowDialog] = useState(false)
  const handleRequest = () => {
    setShowDialog(true)
  }
  return (
    <>
      <ShareLinkDialog
        store={store}
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
      />
      <CardActionIconButton
        aria-label="Share link"
        tooltip="Share link"
        onClick={handleRequest}
      >
        <ShareIcon />
      </CardActionIconButton>
    </>
  )
}
