import { DeepPartial } from '@any-ui/form';
import { Mode } from 'firestorter';
import { computed } from 'mobx';

import { ownerStore } from '../auth/ownerStore';
import { DeviceRequestDoc } from '../db/deviceRequestDoc';
import { AsUpdate, FieldValue } from '../firestore';
import * as log from '../log';
import { IDeviceRequest } from '../model/deviceRequest';
import { deviceRequestStore } from './deviceRequestStore';

// import { FormFields } from './EditDeviceForm'

export class DeviceRequestEditStore {
  public readonly isNew: boolean

  @computed
  get isLoading() {
    return !!this.initialDoc && this.initialDoc.isLoading
  }

  @computed
  get initialValues(): DeepPartial<any> {
    const doc: Partial<IDeviceRequest> =
      (this.initialDoc && this.initialDoc.data) || {}
    return {
      ownerUid: ownerStore.defaultOwnerUid,
      ...doc,
    }
  }

  private readonly initialDoc: DeviceRequestDoc | null = null

  constructor(private readonly deviceRequestId?: string) {
    this.isNew = !deviceRequestId
    if (deviceRequestId) {
      this.initialDoc = new DeviceRequestDoc(
        () => `deviceRequests/${deviceRequestId}`,
        {
          mode: Mode.Off,
        },
      )
      this.initialDoc.fetch().catch(log.error)
    }
  }

  //   public deviceStatus = (fields: FormFields) => {
  //     if (this.deployedToPond) {
  //       return 'Allocated'
  //     } else if (fields.siteId) {
  //       return 'Unallocated'
  //     } else {
  //       return 'Off Hire'
  //     }
  //   }

  public save = async (fields: any) => {
    if (this.initialDoc) {
      await this.initialDoc.set(fields, { merge: true })
    } else {
      const data: AsUpdate<IDeviceRequest> = {
        addedTimestamp: FieldValue.serverTimestamp(),
        isActive: true,
        ...fields,
        // status: this.deviceStatus(fields)
      }
      await deviceRequestStore.deviceRequests.add(data)
    }
   
  }
}
