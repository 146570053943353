import { CardActionIconButton } from '@any-ui/core'
import React, { FC, useState } from 'react'
import { DoseFlowIcon } from '../../app/Icons'
import { PondDoc } from '../../db/pondDoc'
import { DosePondDialog } from './DosePondDialog'

export const DosePondIconButton: FC<{
  pond: PondDoc
}> = ({ pond }) => {
  const [showDialog, setShowDialog] = useState(false)
  const handleRequest = () => {
    setShowDialog(true)
  }
  return (
    <>
      <DosePondDialog
        pond={pond}
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
      />
      <CardActionIconButton
        aria-label="Dose pond"
        tooltip="Dose pond"
        onClick={handleRequest}
      >
        <DoseFlowIcon />
      </CardActionIconButton>
    </>
  )
}
