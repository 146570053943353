import {
  EmailField,
  emptyToNull,
  Form,
  FormComponent,
  FormSubmitHandler,
  IFormActionProps,
  PasswordField,
  requiredString,
  TextField,
  validators,
} from '@any-ui/form';
import { Link, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { authStore } from '../auth/authStore';
import { passwordLengthValidationOptions } from '../customer/password';
import { ICustomerUserProfile } from '../model/customer';
import { IUserProfileUpdate } from '../model/user';
import { phone } from './phoneValidator';
import { ProfileEditStore } from './profileEditStore';

const { confirmation, email, length } = validators

export type FormFields = ICustomerUserProfile & IUserProfileUpdate

interface IProps extends IFormActionProps {
  store: ProfileEditStore
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
})

export const EditProfileForm: FormComponent<FormFields, IProps> = ({
  onResult,
  store,
  ...rest
}) => {
  const classes =  useStyles({})
  const emailVerificationState = () => {
    switch (store.emailVerificationState) {
      case 'verified':
        return null
      case 'unverified':
        return (
          <>
            Address unverified,{' '}
            <Link
              onClick={store.sendEmailVerification}
              className={classes.root}
            >
              resend verification email
            </Link>
          </>
        )
      case 'resending':
        return <>'Sending email...'</>
      case 'sent':
        return <>Verification email sent</>
      case 'failed':
        return (
          <>
            Sending verification email failed,{' '}
            <Link
              onClick={store.sendEmailVerification}
              className={classes.root}
            >
              try again
            </Link>
          </>
        )
    }
  }

  const handleSubmit: FormSubmitHandler<FormFields> = async values => {
    await onResult({
      contactName: emptyToNull(values.contactName),
      email: requiredString(values.email),
      newPassword: emptyToNull(values.newPassword),
      phoneNumber: emptyToNull(values.phoneNumber),
    })
  }

  return useObserver(() => (
    // <Form onSubmit={handleSubmit} {...rest}>
      <Form<FormFields> onSubmit={handleSubmit} {...rest}>
      <TextField name="contactName" label="Contact name" autoFocus />
      <EmailField
        name="email"
        label="Email address"
        validate={email()}
        helperText={emailVerificationState()}
      />
      <TextField
        name="phoneNumber"
        label="Mobile number"
        helperText="For alarm SMS"
        validate={phone()}
      />
      { !authStore.isAdmin && (
        <>
        <PasswordField
          name="newPassword"
          label="New password"
          placeholder="Unchanged"
          validate={length({
            ...passwordLengthValidationOptions,
            allowBlank: true,
          })}
          autoComplete="new-password"
        />
        <PasswordField
        name="passwordConfirm"
        label="Confirm password"
        placeholder="Unchanged"
        validate={confirmation({
          field: 'newPassword',
          fieldLabel: 'New password',
        })}
        autoComplete="confirm-password"
      />
      </>
      )}
      {authStore.isAdmin && (
        <PasswordField
        name="newPassword"
        label="Confirm password"
        placeholder="Unchanged"
        autoComplete="confirm-password"
      />
      )}
      
    </Form>
  ))
}
