import { DocsLayout, DocsList, PageAction, PageTitle } from '@any-ui/core';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { AddIcon } from '../app/Icons';
import { authStore } from '../auth/authStore';
import { customerStore } from '../customer/customerStore';
import { DeviceRequestDoc } from '../db/deviceRequestDoc';
import { deviceRequestStore } from '../deviceRequest/deviceRequestStore';
import { DropDown } from '../tools/DropDown';
import { Search } from '../tools/Search';
import { DeviceRequestListItem } from './components/DeviceRequestListItem';
import { DeviceRequestListTitle } from './components/DeviceRequestListTitle';

const useStyles = makeStyles<Theme, {}, any>(theme => ({
  defaultView: {
    gridColumnEnd: 'span 2',
    // tslint:disable-next-line:object-literal-sort-keys
    '&.decommissioned': {
      backgroundColor: 'rgba(0,0,0,0.15)',
      gridColumnEnd: 'span 1',
    },
    [theme.breakpoints.up('sm')]: {
      gridColumnEnd: 'span 9',
      // tslint:disable-next-line:object-literal-sort-keys
      '&.decommissioned': {
        gridColumnEnd: 'span 6',
      },
    },
  },
  divider: {},
  docsCollectionTitle: {},
  root: {
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(2),
    },
    gridTemplateColumns: 'repeat( auto-fit, minmax(1fr, 1fr) )',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(25px, 1fr) )',
    },
  },
  searchroot: {
    padding: '2px 4px',
    display: 'inline-flex',
    alignItems: 'center',
    width: 230,
    marginLeft: theme.spacing(1),
  },
  item: {
    marginRight: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  typographyRoot: {
    color: 'rgb(255, 255, 255, 0.7)',
    textAlign: 'center',
  },
  custgrid: {
    textAlign: 'right',
  },
}))

export const DeviceRequestListPage: FC = () => {
  const classes = useStyles({})
  const [customer, setCustomer] = React.useState('-1')
  const [searchText, setSearchText] = React.useState('')

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setCustomer(value)
    deviceRequestStore.setCustomerId(value)
    await deviceRequestStore.getCustomersDeviceReq()
  }
  const onSearch = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setSearchText(value)
  }
  const searchCustomer = (searchText: string) => {
    return customerStore.customers.docs
      .filter(p => p.data.orgName.toLowerCase().indexOf(searchText) !== -1)
      .map(p => {
        return p.id
      }) as Array<any>;
  }
  const filterDevicesRequest = (doc: DeviceRequestDoc) => {
    if (searchText) {
      const searchContent = searchText.toLowerCase()
      return doc.data.noOfDevice.toString().toLowerCase().indexOf(searchContent) !== -1
        || searchCustomer(searchContent).indexOf(doc.data.ownerUid) !== -1

    }
    return true
  }
  const customers = customerStore.customers.docs;
  return useObserver(() => (
    <>
      <DocsLayout
        collection={deviceRequestStore.deviceRequests}
        pageWidth="800px"
      >
        <PageTitle>Device Requests</PageTitle>
        {authStore.isCustomer && <PageAction
          Icon={AddIcon}
          label="Request device"
          onClick={DeviceRequestDoc.navigateNew}
        />}
        <Grid container spacing={3}>
          {authStore.isAdmin && <Grid item sm={9}>
            <Search onTextChanged={onSearch}></Search>
          </Grid>}
          <Grid item sm={3} className={classes.custgrid}>
            {authStore.isAdmin && (
              <DropDown
                handleChange={handleChange}
                placeholder={'All Customers'}
                value={customer}
                items={customers}
              ></DropDown>
            )}
          </Grid>
          <Grid item sm={12}>
            <DocsList<DeviceRequestDoc>
              collection={deviceRequestStore.deviceRequests}
              DefaultView={DeviceRequestListItem}
              preItems={<DeviceRequestListTitle />}
              filter={filterDevicesRequest}
              classes={classes}
            />
          </Grid>
          <Grid item sm={12}>
            {!deviceRequestStore.deviceRequests.hasDocs ? (
              <Typography
                variant="h6"
                component="h6"
                className={classes.typographyRoot}
              >
                No Device requested for this customer
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </DocsLayout>
    </>
  ))
}
