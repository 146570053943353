import { browserHistory } from '@any-ui/core/navigation';
import { Document } from 'firestorter';

import { ITermsConditions } from '../model/termsConditions';

// import { computed } from 'mobx'
export class TermsConditionsDoc extends Document<ITermsConditions> {
  public static navigateNew = () => {
    browserHistory.push(`/termsandconditions/new`)
  }

  public navigateEdit = () => {
    browserHistory.push(`/termsandconditions/${this.id}/edit`)
  }
}