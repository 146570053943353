import { firebase } from './firebase'

// REVISIT: using double export as @babel/plugin-transform-typescript can't handle "export import X = Y"

export const Timestamp = firebase.firestore.Timestamp
export type Timestamp = firebase.firestore.Timestamp

export const FieldValue = firebase.firestore.FieldValue
export type FieldValue = firebase.firestore.FieldValue

export const FieldPath = firebase.firestore.FieldPath
export type FieldPath = firebase.firestore.FieldPath

export type AsFieldUpdate<T> =
  | (T extends object
      ? {
          [P in keyof T]?: AsFieldUpdate<T[P]>
        }
      : T)
  | firebase.firestore.FieldValue

export type AsUpdate<T extends object> = {
  [P in keyof T]?: AsFieldUpdate<T[P]>
}

export const FIRESTORE_BATCH_MAX = 500
