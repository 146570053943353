import { EditPage } from '@any-ui/core';
import { browserHistory } from '@any-ui/core/navigation';
import { useStoreClass } from '@any-ui/core/store';
import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { authStore } from '../auth/authStore';
import { DeviceRequestStatus } from '../model/deviceReqStatus';
import { DeviceRequestEditStore } from './deviceRequestEditStore';
import { EditDeviceRequestForm, FormFields } from './EditDeviceRequestForm';

export interface IRouteParams {
  deviceId?: string
}

export const EditDeviceRequestPage: FC<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const { deviceId } = match.params
  const store = useStoreClass(DeviceRequestEditStore, deviceId)
  const save = async (fields: FormFields) => {
    await store.save(fields);
    if(authStore.isAdmin) {
      browserHistory.push(`/devices/request`);
    } else {
      browserHistory.goBack();
    }
  }
  const onDelete = async() =>{
    const fields = {
      status: DeviceRequestStatus.DELETED
    };
    await store.save(fields)
    browserHistory.goBack();
  }
  return useObserver(() => (
    <EditPage isLoading={store.isLoading} heading="Device request">
      <EditDeviceRequestForm
        isNew={store.isNew}
        initialValues={store.initialValues}
        onResult={save}
        onDelete={onDelete}
      />
    </EditPage>
  ))
}
