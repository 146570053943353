import { locationStore } from '@any-ui/core/navigation'
import { Collection, Mode } from 'firestorter'
import { action, reaction, toJS } from 'mobx'

import { authStore } from '../auth/authStore'
import { DeviceDoc } from '../db/deviceDoc'
import { OffHiredDeviceDoc } from '../db/offHiredDeviceDoc'
import { PondDoc } from '../db/pondDoc'
import { SiteDoc } from '../db/siteDoc'
import { DeviceStatus } from '../tools/DeviceStatus'
import { getyear } from '../util/commonFunctions'
import { offHiredDeviceStore } from './offHiredDeviceStore'

// import { authStore } from '../auth/authStore';
// import { CompanyStaffDoc } from '../db/companyStaffDoc';
// import { any } from 'io-ts';
// import { IDevice } from '../model/device';
// import { async } from '@firebase/util';

// import { any } from 'io-ts'

class DeviceStore {
  public devices = new Collection<DeviceDoc>('devices', {
    createDocument: (...args) => new DeviceDoc(...args),
    query: ref =>
      authStore.isAdmin
        ? ref
        : authStore.isCustomer
        ? ref.where('ownerUid', '==', authStore.uid)
        : null,
  })

  // public async deviceListForCompanyStaff() {
  //   const deviceList = await new Collection('devices', {
  //     mode: Mode.Off,
  //   }).fetch()
  //   console.log('deviceList', deviceList);
  //   const companyStaffUser = await new CompanyStaffDoc(`companyStaffs/67N9KdFdgdTYppItJMG89s7eg483`, { mode: Mode.Off })
  //   let deviceArray: any[] = [];
  //   let filter: any;
  //   companyStaffUser.fetch().then(c => {
  //     c.data.siteIds.map(async (s: string) => {
  //       filter = deviceList.docs.filter((p: any) => {
  //         console.log('p.data.siteId', p.data.siteId);
  //         console.log('s', s);
  //         return p.data.siteId == s;
  //       })
  //       console.log('filter1', filter);
  //       return filter
  //     })

  //   })
  //   // return filter
  // }

  // public async deviceListForCompanyStaff() {
  //   const companyStaffUser = await new CompanyStaffDoc(`companyStaffs/67N9KdFdgdTYppItJMG89s7eg483`, { mode: Mode.Off })
  //   let deviceArray: any[] = [];
  //   companyStaffUser.fetch().then(c => {
  //     console.log('c', c)
  //     c.data.siteIds.map(async (s: string) => {
  //       console.log('s', s)
  //       const col = await new Collection('devices', {
  //         query: (ref) => ref.where('siteId', '==', s)
  //       }).fetch()
  //       deviceArray.push(col.docs)
  //     })
  //     console.log('deviceArray', deviceArray)
  //     this.devices = deviceArray[0]
  //     return deviceArray[0]
  //   })
  // }

  /** @TODO
   * Update roles base loading
   */
  public getDeviceFromQR(qrCode: string) {
    const devicesFromQR = new Collection<DeviceDoc>('devices', {
      createDocument: (...args) => new DeviceDoc(...args),
      mode: Mode.On,
      query: ref => ref.where('qrCode', '==', qrCode),
    })
    return devicesFromQR || null
  }
  private readonly disposer: () => void
  private customerId = ''
  constructor() {
    this.disposer = reaction(
      () => locationStore.path.startsWith('/devices'),
      inDevices => {
        this.devices.mode = inDevices ? Mode.On : Mode.Auto
      },
      { fireImmediately: true },
    )
  }

  /**
   * Will search for keyword in
   *
   * particleId
   * serial
   * deviceType
   * status
   * qrCode
   *
   * @param keyword
   */
  // public async searchDevices(keyword: string) {
  //   const deviceData = new Collection<DeviceDoc>('devices', {
  //     createDocument: (...args) => new DeviceDoc(...args),
  //     mode: Mode.Off,
  //   })
  //   deviceData.fetch().then(p => {
  //     const regex = new RegExp(`${keyword}`, 'i');
  //     const filter = p.docs.filter(p => p.data.particleId.match(regex)
  //       || p.data.serial.match(regex) || p.data.deviceType.match(regex)
  //       || p.data.status.match(regex) || p.data.qrCode.match(regex))
  //     console.log('filter', filter);
  //     return filter
  //   })
  // }
  public particleIdInUse = async (particleId: string | undefined) => {
    const device = await new Collection('devices', {
      mode: Mode.Off,
      query: ref => ref.where('particleId', '==', particleId),
    }).fetch()
    return device.docs.length
  }

  public getCustomersSites = async (ownerId: string) => {
    const allSites = await new Collection<SiteDoc>('sites', {
      createDocument: (...args) => new SiteDoc(...args),
      mode: Mode.On,
      query: ref =>
        ref.where('ownerUid', '==', ownerId).where('isArchive', '==', false),
    })
    return allSites
  }

  public deviceFilter = async (status: string = '', ownerId: string = '') => {
    if (status !== '-1' && ownerId !== '-1') {
      this.devices = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref.where('status', '==', status).where('ownerUid', '==', ownerId),
      })
    } else if (status === '-1' && ownerId !== '-1') {
      this.devices = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref => ref.where('ownerUid', '==', ownerId),
      })
    } else if (status !== '-1' && ownerId === '-1') {
      this.devices = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref => {
          if (authStore.isAdmin) {
            return ref.where('status', '==', status)
          } else {
            return ref
              .where('status', '==', status)
              .where('ownerUid', '==', authStore.uid)
          }
        },
      })
    } else {
      this.devices = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          authStore.isAdmin
            ? ref
            : authStore.isCustomer
            ? ref.where('ownerUid', '==', authStore.uid)
            : null,
      })
    }
  }
  public getDeviceInfoByQrCode = async (qrCode: string) => {
    const device = await new Collection('devices', {
      mode: Mode.Off,
      query: ref => ref.where('serial', '==', qrCode),
    }).fetch()
    return device
  }

  @action
  public setCustomerId(customerId: string) {
    this.customerId = customerId
  }
  public deviceRetrievals = new Collection<DeviceDoc>('devices', {
    createDocument: (...args) => new DeviceDoc(...args),
    mode: Mode.On,
    query: ref =>
      ref
        .where('isRetrieved', '==', false)
        .where('status', '==', DeviceStatus.OFFHIRE),
  })
  public updateDeviceRetrival = async (deviceId: string) => {
    const device = await new DeviceDoc(`devices/${deviceId}`, {
      mode: Mode.Off,
    }).fetch()
    await offHiredDeviceStore.updateOffHireHistory(device.data)
    await device.set({ isRetrieved: true, ownerUid: null }, { merge: true })
  }
  public getDevicesOnDemand = async () => {
    this.devices = new Collection<DeviceDoc>('devices', {
      createDocument: (...args) => new DeviceDoc(...args),
      mode: Mode.Off,
      query: ref =>
        authStore.isAdmin
          ? ref
          : authStore.isCustomer
          ? ref.where('ownerUid', '==', authStore.uid)
          : null,
    })
    await this.devices.fetch()
  }
  public addOffHiredDevices = async (
    customerId: string,
    invoiceMonth: Date,
  ) => {
    // console.log('OffHireDeviceData called')
    let latestDevices: any = new Collection<DeviceDoc>('devices', {
      createDocument: (...args) => new DeviceDoc(...args),
      mode: Mode.Off,
      query: ref => ref.where('ownerUid', '==', customerId),
    })
    const latestDevicesData = await latestDevices.fetch()
    // console.log('latest', latestDevices)
    const offHireDevice = new Collection<OffHiredDeviceDoc>('offHiredDevices', {
      createDocument: (...args) => new OffHiredDeviceDoc(...args),
      mode: Mode.Off,
      query: ref => ref.where('ownerUid', '==', customerId),
    })
    const offHireDeviceData = await offHireDevice.fetch()

    offHireDeviceData.docs.forEach((d: any) => {
      const index = latestDevicesData.docs.findIndex((x: any) => {
        return (
          x.data.particleId === d.data.particleId &&
          x.data.ownerUid === d.data.ownerUid
        )
      })

      if (index === -1) {

        const  addedDate = new Date(d.data.addedTimestamp.toDate())
          // addedDate.setDate(addedDate.getDate()-1)
        const  retriveDate = new Date(d.data.retrivalTimestamp.toDate())
        // retriveDate.setDate(retriveDate.getDate()-1)
        const retrivalMonth =
          d.data.retrivalTimestamp &&
          new Date(retriveDate).getMonth()
    
        const retrivalYear =
          d.data.retrivalTimestamp &&
          new Date(retriveDate).getFullYear()
    
        const addedMonth =
          d.data.addedTimestamp &&
          new Date(addedDate).getMonth()
    
        const addedYear =
          d.data.addedTimestamp &&
          new Date(addedDate).getFullYear()
          
          const modifiedMont =  new Date(invoiceMonth).getMonth()
          // const modifiedMont =  Math.abs(JSON.parse(invoiceMonth == '-12' ? '0' : invoiceMonth))
          const selectedYear  = invoiceMonth.getFullYear(); 
          // const selectedYear = getyear(parseInt(invoiceMonth)); 
         
          if(addedYear <= selectedYear && retrivalYear === selectedYear ) {  
          // console.log('add', addedYear, selectedYear, d.data.serial)
          // console.log(addedMonth, retrivalMonth, retriveDate, d.data.serial)


    
            if((addedMonth <= modifiedMont) &&(retrivalMonth >= modifiedMont )){ 
          // console.log('added month', addedMonth, retrivalMonth, modifiedMont, d.data.serial, i)         
          // console.log('added month', addedMonth, retrivalMonth, modifiedMont, d.data.serial, invoiceMonth)         

              latestDevicesData.docs.push(d)     
            }
    
          }
          
        // if (
        //   retrivalMonth >=
        //   Math.abs(JSON.parse(invoiceMonth == '-12' ? '0' : invoiceMonth))
        // ) {
        //   latestDevicesData.docs.push(d)
        // }
      }

    })
    // console.log('Still Site Store')
    return latestDevicesData
  }
  public getCustomersDeviceRetrievals = async () => {
    if (this.customerId !== '-1') {
      this.deviceRetrievals = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref
            .where('ownerUid', '==', this.customerId)
            .where('isRetrieved', '==', false)
            .where('status', '==', DeviceStatus.OFFHIRE),
      })
    } else {
      this.deviceRetrievals = new Collection<DeviceDoc>('devices', {
        createDocument: (...args) => new DeviceDoc(...args),
        mode: Mode.On,
        query: ref =>
          ref
            .where('isRetrieved', '==', false)
            .where('status', '==', DeviceStatus.OFFHIRE),
      })
    }
  }
  public withSerialDeviceId(deviceId: string): DeviceDoc | null {
    return this.devices.docs.find(s => s.id === deviceId) || null
  }
  
  public getDeviceDataWithPondId= async (pondId: string) => {
    let deviceData = null;   
    const pondsData = await new PondDoc(`ponds/${pondId}`, {
      mode: Mode.Off,
    }).fetch()
    
    if(pondsData.data.dispenseDeviceId) {
      deviceData = await this.withSerialDeviceId(pondsData.data.dispenseDeviceId) || null;  
    }
    return deviceData;
    
  }
  
}

export const deviceStore = new DeviceStore()
