import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import React, { ReactNode } from 'react'
import { customerStore } from './customerStore'

export const customerMenuItems = ({
  initialUid,
  required,
}: {
  initialUid?: string | null
  required?: boolean
} = {}): ReactNode[] => {
  const enabledCustomers = customerStore.customers.docs.filter(
    c => c.data.enabled,
  )
  const result: ReactNode[] = []
  if (!required) {
    result.push(
      <MenuItem key="none" value="">
        <em>Unallocated</em>
      </MenuItem>,
    )
  }
  result.push(
    ...enabledCustomers.map(c => (
      <MenuItem key={c.id} value={c.id}>
        {c.displayName}
        {c.id === initialUid && ' (current)'}
      </MenuItem>
    )),
  )
  if (initialUid && !enabledCustomers.find(c => c.id === initialUid)) {
    // If current owner has been disabled they'll be missing from enabled list
    result.push(
      <MenuItem key={initialUid} value={initialUid}>
        {`${customerStore.customerName(initialUid)} (current, disabled)`}
      </MenuItem>,
    )
  }
  return result
}
