import { CardSecondaryAction, useConfirm } from '@any-ui/core';
import { useStoreClass } from '@any-ui/core/store';
import { IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

import { DeleteStaff } from '../../app/Icons';
import { SiteDeleteStore } from '../store/siteDeleteStore';

export const useStyles = makeStyles<Theme>(theme => ({
  action: {
    marginRight: theme.spacing(1),
  },
 
}))

export interface IDeleteSiteProps  {
  siteId: string | undefined
}

export const DeleteSite: FC<IDeleteSiteProps> = ({siteId}) => {
  const classes =  useStyles({})
  const store = useStoreClass(SiteDeleteStore, siteId)
  
  const onDeleteSite = useConfirm({
    message: 'Are you sure you want to delete the site?',
    onCancel: () => {},
    onConfirm: async () => {
      await store.delete();
      // browserHistory.push('/companystaffs');
    },
    title: `Delete Site`,
  });
  return (
    <CardSecondaryAction className={clsx('PondCount', classes.action)}>
       <IconButton onClick={onDeleteSite} >
          <DeleteStaff color="secondary" />
        </IconButton>
    </CardSecondaryAction>
  )
}
