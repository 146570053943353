import { DocsView } from '@any-ui/core'
import { ListItem } from '@any-ui/core'
import { Minimize } from '@material-ui/icons'
import { ListItemIcon } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { CardActionIconButton } from '@any-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { CustomerDoc } from '../db/customerDoc'

export const CustomerListItem: DocsView<CustomerDoc> = ({
  document,
  ...rest
}) =>
  useObserver(() => (
    <ListItem button onClick={document.navigateEdit} {...rest}>
      <ListItemText
        style={!document.data.enabled ? { opacity: 0.5 } : {}}
        primary={document.displayName}
        secondary={document.data.contactName}
      />
    </ListItem>
  ))
