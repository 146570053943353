import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/performance'
import 'firebase/storage'
import { initFirestorter } from 'firestorter'
import * as t from 'io-ts'
import * as env from './env'

const app = firebase.initializeApp(env.firebaseConfig)

export { firebase }

initFirestorter({ firebase, app })

firebase.performance()

interface ICallResult<R> {
  readonly data: R
}

type IApiCall<T extends t.Any, R> = (
  data: t.TypeOf<T>,
) => Promise<ICallResult<R>>

export const apiCall = <R = void, T extends t.Any = t.Any>(
  name: string,
  dataType: T,
): IApiCall<T, R> => {
  const call = app.functions().httpsCallable(name)
  return data => call(data)
}
