import useTheme from '@material-ui/core/styles/useTheme'
import { timeFormat } from 'd3-time-format'
import { useObserver } from 'mobx-react-lite'
import { IndexedEvent } from 'pondjs'
import React, { FC } from 'react'
// @ts-ignore
import { EventMarker } from 'react-timeseries-charts'
import { DashboardStore } from '../dashboardStore'

export type FieldValue = (field: string) => any

export interface IFieldInfo {
  column: string
  label: string
  value: string
}

export type InfoFactory = (valueGetter: FieldValue) => IFieldInfo | null

interface IProps {
  store: DashboardStore
  fields: InfoFactory[]
  axis: string
}

const pastYearHourlyFormatter = timeFormat('%b %d %Y - %I %p')
const currentYearHourlyFormatter = timeFormat('%b %d - %I %p')
const pastYearDailyFormatter = timeFormat('%b %d %Y')
const currentYearDailyFormatter = timeFormat('%b %d')

const isCurrentYear = (date: Date) =>
  date.getFullYear() === new Date().getFullYear()

const hourlyFormatter = (index: IndexedEvent) => {
  const timestamp = index.begin()
  return isCurrentYear(timestamp)
    ? currentYearHourlyFormatter(timestamp)
    : pastYearHourlyFormatter(timestamp)
}

const dailyFormatter = (index: IndexedEvent) => {
  const timestamp = index.begin()
  return isCurrentYear(timestamp)
    ? currentYearDailyFormatter(timestamp)
    : pastYearDailyFormatter(timestamp)
}

export const Marker: FC<IProps> = ({ store, fields, ...props }) => {
  const theme = useTheme()
  return useObserver(() => {
    const event = store.highlightEvent
    if (!event) {
      return null
    }
    const fieldValue: FieldValue = field => event.get(field)
    const infos: IFieldInfo[] = fields
      .map(factory => factory(fieldValue))
      .filter((infoOrNull): infoOrNull is IFieldInfo => infoOrNull != null)
    const firstInfoWithValue = infos.find(i => i.value)
    if (!firstInfoWithValue) {
      return null
    }
    return (
      <EventMarker
        event={event}
        column={firstInfoWithValue.column}
        info={infos}
        infoWidth={140}
        infoHeight={14 + 14 * infos.length}
        infoStyle={{
          box: {
            fill: theme.palette.background.paper,
            opacity: 1,
            pointerEvents: 'none',
            stroke: theme.palette.divider,
          },
        }}
        stemStyle={{
          pointerEvents: 'none',
          stroke: theme.palette.divider,
        }}
        markerStyle={{
          fill: '#b0b0b0',
          stroke: theme.palette.divider,
        }}
        infoTimeFormat={
          store.rollUpWindow === 'daily' ? dailyFormatter : hourlyFormatter
        }
        markerRadius={2}
        {...props}
      />
    )
  })
}
