import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Int } from 'io-ts';
import { useObserver } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';

import { DeviceStatus } from '../../tools/DeviceStatus';

// tslint:disable:object-literal-sort-keys
// @ts-ignore
const useStyles = makeStyles<Theme>(theme => ({
  serial: {
    fontWeight: 800,
  },
  customerName: {},
  status: {},
  count: {},
  allocatedStatus: {
    color: '#C0E800',
  },
  unAllocatedStatus: {
    color: '#FD4646',
  },
  offHireStatus: {
    color: '#676767',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 0.5fr 0.5fr',
  },
  notDeployed: {
    color: theme.palette.text.disabled,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}))

interface IDeviceListItemBaseProps extends Partial<ListItemProps> {
  serial: string
  customerName?: string
  deployedToPond: ReactNode | null
  status: string
  count?: String
}

const renderStatus = (status: any, classes: any) => {
  if (status === DeviceStatus.ALLOCATED) {
    return <Box className={clsx(classes.allocatedStatus)}>{status}</Box>
  } else if (status === DeviceStatus.UNALLOCATED) {
    return <Box className={clsx(classes.unAllocatedStatus)}>{status}</Box>
  } else if (status === DeviceStatus.OFFHIRE) {
    return <Box className={clsx(classes.offHireStatus)}>{status}</Box>
  } else {
    return <Box className={clsx(classes.status)}>{status}</Box>
  }
}
export const DeviceListItemBase: FC<IDeviceListItemBaseProps> = ({
  serial,
  customerName,
  deployedToPond,
  status,
  className,
  count,
  ...rest
}) => {
  const classes =  useStyles({})  
  return useObserver(() => (
    <ListItem
      // @ts-ignore
      button
      classes={{
        root: clsx(classes.root, {
          [classes.notDeployed]: !deployedToPond,
        }),
      }}
      className={clsx('DeviceListItemBase', className)}
      {...rest}
    >
      <Box className={clsx(classes.serial)}>{serial}</Box>
      <Box className={clsx(classes.customerName)}>{customerName}</Box>
      <Box className={clsx(classes.deployedToPond)}>{deployedToPond}</Box>
      {renderStatus(status, classes)}
      <Box className={clsx(classes.count)}>{count}</Box>
    </ListItem>
  ))
}
