export const template = `<!DOCTYPE html>
<html>
<body>
    <div>
        <p>Hello Autofloc Admin,</p>
	<p><%=eSignature%> has accepted terms and conditions and logged in to Autofloc application for first time.</p>
<p>Thanks,</p>
<p>Autoflocc Team</p>
    </div>
</body>
</html>`