import {
  DocsView,
  ListItem,
  SHOW_ON_LIST_ITEM_HOVER,
  useConfirm,
} from '@any-ui/core'
import { ListItemIcon } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { SupervisorAccount } from '@material-ui/icons'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import { useObserver } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { IUserDoc } from '../store/adminUsersStore'

export const DefaultView: DocsView<IUserDoc> = ({ document }) => {
  const anchorEl = useRef(null)
  const handleRemove = useConfirm({
    anchorEl: anchorEl.current,
    message:
      'This user will no longer be able to perform administrator functions. Are you sure?',
    onConfirm: document.handleSelect,
    title: `Disable ${document.data.contactName || document.data.email}?`,
  })
  return useObserver(() => {
    const user = document.data
    return (
      <ListItem>
        <ListItemIcon>
          <SupervisorAccount />
        </ListItemIcon>
        <ListItemText
          primary={user.contactName || user.email}
          secondary={user.contactName && user.email}
        />
        <ListItemSecondaryAction>
          {document.removing ? (
            <CircularProgress size={36} color="secondary" />
          ) : (
            <IconButton
              aria-label="Remove as admin"
              onClick={handleRemove}
              buttonRef={anchorEl}
              className={SHOW_ON_LIST_ITEM_HOVER}
            >
              <RemoveCircle color="error" />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    )
  })
}
