import { DocsView, ListItem } from '@any-ui/core'
import { ListItemIcon } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { AlarmDoc } from './alarmDoc'
import { AlarmIcon } from './AlarmIcon'

export const AlarmListItem: DocsView<AlarmDoc> = ({ document: alarm }) =>
  useObserver(() => (
    <ListItem button onClick={alarm.navigateSiteDash}>
      <ListItemIcon>
        <AlarmIcon alarm={alarm} />
      </ListItemIcon>
      <ListItemText
        primary={alarm.pondName}
        secondary={
          <>
            {alarm.description}
            <br />
            From {alarm.displayTimestamp}
          </>
        }
      />
    </ListItem>
  ))
