import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { DocsEmpty, DocsLayout, DocsList, DocsSorter, PageAction, PageTitle } from '@any-ui/core';
import { PersonAdd } from '@material-ui/icons';
import Add from '@material-ui/icons/AddOutlined';
import negate from 'lodash/negate';
import { CompanyStaffDoc } from '../../db/companyStaffDoc'
import { CompanyStaffActiveListItem } from '../../companyStaff/components/CompanyStaffActiveListItem';
import {companyStaffStore} from '../../companyStaff/companyStaffStore'
import { CompanyStaffInactiveListItem } from '../../companyStaff/components/CompanyStaffInactiveListItem';
import { browserHistory } from '@any-ui/core/navigation';

const useStyles = makeStyles((theme) => ({
    root: {
      gridGap: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        gridGap: theme.spacing(2),
      },
      gridTemplateColumns: "repeat( auto-fit, minmax(1fr, 1fr) )",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat( auto-fit, minmax(25px, 1fr) )",
      },
    },
    item: {
      marginRight: "5px",
    },
    headertitle: {
      marginTop: 12,
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        textAlign: "center"
      },
      whiteSpace: "nowrap",
    },
    smallheadertitle: {
      marginTop: 12,
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
      },
      fontSize: 15,
      textAlign: "center",
    },
    roottitle: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      textAlign: "center",
    },
  }));

  interface SiteUrlObject {
    history: {
      length: number;
      action: string;
      location: {
        pathname: string;
        search: string;
        key: string;
      };
      // Add any other properties as needed
    };
    location: {
      pathname: string;
      search: string;
      key: string;
      // Add any other properties as needed
    };
    match: {
      path: string;
      url: string;
      isExact: boolean;
      // Add any other properties as needed
    };
    staticContext?: unknown;
    // Add any other properties as needed
  }
  function extractSiteId(siteUrlObj: SiteUrlObject): string | null {
    const { pathname } = siteUrlObj.location;
    const siteIdMatch = pathname.match(/\/sites\/([^/]+)\/siteStaff/);
    return siteIdMatch ? siteIdMatch[1] : null;
  }
const enabledFilter = (companyStaff: CompanyStaffDoc) => companyStaff.data.enabled
const disabledFilter = negate(enabledFilter)
const siteIdFilter = (siteId: string) => (companyStaff: CompanyStaffDoc) => companyStaff.data.siteIds && companyStaff.data.siteIds.includes(siteId);
const noSiteIdFilter = (siteId: string) => negate(siteIdFilter(siteId))
const combinedDisabledFilter = (siteId: string, isEnabled: boolean) => (companyStaff: CompanyStaffDoc) => {
  const hasSiteId = noSiteIdFilter(siteId)(companyStaff);
  const isEnabledFilter = isEnabled ? enabledFilter(companyStaff) : disabledFilter(companyStaff);
  return hasSiteId && isEnabledFilter;
};
const combinedEnabledFilter = (siteId: string, isEnabled: boolean) => (companyStaff: CompanyStaffDoc) => {
  const hasSiteId = siteIdFilter(siteId)(companyStaff);
  const isEnabledFilter = isEnabled ? enabledFilter(companyStaff) : disabledFilter(companyStaff);
  return hasSiteId && isEnabledFilter;
};
const SiteStaffPage = (siteUrl: SiteUrlObject) => {
    const classes = useStyles({});
const siteId = extractSiteId(siteUrl)
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={1} className={classes.roottitle}>
          <PageTitle>
            <div className={classes.headertitle}>Manage staff for site</div>
          </PageTitle>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
        >
            <PageAction
          Icon={Add}
          label="Add new staff"
          onClick={() => {
            browserHistory.push(`siteStaff/new`)}} 
        />
        </Grid>
        <Grid item xs={6}>
          <PageTitle>
            <Grid item xs={3} sm={3} className={classes.smallheadertitle}>
              Assigned Staff
            </Grid>
          </PageTitle>
          <DocsLayout collection={companyStaffStore.companyStaffs} pageWidth="100%">
            <DocsList<CompanyStaffDoc>
              collection={companyStaffStore.companyStaffs}
              DefaultView={(props) => <CompanyStaffActiveListItem {...props} siteId={siteId!} />}
              filter={combinedEnabledFilter(siteId!, true)}
            />
            <DocsEmpty>Add the first staff member to begin</DocsEmpty>
          </DocsLayout>
        </Grid>
        <Grid item xs={6}>
          <PageTitle>
            <Grid item xs={3} sm={3} className={classes.smallheadertitle}>
              Nonassigned staff
            </Grid>
          </PageTitle>
          <DocsLayout collection={companyStaffStore.companyStaffs} pageWidth="100%">
            <DocsList 
            collection={companyStaffStore.companyStaffs} 
            DefaultView={(props) => <CompanyStaffInactiveListItem {...props} siteId={siteId!} />}
            filter={combinedDisabledFilter(siteId!, true)}
            />
            <DocsEmpty>No unassigned staff</DocsEmpty>
          </DocsLayout>
        </Grid>
      </Grid>
    </>
  );
};

export default SiteStaffPage;